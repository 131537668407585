import { useMainNavigationItems } from 'eva-frame/EvaLayout/useMainNavigationItems';
import { Navigate } from 'react-router-dom';
import { useDestination } from 'util/navigation/useDestination';

export function HomePageRedirect() {
  const { items } = useMainNavigationItems();
  return <Navigate to={items[0].href} />;
}

export function ShopProfileRedirect() {
  const { toShopProfile } = useDestination();
  return <Navigate to={toShopProfile()} />;
}
