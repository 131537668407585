import { SxProps, Theme } from '@mui/material';

export const sxTruncate: SxProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
};

export function mergeSx(firstSx: SxProps<Theme>, secondSx?: SxProps<Theme>) {
  const firstArray = Array.isArray(firstSx) ? firstSx : [firstSx];
  const secondArray = Array.isArray(secondSx) ? secondSx : [secondSx];

  return [...firstArray, ...secondArray];
}
