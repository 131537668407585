import { DRAWER_Z_INDEX, useDrawer } from '@ev/eva-container-api';
import { Box, FormHelperText, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useContactInfos } from 'api/graphql/hooks/useContact';
import { AddNewContactButton, ContactOption, TContactOption } from 'components/forms/ContactPicker/ContactOption';
import { ContactPopover } from 'components/forms/ContactPicker/ContactPopover';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { CreateNewContact } from 'page-components/contacts/ContactListPage/CreateNewContact';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';
import { useSearchText } from 'util/useDebounce';
import { usePermissions } from 'util/usePermissions';

export const ContactPicker = ({
  label,
  value,
  onChange,
  fieldState,
  children,
  errorMessage,
  sx,
  noOptionsText,
  loadingText,
  required,
  maxContacts,
  hasCreateContact = true,
}: {
  label?: React.ReactNode;
  value: (string | TContactOption)[];
  onChange: (e: (string | TContactOption)[]) => void;
  fieldState?: ControllerFieldState;
  children?: React.ReactNode;
  errorMessage?: string;
  sx?: SxProps;
  noOptionsText?: string;
  loadingText?: string;
  required?: boolean;
  maxContacts?: number;
  hasCreateContact?: boolean;
}) => {
  const autocompleteRef = useRef(null);
  const { searchText, setSearchText } = useSearchText();
  const [selectedOption, setSelectedOption] = useState<TContactOption>();
  const { t } = useTranslation(['contact', 'user']);
  const { activeShop, shopIdsInActiveGroup } = useActiveShop();
  const { canCreateContact } = usePermissions();
  const [isCreateContactDrawerOpen, setCreateContactDrawerOpen] = useDrawer('createNewContact');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const { contacts = [], isLoading: isContactLoading } = useContactInfos(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        _and: [
          {
            deleted: { _neq: true },
          },
          { blocked: { _neq: true } },
          contactSearchNameFilter(searchText),
        ],
      },
      limit: 10,
      offset: 0,
    },
    { enabled: !!searchText.length },
  );
  const suggestedContacts = contacts.map((contact) => ({
    type: 'CONTACT' as const,
    id: contact.id,
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact?.emailAddresses[0]?.address,
    phoneNumber: contact?.phoneNumbers?.find((number) => number.preferred)?.number,
  }));

  const searchOptionsList =
    canCreateContact(activeShop) && hasCreateContact
      ? [{ type: 'ADD_CONTACT' as const, email: searchText }, ...suggestedContacts]
      : suggestedContacts;

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const handleClick = (option: TContactOption) => {
    return (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      setSelectedOption(option);
      setAnchorEl(event.currentTarget);
    };
  };

  const isDisabled = !!maxContacts && value?.length >= maxContacts;
  const maxContactsLabelSuffix = maxContacts
    ? ` ${t('user:myShop.shopExport.contactActivityReport.contactPicker.suffix', { value: maxContacts })}`
    : '';

  return (
    <Box sx={sx}>
      <Autocomplete
        noOptionsText={noOptionsText || t('contact:form.contactPicker.noOptions')}
        loading={!!searchText.length && isContactLoading}
        loadingText={loadingText || t('contact:form.contactPicker.loading')}
        value={value}
        disableClearable
        multiple
        openOnFocus={true}
        ref={autocompleteRef}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : [option.email, option.firstName, option.lastName, option.phoneNumber].filter(Boolean).join(' ')
        }
        onChange={(_, items) => {
          const payload = items.map((item) => {
            if (typeof item === 'string') {
              return {
                type: 'CONTACT' as const,
                displayValue: item,
                email: item,
              };
            }
            return {
              type: 'CONTACT' as const,
              displayValue: item.email,
              id: item.id,
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              phone: item.phoneNumber,
            };
          });
          onChange(payload);
        }}
        isOptionEqualToValue={(option: string | TContactOption, value: TContactOption) =>
          typeof option === 'string' ? option === value.email : option.email === value.email
        }
        onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
        options={searchOptionsList || []}
        renderOption={(props, option) => {
          if (option.type === 'ADD_CONTACT') {
            return (
              <AddNewContactButton
                onClick={(event) => {
                  event.stopPropagation();
                  setCreateContactDrawerOpen(true);
                }}
              />
            );
          }
          return <ContactOption key={option.id} option={option} props={props} />;
        }}
        freeSolo={true}
        getOptionDisabled={(option) => {
          return !!option.disabled || isDisabled;
        }}
        limitTags={2}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            return (
              <EVChip
                label={
                  option?.firstName && option?.lastName
                    ? fullName({ firstName: option?.firstName, lastName: option?.lastName })
                    : option.email
                }
                {...getTagProps({ index })}
                key={option.id}
                onClick={handleClick(option)}
                selected={false}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              label ??
              `${t('user:myShop.shopExport.contactActivityReport.contactPicker.label')}${maxContactsLabelSuffix}`
            }
            required={required}
            slotProps={{
              input: params.InputProps,
            }}
            error={!!fieldState?.error}
            data-testid="contact-picker-text-field"
          />
        )}
      />
      <EVDrawer
        zIndex={DRAWER_Z_INDEX + 1}
        isOpen={isCreateContactDrawerOpen}
        onClose={() => setCreateContactDrawerOpen(false)}
        hideBackdrop={true}
      >
        <CreateNewContact
          onSuccess={(id, contactDetails) => {
            onChange([
              ...(value ?? []),
              {
                type: 'CONTACT' as const,
                id: id,
                firstName: contactDetails?.firstName ?? '',
                lastName: contactDetails?.lastName ?? '',
                email: contactDetails?.emailAddresses[0]?.email ?? '',
                displayValue: contactDetails?.emailAddresses[0]?.email ?? '',
                phoneNumber: contactDetails?.phoneNumbers?.find((number) => number.preferred)?.number ?? '',
              },
            ]);
          }}
          onClose={() => setCreateContactDrawerOpen(false)}
          redirectToContactOnSuccess={false}
          withoutRelationships
        />
      </EVDrawer>
      <Stack direction="row" sx={{ marginBottom: children ? -3 : 0 }}>
        <FormHelperText>{errorMessage}</FormHelperText>
        <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
      </Stack>
      <ContactPopover
        anchorEl={anchorEl}
        selectedOption={selectedOption}
        handleClose={() => {
          setSelectedOption(undefined);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
};
