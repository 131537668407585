import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import CrossNakedThicker from 'components/icons/cross_naked.svg?react';
import { useState } from 'react';
import { theme } from 'theme';
import { TFunction, useTranslation } from 'util/i18next';
import { useInterval } from 'util/useInterval';

// base on https://stackoverflow.com/a/18650828
function formatBytes(t: TFunction, bytes: number, decimals = 2) {
  if (!+bytes) return t('common:amountOfData.bytes', { amount: 0 });
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'common:amountOfData.bytes',
    'common:amountOfData.KB',
    'common:amountOfData.MB',
    'common:amountOfData.GB',
    'common:amountOfData.TB',
    'common:amountOfData.PB',
    'common:amountOfData.EB',
    'common:amountOfData.ZB',
    'common:amountOfData.YB',
  ] as const;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const amount = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;
  return t(sizes[i] || sizes[2], { amount });
}

export function Attachment({ name, size, onRemove }: { name: string; size: number; onRemove?: () => void }) {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  const duration = 3000;
  const interval = 100;
  const increment = 100 / (duration / interval);

  useInterval(() => setProgress((prev) => Math.min(prev + increment, 100)), progress >= 100 ? null : interval);

  const sizeFormatted = formatBytes(t, size);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          gap: 1,
          backgroundColor: theme.palette.shade.grey4,
          paddingY: theme.spacing(0.5),
          paddingX: theme.spacing(2),
          marginBottom: '5px',
        }}
      >
        <Typography
          variant="body3"
          sx={{
            minWidth: 0,
            marginRight: 'auto',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'noWrap',
          }}
        >
          {name}
        </Typography>
        <Typography variant="body3" color="text.secondary" sx={{ whiteSpace: 'noWrap' }}>
          {sizeFormatted}
        </Typography>
        {onRemove && (
          <IconButton size="small" onClick={onRemove}>
            <CrossNakedThicker />
          </IconButton>
        )}
      </Stack>
      {progress !== 100 && (
        <Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              backgroundColor: theme.palette.error.light,
              '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.error.main,
              },
              height: '4px',
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
