import { FRAME_Z_INDEX } from '@ev/eva-container-api';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiTabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import ArrowLeftIcon from 'components/icons/arrow_3_left.svg?react';
import ArrowRightIcon from 'components/icons/arrow_3_right.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import useLocalStorage from 'components/state/LocalStorage';
import { DESKTOP_TOP_BAR_HEIGHT } from 'components/state/UIStateProvider';
import { ActionMenuButton } from 'eva-frame/EvaLayout/ActionMenuButton';
import { MoreSidePanelItems } from 'eva-frame/EvaLayout/MainNavigationSidePanel/MoreSidePanelItems';
import { SidePanelLinkTab } from 'eva-frame/EvaLayout/MainNavigationSidePanel/SidePanelLinkTab';
import { NavItem } from 'eva-frame/EvaLayout/useMainNavigationItems';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { usePermissions } from 'util/usePermissions';
import { useThrottle } from 'util/useThrottle';

export const MAIN_SIDE_PANEL_WIDTH = 167;

export const Aside = styled('aside')({
  backgroundColor: theme.palette.backgroundExtension.grey1,
  borderRight: `solid 1px ${theme.palette.shade.grey4}`,
  gridColumnStart: 'left',
  gridColumnEnd: 'content',
  gridRowStart: 'content',
  gridRowEnd: 'bottom',
  position: 'sticky',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  top: DESKTOP_TOP_BAR_HEIGHT,
  alignSelf: 'start',
  height: `calc(100vh - ${DESKTOP_TOP_BAR_HEIGHT}px)`,
  zIndex: FRAME_Z_INDEX,
});

const Tabs = styled(MuiTabs)({
  width: '100%',
  marginTop: theme.spacing(2),
  overflow: 'visible',
  '& .MuiTabs-indicator': {
    width: '1px',
    right: '-1px',
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
  },
});

export function MainNavigationSidePanel({ items }: { items: readonly NavItem[] }) {
  const [showLabels, setShowLabels] = useLocalStorage<boolean>('sidePanelExpanded', false);

  const { canSeeLeadHubMainPages } = usePermissions();
  const { activeShop } = useActiveShop();
  const showActionMenu = activeShop?.onboardingLevel === 2 && canSeeLeadHubMainPages(activeShop);

  const throttle = useThrottle(50);
  const blankSpaceRef = useRef<HTMLButtonElement>(null);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const itemHeight = useRef<number>(0);
  const [maxDisplayedItems, setMaxDisplayedItems] = useState(Infinity);

  useLayoutEffect(() => {
    if (!itemHeight.current && tabContainerRef.current) {
      const fullHeight = tabContainerRef.current.getElementsByClassName('MuiTabs-scroller')[0]?.scrollHeight || 0;
      itemHeight.current = fullHeight / items.length;
    }
  }, [items.length]);

  useEffect(() => {
    const resizeListener = () => {
      throttle(() => {
        const tabContainer = tabContainerRef.current;
        const blankSpace = blankSpaceRef.current;
        if (itemHeight.current && tabContainer && blankSpace) {
          const requiredHeight = items.length * itemHeight.current;
          const availableHeight =
            tabContainer.getBoundingClientRect().height + blankSpace.getBoundingClientRect().height;

          if (availableHeight < requiredHeight - 1) {
            setMaxDisplayedItems(Math.floor(availableHeight / itemHeight.current) - 1);
          } else {
            setMaxDisplayedItems(Infinity);
          }
        }
      });
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [items.length, throttle]);

  const displayedItems = items.slice(0, maxDisplayedItems);
  const hiddenItems = items.slice(maxDisplayedItems);

  const currentTab = displayedItems.find((tab) => tab.isActive)?.label || false;

  return (
    <Aside>
      <Tabs ref={tabContainerRef} value={currentTab} orientation="vertical">
        {displayedItems.map(({ href, label, icon: Icon }) => (
          <SidePanelLinkTab
            key={`${label}_${href}`}
            href={href}
            value={label}
            label={label}
            showLabel={showLabels}
            icon={<Icon aria-hidden />}
          />
        ))}
        {hiddenItems.length > 0 ? <MoreSidePanelItems showLabel={showLabels} menuItems={hiddenItems} /> : null}
      </Tabs>

      {showActionMenu && (
        <>
          <Divider sx={{ marginX: 3, marginTop: 2, marginBottom: 3, alignSelf: 'stretch' }} />
          <ActionMenuButton showLabel={showLabels} variant="solid" />
        </>
      )}
      <Box sx={{ flexGrow: 1 }} ref={blankSpaceRef} />
      <IconButton
        size="small"
        sx={{ alignSelf: 'flex-end', margin: 3, padding: 0, border: '1px solid currentColor' }}
        onClick={() => setShowLabels(!showLabels)}
      >
        {showLabels ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </IconButton>
    </Aside>
  );
}
