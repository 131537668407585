import { useJwtToken } from '@ev/eva-container-api';
import { ActiveShopProvider } from 'components/state/ActiveShopProvider';
import { ActivityDrawerProvider } from 'components/state/ActivityDrawerProvider';
import { EmailSendingProvider } from 'components/state/EmailSendingProvider';
import { TwilioProvider } from 'components/state/Twilio';
import { env } from 'env';
import { ErrorPage } from 'eva-frame/ErrorPage';
import { ProductFruitsIntegration } from 'eva-frame/EvaProviders/ProductFruitsIntegration';
import useFreshServiceWidget from 'eva-frame/useFreshServiceWidget';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'util/i18next';
import { GoogleIntegrationCheck } from './GoogleIntegrationCheck';

export function EvaProviderWithShop({ children }: PropsWithChildren) {
  const { jwtToken } = useJwtToken();
  const { t } = useTranslation(['user']);
  useFreshServiceWidget(env.VITE_FRESH_SERVICE_URL);

  if (jwtToken.migrationOnly && env.VITE_LEADHUB_ENV !== 'migration-prod') {
    return <ErrorPage title={t('user:migration.error')} message={t('user:migration.error.message')} />;
  }

  return (
    <ActiveShopProvider>
      <GoogleIntegrationCheck />
      <ProductFruitsIntegration />
      <EmailSendingProvider>
        <ActivityDrawerProvider>
          <TwilioProvider>{children}</TwilioProvider>
        </ActivityDrawerProvider>
      </EmailSendingProvider>
    </ActiveShopProvider>
  );
}
