import {
  AddressTypeEnum,
  ContactTimeEnum,
  CountryEnum,
  EmailAddressTypeEnum,
  LanguageEnum,
  PhoneNumberTypeEnum,
  SalutationEnum,
} from 'api/graphql/generated/graphql';
import { CreateContactRequest } from 'api/rest/models/rest/Command';
import { formatISO } from 'date-fns';
import { contactRelationshipMapper } from 'util/mappers/contactRelationshipMapper';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

export function createContactMapper(data: ContactDetailsFormData, shopId: string): CreateContactRequest {
  return {
    shopId,
    company: data.isCompany ? { name: data.company as string, department: data.division } : undefined,
    note: data.additionalNote,
    tags: data.tags?.map((tag) => tag.value),
    preferredLanguage: data.preferredLanguage as LanguageEnum,
    preferredTimes: data.preferredTimes as ContactTimeEnum[],
    autoCc: data.autoCc,
    person: {
      firstName: data.firstName,
      lastName: data.lastName,
      salutation: (data.salutation as SalutationEnum) || undefined,
      title: data.title,
      birthDate: data.birthDate ? formatISO(data.birthDate, { representation: 'date' }) : undefined,
      placeOfBirth: data.placeOfBirth || undefined,
      nationality: data.nationality || undefined,
      idNumber: data.idNumber || undefined,
    },
    renterDetails: {
      householdType: data?.renterDetails?.householdType || undefined,
      professionType: data?.renterDetails?.professionType || undefined,
      petOwner: data?.renterDetails?.petOwner ?? undefined,
      incomeRange: data.renterDetails?.incomeRange ?? undefined,
    },
    contactRelationships: contactRelationshipMapper(data),
    addresses: data.addresses
      .filter(
        ({ address }) =>
          address?.streetName || address?.streetNumber || address?.postalCode || address?.city || address?.countryCode,
      )
      .map(({ address, preferred, addressType }) => ({
        ...address,
        countryCode: (address?.countryCode || 'UNDEFINED') as CountryEnum,
        addressType: (addressType || 'UNDEFINED') as AddressTypeEnum,
        addressFormat: 'FULL',
        preferred: preferred,
      })),
    phoneNumbers: data.phoneNumbers
      .map((phone) => {
        return {
          ...phone,
          phoneType: (phone.phoneType || 'UNDEFINED') as PhoneNumberTypeEnum,
          preferred: phone.preferred,
        };
      })
      .filter(({ number }) => !!number),
    contactType: data._internals.contact?.isServiceContact ? data.contactType : undefined,
    emailAddresses: data.emailAddresses
      .map((email) => ({
        ...email,
        emailType: (email.emailType || 'UNDEFINED') as EmailAddressTypeEnum,
        preferred: email.preferred,
      }))
      .filter(({ email }) => email && email.length > 0),
  };
}
