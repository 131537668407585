/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment ReferralActivity on DbReferralActivity {\n    id\n    referral {\n      ...ReferralDetailsFragment\n    }\n  }\n':
    types.ReferralActivityFragmentDoc,
  '\n  fragment ActivityTypeGrouping on DbActivityTypeGrouping {\n    activityCategory\n    activityType\n    typeLocalizeKey\n  }\n':
    types.ActivityTypeGroupingFragmentDoc,
  '\n  fragment Address on DbAddress {\n    id\n    city\n    country {\n      description\n      name\n    }\n    countryCode\n    postalCode\n    secondAddressLine\n    streetName\n    streetNumber\n    fullAddress\n    placeId\n  }\n':
    types.AddressFragmentDoc,
  '\n  fragment AgentInfo on DbAgent {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    status\n    mobilePhoneNumber\n    officePhoneNumber\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1) {\n        gcpFileName\n      }\n    }\n  }\n':
    types.AgentInfoFragmentDoc,
  '\n  fragment Agent on DbAgent {\n    ...AgentInfo\n    createdAt\n    primaryShopId\n    userName\n    salutation\n    betaTester\n    energyAdvisoryEnabled\n    nylasEmailIntegrations {\n      email\n      active\n    }\n  }\n':
    types.AgentFragmentDoc,
  '\n  fragment AgentWithTeamAssignments on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n      }\n      role\n    }\n  }\n':
    types.AgentWithTeamAssignmentsFragmentDoc,
  '\n  fragment AgentWithShopMembership on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n        shop {\n          ...ShopInfo\n        }\n      }\n      role\n    }\n    userShopAssignments {\n      shop {\n        ...ShopInfo\n      }\n      role\n    }\n    userAdminAssignments {\n      role\n    }\n    primaryTeams {\n      shopId\n      teamId\n    }\n  }\n':
    types.AgentWithShopMembershipFragmentDoc,
  '\n  fragment AgentProfileImage on DbAgentProfileImage {\n    id\n    title\n    gcpFileName\n  }\n':
    types.AgentProfileImageFragmentDoc,
  '\n  fragment AgentProfile on DbAgentProfile {\n    id\n    published\n    cityDistricts {\n      city\n      district\n    }\n    expertises {\n      expertise\n    }\n    spokenLanguages {\n      spokenLanguage\n    }\n    descriptions {\n      jobTitle\n      academicTitle\n      text\n      lang\n      url\n      isAiTranslated\n    }\n    images {\n      ...AgentProfileImage\n    }\n    socialMediaProfiles: agentProfileSocialMediaProfile {\n      facebookUrl\n      instagramUrl\n      twitterUrl\n      xingUrl\n      linkedInUrl\n      tiktokUrl\n    }\n  }\n':
    types.AgentProfileFragmentDoc,
  '\n  fragment Appointment on DbAppointment {\n    agent {\n      firstName\n      lastName\n    }\n    id\n    type\n    startTime\n    endTime\n    location\n    subject\n    status\n    agentId\n    teamId\n    comment\n    feedback\n    cost\n    propertyIds\n    go3AppointmentKey\n    appointmentContacts {\n      contactId\n    }\n    appointmentLeads {\n      leadId\n    }\n    appointmentAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n':
    types.AppointmentFragmentDoc,
  '\n  fragment AppointmentActivity on DbAppointmentActivity {\n    createdAt\n    appointment {\n      ...Appointment\n    }\n  }\n':
    types.AppointmentActivityFragmentDoc,
  '\n  fragment AuditActivity on DbAuditActivity {\n    content\n    id\n  }\n': types.AuditActivityFragmentDoc,
  '\n  fragment BaseActivityInfo on DbBaseActivity {\n    type\n    createdBy\n    createdAt\n    shopId\n  }\n':
    types.BaseActivityInfoFragmentDoc,
  '\n  fragment ActionActivity on DbBaseActivity {\n    id\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n    category\n    taskActivity {\n      task {\n        taskType {\n          name\n        }\n      }\n    }\n  }\n':
    types.ActionActivityFragmentDoc,
  '\n  fragment ActivityContactFragment on DbContact {\n    id\n    firstName\n    lastName\n    salutation\n    title\n    birthdate\n    contactStatus\n    blocked\n    preferredTimes {\n      preferredContactTime\n    }\n    personalNote\n    emailAddresses {\n      address\n      preferred\n    }\n    preferredLanguage\n    phoneNumbers {\n      number\n      preferred\n      type\n    }\n  }\n':
    types.ActivityContactFragmentFragmentDoc,
  '\n  fragment ActivityLeadFragment on DbLead {\n    contactId\n    id\n    intentType\n    status\n    expiredAt\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        countryCode\n        streetName\n        streetNumber\n        secondAddressLine\n      }\n      livingArea\n      plotArea\n    }\n    go3PropertyId\n    peLinkedPropertyId\n    buyerToRentSpecification {\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n    }\n  }\n':
    types.ActivityLeadFragmentFragmentDoc,
  '\n  fragment CreatedAndReferralActivityLeadFragment on DbLead {\n    referrals {\n      receivingShop {\n        id\n      }\n    }\n    personalNote\n\n    timeFrame\n    customerMessage\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    timeFrame\n    intentType\n  }\n':
    types.CreatedAndReferralActivityLeadFragmentFragmentDoc,
  '\n  fragment BaseActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    type\n    createdAt\n    createdBy\n    creator {\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    leadId\n    shopId\n    lead {\n      ...ActivityLeadFragment\n      ...CreatedAndReferralActivityLeadFragment\n    }\n    contact {\n      ...ActivityContactFragment\n    }\n\n    callActivity {\n      ...CallActivity\n    }\n\n    auditActivity {\n      ...AuditActivity\n    }\n\n    consentActivity {\n      ...ConsentActivity\n    }\n\n    emailActivity {\n      ...EmailActivity\n    }\n\n    exposeSharedActivity {\n      ...ExposeSharedActivity\n    }\n\n    taskActivity {\n      ...TaskActivity\n    }\n\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n    referralActivity {\n      ...ReferralActivity\n    }\n    documentActivity {\n      ...DocumentActivity\n    }\n    contactChangeLogs {\n      ...ContactChangeActivity\n    }\n    base_activity_legacy_go3_change_logs {\n      ...LegacyGo3ChangeActivity\n    }\n  }\n':
    types.BaseActivityFragmentDoc,
  '\n  fragment DashboardActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    leadId\n    contactId\n    contact {\n      firstName\n      lastName\n    }\n    taskActivity {\n      ...TaskActivity\n    }\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n  }\n':
    types.DashboardActivityFragmentDoc,
  '\n  fragment ExpandedActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    shopId\n    contact {\n      id\n      firstName\n      lastName\n    }\n    leadId\n\n    callActivity {\n      id\n      call {\n        direction\n        agent {\n          firstName\n          lastName\n        }\n        status\n        duration\n        createdAt\n        note\n        outcome\n      }\n    }\n\n    emailActivity {\n      id\n      email {\n        direction\n        subject\n        propertyIds\n        createdAt\n        from\n        to\n        cc\n        body\n      }\n    }\n\n    taskActivity {\n      id\n      task {\n        id\n        agentId\n        title\n        type\n        propertyIds\n        dueDatetime\n        status\n        updatedAt\n        comment\n      }\n    }\n    appointmentActivity {\n      id\n      appointment {\n        id\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n        status\n        updatedAt\n        comment\n      }\n    }\n    documentActivity {\n      id\n      documentReference {\n        id\n        fileName\n        contentType\n        createdAt\n        sizeKb\n      }\n    }\n    exposeSharedActivity {\n      id\n      propertyIds\n      status\n      title\n      createdAt\n      comment\n    }\n  }\n':
    types.ExpandedActivityListItemFragmentDoc,
  '\n  fragment ActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n    }\n    contactId\n    shopId\n\n    leadId\n\n    callActivity {\n      call {\n        createdAt\n        direction\n        status\n        duration\n      }\n    }\n\n    emailActivity {\n      email {\n        subject\n        propertyIds\n        createdAt\n        direction\n      }\n    }\n\n    taskActivity {\n      task {\n        agentId\n        title\n        type\n        dueDatetime\n        propertyIds\n      }\n    }\n    appointmentActivity {\n      appointment {\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n      }\n    }\n    documentActivity {\n      documentReference {\n        fileName\n        contentType\n        createdAt\n      }\n    }\n    exposeSharedActivity {\n      propertyIds\n      title\n      createdAt\n    }\n  }\n':
    types.ActivityListItemFragmentDoc,
  '\n  fragment BatchJobExecution on DbBatchJobExecution {\n    startTime\n    status\n    jobExecutionId\n    jobInstanceId\n    endTime\n    exitCode\n    exitMessage\n    batchJobExecutionParams(where: { parameterName: { _in: ["shop_id, migration_id"] } }) {\n      parameterName\n      parameterValue\n      parameterType\n    }\n    batchStepExecutions(where: { stepName: { _nlike: "%PARTITION%" } }) {\n      exitCode\n      exitMessage\n      endTime\n      readCount\n      status\n      startTime\n      stepName\n      writeCount\n      stepExecutionId\n    }\n  }\n':
    types.BatchJobExecutionFragmentDoc,
  '\n  fragment BuyerToRentSpecification on DbBuyerToRentSpecification {\n    area\n    bedroomsMax\n    bedroomsMin\n    city\n    country\n    currency\n    district\n    id\n    legacyAreaNames\n    legacyDistrictNames\n    livingAreaMax\n    livingAreaMin\n    monthlyNetRentMax\n    monthlyNetRentMin\n    monthlyTotalRentMax\n    monthlyTotalRentMin\n    plotSurfaceMax\n    plotSurfaceMin\n    priceMax\n    priceMin\n    roomsMax\n    roomsMin\n    subTypes\n    variations\n    totalSurfaceMax\n    totalSurfaceMin\n    type\n    placeIds\n    searchDemandId\n    buyerToRentSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n    customSearchDemandPolygons {\n      id\n      name\n      polygon\n    }\n  }\n':
    types.BuyerToRentSpecificationFragmentDoc,
  '\n  fragment CallInfo on DbCall {\n    id\n    callSid\n    createdAt\n    duration\n    outcome\n    note\n    status\n    shopId\n    callDirection {\n      name\n    }\n    agent {\n      ...Agent\n    }\n    recipient\n    caller\n  }\n':
    types.CallInfoFragmentDoc,
  '\n  fragment Call on DbCall {\n    call_activities {\n      baseActivity {\n        ...BaseActivityInfo\n        id\n        category\n        type\n        createdAt\n        createdBy\n        contactId\n        leadId\n        shopId\n        lead {\n          ...ActivityLeadFragment\n          ...CreatedAndReferralActivityLeadFragment\n        }\n        contact {\n          ...ActivityContactFragment\n        }\n      }\n    }\n    ...CallInfo\n  }\n':
    types.CallFragmentDoc,
  '\n  fragment CallActivity on DbCallActivity {\n    call {\n      ...CallInfo\n    }\n  }\n':
    types.CallActivityFragmentDoc,
  '\n  fragment Consent on DbConsent {\n    id\n    licenseId\n    status\n    consentType\n    consentedOn\n    updatedAt\n    revokedAt\n    expiredAt\n    consentSource\n    contactForm\n    createdBy\n    creator {\n      firstName\n      lastName\n    }\n    document {\n      ...Document\n    }\n  }\n':
    types.ConsentFragmentDoc,
  '\n  fragment ConsentActivity on DbConsentActivity {\n    id\n    consentId\n    consent {\n      createdAt\n      status\n      licenseId\n      expiredAt\n      customerAccountId\n      customerAccount {\n        emailAddress\n      }\n      contactForm\n      ipAddress\n      consentType\n      consentText\n      url\n    }\n    content\n    id\n  }\n':
    types.ConsentActivityFragmentDoc,
  '\n  fragment ContactInfo on DbContact {\n    id\n    go3Utag\n    firstName\n    lastName\n    preferredLanguage\n    blocked\n    phoneNumbers {\n      number\n      preferred\n    }\n    emailAddresses {\n      address\n      preferred\n    }\n  }\n':
    types.ContactInfoFragmentDoc,
  '\n  fragment Contact on DbContact {\n    ...ContactInfo\n    addresses {\n      ...ContactAddress\n    }\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n    legalEntity {\n      department\n      id\n      name\n    }\n    fullName\n    salutation\n    formattedSalutation\n    title\n    contactStatus\n    birthdate\n    placeOfBirth\n    nationality\n    idNumber\n    kycSearchId\n    personalNote\n    partnerFirstName\n    partnerLastName\n    deleted\n    createdAt\n    type\n    autoCc\n    customerAccountId\n    blocked\n    customerAccount {\n      emailAddress\n      consents {\n        ...Consent\n      }\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      preferred\n      type\n    }\n    preferredLanguage\n    preferredTimes {\n      preferredContactTime\n    }\n    contactRelationships {\n      relatedContactId\n      autoCc\n      type\n      relatedContact: related_contact {\n        ...ContactInfo\n      }\n    }\n\n    contactGdpr {\n      agreedToGdpr\n    }\n    contactRenterInformations {\n      contactId\n      householdType\n      professionType\n      petOwner\n      monthlyIncomeMin\n      monthlyIncomeMax\n      currency\n    }\n    tags {\n      tag {\n        id\n        name\n      }\n    }\n    preferredPhone: phoneNumbers(where: { preferred: { _eq: true } }) {\n      number\n      internationalNumber\n    }\n    preferredEmail: emailAddresses(where: { preferred: { _eq: true } }) {\n      address\n    }\n    leadhubGo3ContactLinks {\n      firstAgent {\n        ...AgentInfo\n      }\n      secondAgent {\n        ...AgentInfo\n      }\n      thirdAgent {\n        ...AgentInfo\n      }\n      firstIntentCategory\n      secondIntentCategory\n      thirdIntentCategory\n    }\n    emailLinks {\n      threadId\n    }\n    shopId\n    shop {\n      shopName\n    }\n    creator {\n      firstName\n      lastName\n    }\n    customerAccountId\n    blocked\n  }\n':
    types.ContactFragmentDoc,
  '\n  fragment ContactAndLeads on DbContact {\n    ...Contact\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n':
    types.ContactAndLeadsFragmentDoc,
  '\n  fragment ContactAddress on DbContactAddress {\n    address {\n      ...Address\n    }\n    preferred\n    type\n  }\n':
    types.ContactAddressFragmentDoc,
  '\n  fragment ContactChangeActivity on DbContactChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n':
    types.ContactChangeActivityFragmentDoc,
  '\n  fragment ContactConnectedPropertiesListing on PropertyEngineListing {\n    address {\n      streetName\n      streetNumber\n    }\n    assignedAgentId\n    assignedTeamId\n    id\n    lastActivation\n    listingOwnerContactId\n    status\n    type\n    utag\n  }\n':
    types.ContactConnectedPropertiesListingFragmentDoc,
  '\n  fragment ContactEmailAddress on DbContactEmailAddress {\n    address\n    preferred\n    type\n  }\n':
    types.ContactEmailAddressFragmentDoc,
  '\n  fragment ContactLastActivity on DbContact {\n    lastActivity: baseActivities(limit: 1, orderBy: { orderDate: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n':
    types.ContactLastActivityFragmentDoc,
  '\n  fragment ContactListEntry on DbContact {\n    addresses {\n      ...ContactAddress\n    }\n\n    contactGdpr {\n      agreedToGdpr\n      blockedDueToGdpr\n    }\n\n    createdAt\n\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n\n    firstName\n    id\n    lastName\n    type\n    blocked\n\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n    mostRecentActivityCreatedAt\n\n    tags {\n      tagId\n    }\n\n    leads {\n      ...LeadForContactList\n    }\n    shopId\n    phoneNumbers {\n      number\n      preferred\n      internationalNumber\n    }\n  }\n':
    types.ContactListEntryFragmentDoc,
  '\n  fragment ConversionData on DbConversionData {\n    referrer\n    landingPageUrlWithUtm\n    landingPageOwnerShopId\n    campaignOwnerShopId\n  }\n':
    types.ConversionDataFragmentDoc,
  '\n  fragment CustomView on DbCustomListView {\n    id\n    name\n    shownByDefault\n    type\n    viewData\n    viewIndex\n  }\n':
    types.CustomViewFragmentDoc,
  '\n  fragment DocumentInfo on DbDocumentReference {\n    id\n    displayName\n  }\n': types.DocumentInfoFragmentDoc,
  '\n  fragment Document on DbDocumentReference {\n    ...DocumentInfo\n    contactId\n    contentType\n    createdAt\n    createdBy\n    description\n    fileName\n    teamId\n    privacyLevel\n    referenceId\n    referenceType\n    sizeKb\n    description\n    createdByAgent {\n      ...Agent\n    }\n    lead {\n      ...LeadInfo\n    }\n  }\n':
    types.DocumentFragmentDoc,
  '\n  fragment DocumentActivity on DbDocumentActivity {\n    id\n    documentReference {\n      ...Document\n    }\n  }\n':
    types.DocumentActivityFragmentDoc,
  '\n  fragment DuplicateContact on DbPotentialDuplicateContact {\n    contact {\n      ...DuplicateContactDetails\n    }\n    createdAt\n    groupId\n    matchedEmail\n    matchedPhone\n  }\n':
    types.DuplicateContactFragmentDoc,
  '\n  fragment DuplicateContactDetails on DbContact {\n    contactStatus\n    createdAt\n    fullName\n    id\n    blocked\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n    }\n    emailAddresses {\n      address\n      normalizedAddress\n      preferred\n      type\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      normalizedLocalNumber\n      preferred\n      type\n    }\n    ...ContactLastActivity\n    lastInteractionActivity {\n      ...LastInteraction\n    }\n  }\n':
    types.DuplicateContactDetailsFragmentDoc,
  '\n  fragment BulkEmailFragment on DbBulkEmail {\n    id\n    from\n    to\n    cc\n    bcc\n    subject\n    body\n    status\n    createdAt\n    attachments {\n      document {\n        id\n        contentType\n        name\n      }\n    }\n    documentReferences {\n      id\n      fileName\n      sizeKb\n    }\n    status\n    trackings {\n      id\n      count\n      type\n      updatedAt\n      linkUrl\n      recipient\n      type\n    }\n    emailActivities {\n      baseActivity {\n        contactId\n      }\n    }\n    template {\n      name\n    }\n  }\n':
    types.BulkEmailFragmentFragmentDoc,
  '\n  fragment EmailActivity on DbEmailActivity {\n    email {\n      id\n      status\n      from\n      to\n      cc\n      bcc\n      subject\n      createdAt\n      direction\n      body\n      propertyIds\n      trackings {\n        id\n        count\n        type\n        recipient\n        updatedAt\n        linkUrl\n      }\n      attachments {\n        document {\n          id\n          contentType\n          name\n        }\n      }\n    }\n    id\n  }\n':
    types.EmailActivityFragmentDoc,
  '\n  fragment NylasEmailIntegration on DbNylasEmailIntegration {\n    id\n    email\n    agentId\n    shopId\n    createdAt\n    updatedAt\n    scope\n    active\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n':
    types.NylasEmailIntegrationFragmentDoc,
  '\n  fragment EmailPlaceholder on DbEmailPlaceholder {\n    name\n    mapping\n    scope\n    type\n  }\n':
    types.EmailPlaceholderFragmentDoc,
  '\n  fragment EmailSignature on DbEmailSignature {\n    id\n    scope\n    name\n    signature\n    agentId\n    shopId\n    teamId\n    createdAt\n    updatedAt\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n':
    types.EmailSignatureFragmentDoc,
  '\n  fragment EmailTemplate on DbEmailTemplate {\n    id\n    scope\n    name\n    type\n    agentId\n    contents {\n      language\n      subject\n      body\n    }\n    shopId\n    teamId\n    createdAt\n    updatedAt\n  }\n':
    types.EmailTemplateFragmentDoc,
  '\n  fragment EmailTracking on DbEmailTracking {\n    id\n    emailId\n    type\n    linkUrl\n    count\n    email {\n      messageId\n    }\n    updatedAt\n  }\n':
    types.EmailTrackingFragmentDoc,
  '\n  fragment ExposeSharedActivity on DbExposeeSharedActivity {\n    id\n    propertyIds\n    status\n    title\n    updatedAt\n    createdAt\n    comment\n  }\n':
    types.ExposeSharedActivityFragmentDoc,
  '\n  fragment KeycloakUserData on KeycloakUser {\n    firstName\n    lastName\n    email\n    ldapId\n  }\n':
    types.KeycloakUserDataFragmentDoc,
  '\n  fragment LastInteraction on DbBaseActivity {\n    ...BaseActivityInfo\n    orderDate\n    category\n    taskActivity {\n      task {\n        type\n      }\n    }\n    appointmentActivity {\n      appointment {\n        type\n      }\n    }\n  }\n':
    types.LastInteractionFragmentDoc,
  '\n  fragment LeadInfo on DbLead {\n    id\n    contactId\n    teamId\n    intentType\n    createdAt\n    expiredAt\n    status\n    timeFrame\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        fullAddress\n      }\n      livingArea\n      priceRangeTo\n      priceRangeFrom\n      priceRangeUnit\n      rentPriceUnit\n      plotArea\n    }\n    buyerToRentSpecification {\n      searchDemandId\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n      priceMax\n      priceMin\n      currency\n      area\n      country\n    }\n    emailLinks {\n      threadId\n    }\n    agentId\n    shopId\n    personalNote\n    go3PropertyId\n    peLinkedPropertyId\n    leadBundlingByLeadWithPropertyId {\n      id\n    }\n  }\n':
    types.LeadInfoFragmentDoc,
  '\n  fragment Lead on DbLead {\n    ...LeadInfo\n    contactId\n    status\n    source\n    priority\n    customerMessage\n    statusReason\n    statusNote\n    utmParams {\n      ...UtmParam\n    }\n    conversionData {\n      ...ConversionData\n    }\n    activities: baseActivities {\n      ...BaseActivityInfo\n    }\n    leadExpiryByLead {\n      ...LeadExpiryByLead\n    }\n    contact {\n      go3ContactKey\n    }\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shopId\n    teamId\n    shop {\n      ...ShopInfo\n    }\n    referrals {\n      ...Referral\n    }\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    leadBundlingByLeadWithPropertyId {\n      leadWithSearchDemand {\n        id\n      }\n    }\n    leadBundlingsByLeadWithSearchDemandId {\n      id\n      leadWithProperty {\n        ...LeadInfo\n      }\n    }\n    ...LeadLastActivity\n  }\n':
    types.LeadFragmentDoc,
  '\n  fragment LeadListEntry on DbLead {\n    id\n    status\n    source\n    createdAt\n    priority\n    statusReason\n    statusNote\n    contactId\n    buyerToRentSpecification {\n      searchDemandId\n    }\n    contact {\n      id\n      firstName\n      lastName\n      contactStatus\n      blocked\n      phoneNumbers {\n        preferred\n        number\n        internationalNumber\n        type\n      }\n      emailAddresses {\n        address\n        preferred\n      }\n      preferredTimes {\n        preferredContactTime\n      }\n    }\n    ...LeadLastActivity\n    intentType\n    timeFrame\n    go3PropertyId\n    peLinkedPropertyId\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shop {\n      id\n      shopGroup {\n        name\n        shops {\n          id\n          status\n        }\n      }\n    }\n    shopId\n    teamId\n    emailLinks {\n      threadId\n    }\n  }\n':
    types.LeadListEntryFragmentDoc,
  '\n  fragment LeadForContactList on DbLead {\n    id\n    intentType\n    agentId\n    agent {\n      firstName\n      lastName\n    }\n  }\n':
    types.LeadForContactListFragmentDoc,
  '\n  fragment LeadForAddingProperty on DbLead {\n    id\n    intentType\n    contact {\n      go3ContactKey\n    }\n    sellerRentOutSpecification {\n      type\n      subType\n      address {\n        streetName\n        streetNumber\n        city\n        postalCode\n      }\n      livingArea\n      plotArea\n      surfaceTotal\n      numberOfRooms\n      numberOfBathrooms\n    }\n  }\n':
    types.LeadForAddingPropertyFragmentDoc,
  '\n  fragment LeadExpiryByLead on DbLeadExpiry {\n    expirationDate\n    id\n    status\n  }\n':
    types.LeadExpiryByLeadFragmentDoc,
  '\n  fragment ImportLeadRequest on DbImportLeadRequest {\n    id\n    createdAt\n    payloadType\n    requestPayload\n    importLeadStatus\n    errorMessage\n    stackTrace\n    importedLeadId\n  }\n':
    types.ImportLeadRequestFragmentDoc,
  '\n  fragment LeadLastActivity on DbLead {\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n':
    types.LeadLastActivityFragmentDoc,
  '\n  fragment LegacyGo3ChangeActivity on DbLegacyGo3ChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n':
    types.LegacyGo3ChangeActivityFragmentDoc,
  '\n  fragment LicensePartner on DbLicensePartner {\n    name\n    id\n    isCompanyOwnedOperation\n    status\n    debtorNumber\n    primaryShopId\n    shops {\n      id\n      status\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      licensePartnerProfileImages(limit: 1, where: { type: { _eq: "HERO_TEASER" }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n':
    types.LicensePartnerFragmentDoc,
  '\n  fragment Listing on PropertyEngineListing {\n    utag\n    type\n    status\n    cdfs\n    asset {\n      ... on PropertyEngineApartment {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineHouse {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineLand {\n        type\n        subType\n        variation\n        plotSurface\n      }\n    }\n    assignedAgentId\n    assignedTeamId\n\n    currency\n    district\n    area\n    fileAs\n\n    monthlyNetRent\n    monthlyTotalRent\n    monthlyUtilities\n    askingPrice\n    address {\n      city\n      postalCode\n      coverLetterAddress\n      streetName\n      streetNumber\n      countryCode\n      latitude\n      longitude\n    }\n    previewImage {\n      imageUrl\n    }\n\n    marketingDetails {\n      language\n      title\n      premiumExposeEmail {\n        subject\n        description\n        bulletPoints\n        bulletPointsTitle\n      }\n    }\n    internetProfile {\n      priceOnRequest\n      portals {\n        includeInExport\n      }\n    }\n\n    lastActivation\n    status\n    listingOwnerContactId\n  }\n':
    types.ListingFragmentDoc,
  '\n  fragment MatchingStatus on PropertyEngineMatchingStatusDetail {\n    contactId\n    searchDemandId\n    matchingState\n    manuallyProposed\n    feedback\n    createdAt\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n      lead {\n        id\n      }\n    }\n  }\n':
    types.MatchingStatusFragmentDoc,
  '\n  fragment ProposedListing on PropertyEngineProposedListing {\n    matchingState\n    manuallyProposed\n    feedback\n    listing {\n      ...Listing\n    }\n    feedback\n    createdAt\n  }\n':
    types.ProposedListingFragmentDoc,
  '\n  fragment Referral on DbReferral {\n    id\n    go3ReferralId\n    referralNotPossibleReason\n    referralAgentNote\n\n    receivingShop {\n      ...ShopInfo\n    }\n  }\n':
    types.ReferralFragmentDoc,
  '\n  fragment ReferralDetailsFragment on DbReferral {\n    id\n    createdAt\n    acceptedRefusedDate\n    referralStatus\n    commission\n    note\n    sendingContactId\n    leadId\n    receivingLeadId\n    receivingGo3ContactUtag\n    type\n    leadByReceivingLeadId {\n      contact {\n        id\n        blocked\n        emailAddresses {\n          address\n          preferred\n        }\n        phoneNumbers {\n          number\n          preferred\n        }\n      }\n      agent {\n        ...Agent\n      }\n      team {\n        ...Team\n      }\n    }\n    referralAgentNote\n    referralNotPossibleReason\n    agent {\n      ...Agent\n    }\n    agentByReceivingAgentId {\n      ...Agent\n    }\n    sendingShopId\n    receivingShopId\n    receivingTeamId\n    contactByReceivingLeadIdWrapper {\n      contact {\n        id\n      }\n    }\n    sendingTeamId\n    receivingShop {\n      ...ShopInfo\n    }\n    sendingShop {\n      ...ShopInfo\n    }\n    snapshotId\n    snapshotLead {\n      contactFirstName\n      contactLastName\n      intentType\n    }\n    contact {\n      id\n    }\n    teamByReceivingTeamId {\n      id\n      name\n      shopId\n    }\n    teamBySendingTeamId {\n      id\n      name\n      shopId\n    }\n\n    leadByReceivingLeadId {\n      baseActivities {\n        callActivity {\n          ...CallActivity\n        }\n        emailActivity {\n          ...EmailActivity\n        }\n        appointmentActivity {\n          ...AppointmentActivity\n        }\n      }\n    }\n  }\n':
    types.ReferralDetailsFragmentFragmentDoc,
  '\n  fragment SellerRentOutSpecification on DbSellerRentOutSpecification {\n    constructionYear\n    condition\n    priceRangeTo\n    priceRangeFrom\n    rentRangeFrom\n    rentRangeTo\n    priceRangeUnit\n    rentPriceUnit\n    occupationStatus\n    livingArea\n    numberOfBathrooms\n    numberOfBedrooms\n    floor\n    numberOfMasterBathrooms\n    numberOfRooms\n    totalFloors\n    surfaceTotal\n    plotArea\n    surfaceUsable\n    balcony\n    garden\n    parking\n    swimmingPool\n    elevator\n    garage\n    guestToilet\n    type\n    subType\n    variation\n    id\n    owner {\n      id\n      firstName\n      lastName\n      fullName\n    }\n    ownershipStatus\n    ownerDescription\n    address {\n      ...Address\n    }\n    sellerRentOutSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n  }\n':
    types.SellerRentOutSpecificationFragmentDoc,
  '\n  fragment ShopInfo on DbShop {\n    id\n    shopName\n    city\n    countryCode\n    postalCode\n    streetName\n    phone\n    email\n    status\n    streetNumber\n    onboardingLevel\n    groupId\n    shopGroup {\n      shops {\n        id\n      }\n    }\n    latitude\n    longitude\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      id\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1, where: { type: { _eq: SHOP }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n':
    types.ShopInfoFragmentDoc,
  '\n  fragment Shop on DbShop {\n    ...ShopInfo\n    currency\n    website\n    energyAdvisoryEnabled\n    twilioConfigurations {\n      id\n      twilioNumber\n    }\n    shopGroup {\n      name\n      shops {\n        id\n        status\n      }\n    }\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      shops {\n        id\n      }\n      id\n    }\n    businessUnit\n    shopStatus {\n      description\n    }\n    teams {\n      ...Team\n    }\n  }\n':
    types.ShopFragmentDoc,
  '\n  fragment ShopWithMembers on DbShop {\n    ...Shop\n    teams {\n      ...TeamWithMembers\n    }\n    userShopAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n':
    types.ShopWithMembersFragmentDoc,
  '\n  fragment ShopNameByID on DbShop {\n    countryCode\n    shopName\n    id\n  }\n': types.ShopNameByIdFragmentDoc,
  '\n  fragment ShopGroup on DbShopGroup {\n    id\n    name\n    shops {\n      ...ShopInfo\n    }\n    description\n  }\n':
    types.ShopGroupFragmentDoc,
  '\n  fragment ShopProfile on DbShopProfile {\n    spokenLanguages {\n      spokenLanguage\n    }\n    customerMails {\n      footer\n      lang\n    }\n  }\n':
    types.ShopProfileFragmentDoc,
  '\n  fragment ShopSettings on DbShopSetting {\n    applyAutomatedContactSettings\n    ccUsersInAssignmentEmails\n    consentManagementEnabled\n    defaultCity\n    defaultCountryCode\n    defaultPreferredLanguage\n    shopId\n    sendQualificationLink\n    leadAutoAssignmentEnabled\n    leadExpiryEnabled\n    leadExpiryDaysBuyer\n    leadExpiryDaysSeller\n    leadExpiryDaysToRent\n    leadExpiryDaysRentOut\n    leadExtendingInteractionsEnabled\n    lastInteractionRecalculatedAt\n    activeToRecalculateLastInteraction\n  }\n':
    types.ShopSettingsFragmentDoc,
  '\n  fragment SnapshotLeadFragment on DbSnapshotLead {\n    id\n    contactFirstName\n    contactLastName\n    createdAt\n    intentType\n    priority\n    personalNote\n    propertyId\n    preferredEmailAddress {\n      address\n    }\n    preferredPhoneNumber {\n      number\n    }\n    snapshotBuyerToRentSpecification {\n      id\n      area\n      livingAreaMax\n      livingAreaMin\n      monthlyNetRentMax\n      monthlyNetRentMin\n      monthlyTotalRentMax\n      monthlyTotalRentMin\n      plotSurfaceMax\n      plotSurfaceMin\n      currency\n      priceMax\n      priceMin\n      roomsMin\n      roomsMax\n      type\n      subTypes\n      variations\n      placeIds\n      snapshotCustomSearchDemandPolygons {\n        id\n        name\n        polygon\n      }\n    }\n    snapshotSellerRentOutSpecification {\n      id\n      assetSubType {\n        name\n      }\n      assetType {\n        name\n      }\n      condition\n      floor\n      totalFloors\n      constructionYear\n      plotArea\n      livingArea\n      numberOfRooms\n      numberOfBathrooms\n      variation\n      priceRangeFrom\n      priceRangeTo\n      priceRangeUnit\n      rentPriceUnit\n      rentRangeFrom\n      rentRangeTo\n      city\n      country {\n        name\n      }\n      postalCode\n      countryCode\n      streetName\n      streetNumber\n      balcony\n      garden\n      parking\n      swimmingPool\n      elevator\n      garage\n      guestToilet\n    }\n  }\n':
    types.SnapshotLeadFragmentFragmentDoc,
  '\n  fragment Task on DbTask {\n    id\n    type\n    dueDatetime\n    title\n    status\n    agentId\n    teamId\n    comment\n    propertyIds\n    priority\n    go3TaskKey\n    taskContacts {\n      contactId\n    }\n    taskLeads {\n      leadId\n    }\n    taskAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n':
    types.TaskFragmentDoc,
  '\n  fragment TaskActivity on DbTaskActivity {\n    task {\n      ...Task\n    }\n  }\n':
    types.TaskActivityFragmentDoc,
  '\n  fragment TeamInfo on DbTeam {\n    id\n    name\n    shopId\n    email\n  }\n': types.TeamInfoFragmentDoc,
  '\n  fragment Team on DbTeam {\n    ...TeamInfo\n    normalizedName\n    description\n    status\n    teamStatus {\n      description\n    }\n  }\n':
    types.TeamFragmentDoc,
  '\n  fragment TeamWithMembers on DbTeam {\n    ...Team\n    userTeamAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n':
    types.TeamWithMembersFragmentDoc,
  '\n  fragment TeamWithMembersAndShop on DbTeam {\n    ...TeamWithMembers\n    shop {\n      ...ShopInfo\n    }\n  }\n':
    types.TeamWithMembersAndShopFragmentDoc,
  '\n  fragment UtmParam on DbUtmParam {\n    autaclid\n    msclkid\n    pmclid\n    campaign\n    content\n    gclid\n    medium\n    source\n    url\n    gbraid\n    wbraid\n  }\n':
    types.UtmParamFragmentDoc,
  '\n  query getLastInteractionActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...LastInteraction\n    }\n  }\n':
    types.GetLastInteractionActivitiesDocument,
  '\n  query getActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...ActionActivity\n    }\n  }\n':
    types.GetActivitiesDocument,
  '\n  query getActivitiesList(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ActivityListItem\n    }\n  }\n':
    types.GetActivitiesListDocument,
  '\n  query getExpandedActivity(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ExpandedActivityListItem\n    }\n  }\n':
    types.GetExpandedActivityDocument,
  '\n  query totalActivitiesCountQuery($where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivityAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalActivityCount: count\n      }\n    }\n  }\n':
    types.TotalActivitiesCountQueryDocument,
  '\n  query activityTypeGrouping {\n    dbActivityTypeGrouping {\n      ...ActivityTypeGrouping\n    }\n  }\n':
    types.ActivityTypeGroupingDocument,
  '\n  query AgentQuery(\n    $where: DbAgentBoolExp\n    $orderBy: [DbAgentOrderBy!]\n    $limit: Int\n    $offset: Int\n    $distinctOn: [DbAgentSelectColumn!]\n  ) {\n    dbAgent(where: $where, orderBy: $orderBy, distinctOn: $distinctOn, limit: $limit, offset: $offset) {\n      ...Agent\n    }\n  }\n':
    types.AgentQueryDocument,
  '\n  query AgentWithTeamAssignments($where: DbAgentBoolExp!) {\n    dbAgent(where: $where) {\n      ...AgentWithTeamAssignments\n    }\n  }\n':
    types.AgentWithTeamAssignmentsDocument,
  '\n  query LeadsResponsibleAgents($contactId: String) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      agentsAssignedToLeadsOfContact {\n        intentType\n        agent {\n          ...AgentInfo\n        }\n      }\n    }\n  }\n':
    types.LeadsResponsibleAgentsDocument,
  '\n  query AgentsWithAllMemberships($where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...AgentWithShopMembership\n    }\n    dbAgentAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.AgentsWithAllMembershipsDocument,
  '\n  query BulkQuery($order_by: [DbBulkEmailOrderBy!], $where: DbBulkEmailBoolExp, $limit: Int, $offset: Int) {\n    dbBulkEmail(orderBy: $order_by, where: $where, limit: $limit, offset: $offset) {\n      ...BulkEmailFragment\n    }\n  }\n':
    types.BulkQueryDocument,
  '\n  query TotalBulkEmailCountQuery($where: DbBulkEmailBoolExp) {\n    dbBulkEmailAggregate(where: $where) {\n      aggregate {\n        totalBulkEmails: count\n      }\n    }\n  }\n':
    types.TotalBulkEmailCountQueryDocument,
  '\n  query callListQuery($limit: Int, $offset: Int, $order_by: [DbCallOrderBy!], $where: DbCallBoolExp) {\n    dbCall(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Call\n    }\n  }\n':
    types.CallListQueryDocument,
  '\n  query CallQueue($shops: [String!]) {\n    dbCallAggregate(where: { status: { _eq: QUEUED }, shopId: { _in: $shops } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n':
    types.CallQueueDocument,
  '\n  query Comment($contactId: String!) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      personalNote\n    }\n  }\n':
    types.CommentDocument,
  '\n  query ConsentById($where: DbConsentBoolExp) {\n    dbConsent(where: $where) {\n      ...Consent\n    }\n  }\n':
    types.ConsentByIdDocument,
  '\n  query ContactAndLeads($id: String, $shopIds: [String!]) {\n    dbContact(where: { id: { _eq: $id }, shopId: { _in: $shopIds } }) {\n      ...ContactAndLeads\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n      ...ContactLastActivity\n    }\n  }\n':
    types.ContactAndLeadsDocument,
  '\n  query Contact($id: String) {\n    dbContact(where: { id: { _eq: $id } }) {\n      ...Contact\n    }\n  }\n':
    types.ContactDocument,
  '\n  query ContactsAndLeads($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactAndLeads\n    }\n  }\n':
    types.ContactsAndLeadsDocument,
  '\n  query Contacts($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Contact\n    }\n  }\n':
    types.ContactsDocument,
  '\n  query ContactIds($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n':
    types.ContactIdsDocument,
  '\n  query ContactsList($order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(orderBy: $order_by, where: $where) {\n      ...ContactListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n':
    types.ContactsListDocument,
  '\n  query ContactsListCount($where: DbContactBoolExp, $limit: Int) {\n    dbContactAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.ContactsListCountDocument,
  '\n  query publicContactQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbContactDetailsOrderBy!]\n    $where: DbContactDetailsBoolExp\n  ) {\n    dbContactDetails(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      firstName\n      lastName\n      id\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n':
    types.PublicContactQueryDocument,
  '\n  query contactInfosQuery($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactInfo\n    }\n  }\n':
    types.ContactInfosQueryDocument,
  '\n  query contactInfoWithRelationshipsQuery($contactId: String, $where: DbContactBoolExp) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      contactRelationships(where: { related_contact: $where }) {\n        type\n        relatedContact: related_contact {\n          ...ContactInfo\n        }\n      }\n    }\n  }\n':
    types.ContactInfoWithRelationshipsQueryDocument,
  '\n  query ContactTags($contactId: String!) {\n    dbContactTag(where: { contactId: { _eq: $contactId } }) {\n      tag {\n        name\n      }\n    }\n  }\n':
    types.ContactTagsDocument,
  '\n  query ContactAllTags($limit: Int, $offset: Int, $order_by: [DbContactTagOrderBy!], $where: DbContactTagBoolExp) {\n    dbContactTag(limit: $limit, offset: $offset, orderBy: $order_by, where: $where, distinctOn: tagId) {\n      tag {\n        id\n        name\n      }\n    }\n  }\n':
    types.ContactAllTagsDocument,
  '\n  query ContactsConnectedProperties($ownerContactId: String!, $shopId: PropertyEngineUUID!) {\n    propertyEngine {\n      searchListings(shopId: $shopId, where: { ownerContactId: $ownerContactId }) {\n        listings {\n          ...ContactConnectedPropertiesListing\n        }\n        total\n      }\n    }\n  }\n':
    types.ContactsConnectedPropertiesDocument,
  '\n  query CustomDemandFields($where: DbCdfBoolExp) {\n    dbCdf(where: $where, orderBy: { createdAt: DESC }) {\n      id\n      label\n      shopId\n    }\n  }\n':
    types.CustomDemandFieldsDocument,
  '\n  query CustomViewsList($activeShopId: String, $type: EnumsCustomViewTypeEnum) {\n    dbCustomListView(orderBy: { viewIndex: ASC }, where: { type: { _eq: $type }, shopId: { _eq: $activeShopId } }) {\n      ...CustomView\n    }\n  }\n':
    types.CustomViewsListDocument,
  '\n  query AgentDashboardActivity($order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivity(where: $where, orderBy: $order_by, limit: $limit) {\n      ...DashboardActivity\n    }\n  }\n':
    types.AgentDashboardActivityDocument,
  '\n  query Documents(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Document\n    }\n  }\n':
    types.DocumentsDocument,
  '\n  query DocumentsCount($where: DbDocumentReferenceBoolExp) {\n    dbDocumentReferenceAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.DocumentsCountDocument,
  '\n  query DocumentsInfoQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...DocumentInfo\n    }\n  }\n':
    types.DocumentsInfoQueryDocument,
  '\n  query DocumentContactIdsQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      emailActivity {\n        emailId\n      }\n      printedDocumentActivity {\n        printedDocumentId\n      }\n      appointmentActivity {\n        appointmentId\n      }\n    }\n  }\n':
    types.DocumentContactIdsQueryDocument,
  '\n  query DuplicateContactGroupIds(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      groupId\n    }\n  }\n':
    types.DuplicateContactGroupIdsDocument,
  '\n  query DuplicateContact(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      ...DuplicateContact\n      contact {\n        lastInteractionActivity {\n          ...LastInteraction\n        }\n      }\n    }\n  }\n':
    types.DuplicateContactDocument,
  '\n  query DuplicateContactCount(\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContactAggregate(where: $where, distinctOn: $distinctOn) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.DuplicateContactCountDocument,
  '\n  query EmailLinksQuery($where: DbEmailLinkBoolExp, $contactRelationshipWhere: DbContactRelationshipBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n      contactId\n      leadId\n      contact {\n        ...ContactAndLeads\n      }\n    }\n  }\n':
    types.EmailLinksQueryDocument,
  '\n  query EmailLinkThreadIdQuery($where: DbEmailLinkBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n    }\n  }\n':
    types.EmailLinkThreadIdQueryDocument,
  '\n  query EmailPlaceholderQuery($where: DbEmailPlaceholderBoolExp) {\n    dbEmailPlaceholder(orderBy: { name: ASC }, where: $where) {\n      ...EmailPlaceholder\n    }\n  }\n':
    types.EmailPlaceholderQueryDocument,
  '\n  query EmailSignaturesQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbEmailSignatureOrderBy!]\n    $where: DbEmailSignatureBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbEmailSignature(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...EmailSignature\n    }\n  }\n':
    types.EmailSignaturesQueryDocument,
  '\n  query EmailTemplatesQuery($order_by: [DbEmailTemplateOrderBy!], $where: DbEmailTemplateBoolExp) {\n    dbEmailTemplate(orderBy: $order_by, where: $where) {\n      ...EmailTemplate\n    }\n  }\n':
    types.EmailTemplatesQueryDocument,
  '\n  query EmailTrackingQuery($where: DbEmailTrackingBoolExp) {\n    dbEmailTracking(where: $where) {\n      ...EmailTracking\n    }\n  }\n':
    types.EmailTrackingQueryDocument,
  '\n  query GetEnableFeatures {\n    dbEnabledFeatureFlags {\n      enabledFeature\n    }\n  }\n':
    types.GetEnableFeaturesDocument,
  '\n  query FailedLeadImports($limit: Int, $offset: Int) {\n    dbImportLeadRequest(\n      limit: $limit\n      offset: $offset\n      where: { importLeadStatus: { _eq: FAILED } }\n      orderBy: { createdAt: DESC }\n    ) {\n      ...ImportLeadRequest\n    }\n  }\n':
    types.FailedLeadImportsDocument,
  '\n  query FailedLeadImportsTotalCount {\n    dbImportLeadRequestAggregate(where: { importLeadStatus: { _eq: FAILED } }) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.FailedLeadImportsTotalCountDocument,
  '\n  query FindProposedListingsQuery(\n    $contactId: String!\n    $matchingState: PropertyEngineMatchingState\n    $searchDemandIds: [PropertyEngineUUID!]\n    $addressQuery: String\n    $listingType: PropertyEngineListingType\n    $matchingStatusOrder: PropertyEngineMatchingStatusOrder\n    $page: Int = 0\n    $perPage: Int = 100\n  ) {\n    propertyEngine {\n      findProposedListings(\n        contactId: $contactId\n        matchingState: $matchingState\n        searchDemandIds: $searchDemandIds\n        addressQuery: $addressQuery\n        listingType: $listingType\n        matchingStatusOrder: $matchingStatusOrder\n        page: $page\n        perPage: $perPage\n      ) {\n        total\n        proposedListings {\n          ...ProposedListing\n        }\n      }\n    }\n  }\n':
    types.FindProposedListingsQueryDocument,
  '\n  query FindProposedListingsCountQuery($contactId: String!) {\n    propertyEngine {\n      findProposedListings(contactId: $contactId) {\n        total\n      }\n    }\n  }\n':
    types.FindProposedListingsCountQueryDocument,
  '\n  query FullLeadsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Lead\n    }\n  }\n':
    types.FullLeadsQueryDocument,
  '\n  query GetAllEnums {\n    enumsBaseActivityCategory {\n      name\n    }\n    enumsActivityType {\n      name\n    }\n    enumsAddressType {\n      name\n    }\n    enumsContactTime {\n      name\n    }\n    enumsCountry {\n      name\n    }\n    enumsLanguage {\n      name\n    }\n    enumsLeadSource {\n      name\n    }\n    enumsLeadStatus {\n      name\n    }\n    enumsPhoneNumberType {\n      name\n    }\n    enumsEmailAddressType {\n      name\n    }\n    enumsSalutation {\n      name\n    }\n    enumsGender {\n      name\n    }\n    enumsAssetType {\n      name\n    }\n    enumsAssetSubType {\n      name\n    }\n    enumsPriority {\n      name\n    }\n    enumsIntentType {\n      name\n    }\n    enumsAssetCondition {\n      name\n    }\n    enumsReferralNotPossibleReason {\n      name\n    }\n    enumsZeroCommissionReason {\n      name\n    }\n    enumsEmailSignatureScope {\n      name\n    }\n    enumsEmailTemplateScope {\n      name\n    }\n    enumsUserRole {\n      name\n    }\n    enumsTimeFrame {\n      name\n    }\n    enumsLeadStatusReason {\n      name\n    }\n    enumsShopExpertise {\n      name\n    }\n    enumsIntentCategory {\n      name\n    }\n    enumsContactRelationType {\n      name\n    }\n    enumsCallStatus {\n      name\n    }\n    enumsCallOutcome {\n      name\n    }\n    enumsTaskAppointmentStatus {\n      name\n    }\n    enumsTaskType {\n      name\n    }\n    enumsAppointmentType {\n      name\n    }\n    enumsSharedType {\n      name\n    }\n    enumsPropertyOwnershipStatus {\n      name\n    }\n    enumsPropertyOccupationStatus {\n      name\n    }\n    enumsEntityType {\n      name\n    }\n    enumsPrivacyLevel {\n      name\n    }\n    enumsMandatoryShopProfileField {\n      name\n    }\n    enumsConsentSource {\n      name\n    }\n    enumsHouseholdType {\n      name\n    }\n    enumsProfessionType {\n      name\n    }\n    enumsSpokenLanguage {\n      name\n    }\n  }\n':
    types.GetAllEnumsDocument,
  '\n  query GoogleIntegrations(\n    $order_by: [DbNylasEmailIntegrationOrderBy!]\n    $where: DbNylasEmailIntegrationBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbNylasEmailIntegration(orderBy: $order_by, where: $where) {\n      ...NylasEmailIntegration\n    }\n  }\n':
    types.GoogleIntegrationsDocument,
  '\n  query LeadById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...Lead\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n':
    types.LeadByIdDocument,
  '\n  query Go3PropertyIdByLeadId($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      go3PropertyId\n    }\n  }\n':
    types.Go3PropertyIdByLeadIdDocument,
  '\n  query leadAndContactById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n':
    types.LeadAndContactByIdDocument,
  '\n  query LeadBaseInfoById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n    }\n  }\n':
    types.LeadBaseInfoByIdDocument,
  '\n  query LeadInfoByIdForAddingProperty($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadForAddingProperty\n    }\n  }\n':
    types.LeadInfoByIdForAddingPropertyDocument,
  '\n  query LeadsQuery($limit: Int, $offset: Int, $where: DbLeadBoolExp, $lastInteractionWhere: DbBaseActivityBoolExp) {\n    dbLead(limit: $limit, offset: $offset, where: $where) {\n      ...LeadInfo\n    }\n  }\n':
    types.LeadsQueryDocument,
  '\n  query LeadsOfContactQuery($limit: Int, $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, where: $where) {\n      ...Lead\n    }\n  }\n':
    types.LeadsOfContactQueryDocument,
  '\n  query LeadBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n':
    types.LeadBaseActivityDocument,
  '\n  query LeadListIdsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n':
    types.LeadListIdsQueryDocument,
  '\n  query LeadListQuery($order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(orderBy: $order_by, where: $where) {\n      ...LeadListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n':
    types.LeadListQueryDocument,
  '\n  query LeadInfoListQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LeadInfo\n    }\n  }\n':
    types.LeadInfoListQueryDocument,
  '\n  query leadSourceOptions($shopIds: [String!]) {\n    dbLead(limit: 250, distinctOn: source, where: { shopId: { _in: $shopIds } }) {\n      source\n    }\n  }\n':
    types.LeadSourceOptionsDocument,
  '\n  query leadStatistics($where: DbLeadStatisticsBoolExp) {\n    dbLeadStatistics(where: $where) {\n      count\n      originalLeadSource\n      createdDate\n    }\n  }\n':
    types.LeadStatisticsDocument,
  '\n  query licensePartners(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbLicensePartnerOrderBy!]\n    $where: DbLicensePartnerBoolExp\n  ) {\n    dbLicensePartner(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LicensePartner\n    }\n  }\n':
    types.LicensePartnersDocument,
  '\n  query getPropertyDetailQuery($utag: PropertyEngineListingUtag!) {\n    propertyEngine {\n      getListingByUtag(utag: $utag) {\n        ...Listing\n      }\n    }\n  }\n':
    types.GetPropertyDetailQueryDocument,
  '\n  query ListingsQuery(\n    $page: Int\n    $perPage: Int\n    $where: PropertyEngineSearchListingsFilterInput!\n    $shopId: PropertyEngineUUID!\n  ) {\n    propertyEngine {\n      searchListings(page: $page, perPage: $perPage, where: $where, shopId: $shopId) {\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n':
    types.ListingsQueryDocument,
  '\n  query MatchingCountQuery($searchDemandId: PropertyEngineUUID!) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(id: $searchDemandId) {\n        total\n      }\n    }\n  }\n':
    types.MatchingCountQueryDocument,
  '\n  query MatchingsQuery(\n    $searchDemandId: PropertyEngineUUID!\n    $page: Int\n    $perPage: Int\n    $order: PropertyEngineListingOrder\n    $addressQuery: String\n    $listingStatus: [PropertyEngineListingStatus!]\n  ) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(\n        id: $searchDemandId\n        page: $page\n        perPage: $perPage\n        order: $order\n        addressQuery: $addressQuery\n        listingStatus: $listingStatus\n      ) {\n        total\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n':
    types.MatchingsQueryDocument,
  '\n  mutation UpsertMatchingStatus($input: [PropertyEngineMatchingStatusInput!]!) {\n    propertyEngine {\n      upsertMatchingStatus(input: $input) {\n        id\n      }\n    }\n  }\n':
    types.UpsertMatchingStatusDocument,
  '\n  query findMatchingStatus($listingUtag: PropertyEngineListingUtag!, $contactId: String!) {\n    propertyEngine {\n      findMatchingStatus(listingUtag: $listingUtag, contactId: $contactId) {\n        ...MatchingStatus\n      }\n    }\n  }\n':
    types.FindMatchingStatusDocument,
  '\n  query batchJobExecution($limit: Int, $offset: Int) {\n    dbBatchJobExecution(limit: $limit, offset: $offset, orderBy: { jobExecutionId: DESC }) {\n      ...BatchJobExecution\n    }\n  }\n':
    types.BatchJobExecutionDocument,
  '\n  query NewsQuery($limit: Int, $offset: Int, $order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      createdAt\n      type\n      auditActivity {\n        content\n      }\n      contact {\n        firstName\n        lastName\n        id\n      }\n      lead {\n        ...LeadInfo\n        status\n      }\n    }\n  }\n':
    types.NewsQueryDocument,
  '\n  query CountryMandatoryLanguage(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbCountryMandatoryLanguageOrderBy!]\n    $where: DbCountryMandatoryLanguageBoolExp\n  ) {\n    dbCountryMandatoryLanguage(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      countryCode\n      lang\n    }\n  }\n':
    types.CountryMandatoryLanguageDocument,
  '\n  query MyQuery(\n    $dimensions: [PropertyEngineDashboardFeedListingsDimension!]!\n    $shopId: PropertyEngineUUID!\n    $dateFrom: PropertyEngineDateTime!\n    $perPage: Int\n  ) {\n    propertyEngine {\n      dashboardFeedListings(dimensions: $dimensions, shopId: $shopId, dateTimeFrom: $dateFrom, perPage: $perPage) {\n        total\n        results {\n          listing {\n            id\n            type\n            status\n            utag\n            fileAs\n            lastActivation\n            lastAssignedAgentChange\n            lastPriceReduction\n            previewImage {\n              imageUrl\n            }\n            pictures {\n              imageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.MyQueryDocument,
  '\n  query ReferralQuery($limit: Int, $offset: Int, $order_by: [DbReferralOrderBy!], $where: DbReferralBoolExp) {\n    dbReferral(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ReferralDetailsFragment\n    }\n  }\n':
    types.ReferralQueryDocument,
  '\n  query TotalReferralCount($where: DbReferralBoolExp) {\n    dbReferralAggregate(where: $where) {\n      aggregate {\n        count\n      }\n    }\n  }\n':
    types.TotalReferralCountDocument,
  '\n  query ReferralSendingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingShopId) {\n      sendingShop {\n        id\n        shopName\n      }\n    }\n  }\n':
    types.ReferralSendingShopsQueryDocument,
  '\n  query ReferralSendingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: agentId) {\n      agent {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n':
    types.ReferralSendingAgentsQueryDocument,
  '\n  query ReferralReceivingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingShopId) {\n      receivingShop {\n        id\n        shopName\n      }\n    }\n  }\n':
    types.ReferralReceivingShopsQueryDocument,
  '\n  query ReferralReceivingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingTeamId) {\n      teamByReceivingTeamId {\n        id\n        name\n      }\n    }\n  }\n':
    types.ReferralReceivingTeamsQueryDocument,
  '\n  query ReferralSendingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingTeamId) {\n      teamBySendingTeamId {\n        id\n        name\n      }\n    }\n  }\n':
    types.ReferralSendingTeamsQueryDocument,
  '\n  query ReferralReceivingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingAgentId) {\n      agentByReceivingAgentId {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n':
    types.ReferralReceivingAgentsQueryDocument,
  '\n  query ReferralWithSnapshotLeadQuery($referralId: String) {\n    dbReferral(where: { id: { _eq: $referralId } }) {\n      ...ReferralDetailsFragment\n      snapshotLead {\n        ...SnapshotLeadFragment\n      }\n    }\n  }\n':
    types.ReferralWithSnapshotLeadQueryDocument,
  '\n  # Returns the list of all shops that the leads are assigned to that are assigned to $shopId\n  query ReferredShops($shopId: String!) {\n    dbReferral(where: { sendingShopId: { _eq: $shopId } }, distinctOn: receivingShopId) {\n      receivingShop {\n        ...ShopInfo\n      }\n    }\n  }\n':
    types.ReferredShopsDocument,
  '\n  query Lead($where: DbLeadBoolExp) {\n    dbLead(where: $where) {\n      ...Lead\n    }\n  }\n':
    types.LeadDocument,
  '\n  query SearchKeycloakUsers($search: String!, $limit: Int!) {\n    keycloakUser(search: $search, limit: $limit) {\n      ...KeycloakUserData\n    }\n  }\n':
    types.SearchKeycloakUsersDocument,
  '\n  query shopBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n':
    types.ShopBaseActivityDocument,
  '\n  query ShopGroups($limit: Int, $offset: Int, $order_by: [DbShopGroupOrderBy!], $where: DbShopGroupBoolExp) {\n    dbShopGroup(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopGroup\n    }\n  }\n':
    types.ShopGroupsDocument,
  '\n  query TotalShopGroupCount {\n    dbShopGroupAggregate {\n      aggregate {\n        count\n      }\n    }\n  }\n':
    types.TotalShopGroupCountDocument,
  '\n  query AgentsInShop($shopId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shopId: { _eq: $shopId } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n      primaryTeams(where: { shopId: { _eq: $shopId } }) {\n        shopId\n        teamId\n      }\n    }\n  }\n':
    types.AgentsInShopDocument,
  '\n  query TotalAgentsInShopCount($shopId: String, $where: DbAgentBoolExp!) {\n    dbAgentAggregate(\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.TotalAgentsInShopCountDocument,
  '\n  query AgentsInLipa($lipaId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shop: { licensePartnerId: { _eq: $lipaId } } } }\n              { userTeamAssignments: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shop: { licensePartnerId: { _eq: $lipaId } } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n    }\n  }\n':
    types.AgentsInLipaDocument,
  '\n  query ShopProfile($shopId: String, $where: DbShopProfileBoolExp!) {\n    dbShopProfile(where: { _and: [$where, { shopId: { _eq: $shopId }, published: { _isNull: true } }] }) {\n      ...ShopProfile\n    }\n  }\n':
    types.ShopProfileDocument,
  '\n  query ShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      ...ShopSettings\n    }\n  }\n':
    types.ShopSettingsDocument,
  '\n  query AutoExposeShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        qualiformMandatoryFields {\n          fieldName\n        }\n        qualiformLeadIntents {\n          intentType\n        }\n        qualiformLeadSources {\n          leadSource\n        }\n        qualiformShopSettings {\n          secondaryLanguageEnglishEnabled\n        }\n      }\n    }\n  }\n':
    types.AutoExposeShopSettingsDocument,
  '\n  query AutoLeadAssignmentShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        leadAutoAssignmentIntents {\n          intentType\n        }\n        leadAutoAssignmentRules {\n          leadAssignmentRule\n        }\n      }\n    }\n  }\n':
    types.AutoLeadAssignmentShopSettingsDocument,
  '\n  query ConsentManagementShopSettings($shopId: String!) {\n    dbShopManagedConsent(where: { shopId: { _eq: $shopId } }) {\n      consentType\n    }\n  }\n':
    types.ConsentManagementShopSettingsDocument,
  '\n  query LastInteractionSettings($shopId: String!) {\n    dbShopSetting(where: { shopId: { _eq: $shopId } }) {\n      leadExtendingInteractionsEnabled\n    }\n  }\n':
    types.LastInteractionSettingsDocument,
  '\n  query ShopLastInteractionActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n':
    types.ShopLastInteractionActivitiesDocument,
  '\n  query ShopTags($shopIds: [String!]!) {\n    dbTag(where: { shopId: { _in: $shopIds }, deleted: { _eq: false } }) {\n      id\n      name\n    }\n  }\n':
    types.ShopTagsDocument,
  '\n  query Shops($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Shop\n    }\n  }\n':
    types.ShopsDocument,
  '\n  query ShopInfo($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopInfo\n    }\n  }\n':
    types.ShopInfoDocument,
  '\n  query ShopsWithMembers($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopWithMembers\n    }\n  }\n':
    types.ShopsWithMembersDocument,
  '\n  query TotalShopCount($where: DbShopBoolExp) {\n    dbShopAggregate(where: $where) {\n      aggregate {\n        totalShopCount: count\n      }\n    }\n  }\n':
    types.TotalShopCountDocument,
  '\n  query shopNameByIDs($shopIds: [String!]!) {\n    dbShop(where: { id: { _in: $shopIds } }) {\n      ...ShopNameByID\n    }\n  }\n':
    types.ShopNameByIDsDocument,
  '\n  query LeadExtendingActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n':
    types.LeadExtendingActivitiesDocument,
  '\n  query systemUserIdsQuery($systemUsers: [String!]) {\n    dbAgent(where: { userName: { _in: $systemUsers } }) {\n      id\n    }\n  }\n':
    types.SystemUserIdsQueryDocument,
  '\n  query Tags($where: DbTagBoolExp) {\n    dbTag(where: $where) {\n      id\n      name\n      description\n    }\n  }\n':
    types.TagsDocument,
  '\n  query teamsWithMembers($where: DbTeamBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...TeamWithMembersAndShop\n    }\n    dbTeamAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n':
    types.TeamsWithMembersDocument,
  '\n  query teamInfo($where: DbTeamBoolExp!, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(where: $where, orderBy: $orderBy) {\n      ...TeamInfo\n    }\n  }\n':
    types.TeamInfoDocument,
  '\n  query TeamsAssignedToAgentInShop($where: DbAgentBoolExp!, $shopId: String!) {\n    dbAgent(where: $where) {\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          name\n          id\n        }\n      }\n    }\n    dbTeam(where: { shopId: { _eq: $shopId } }) {\n      id\n      name\n    }\n  }\n':
    types.TeamsAssignedToAgentInShopDocument,
  '\n  query TotalCallCountQuery($where: DbCallBoolExp) {\n    dbCallAggregate(where: $where) {\n      aggregate {\n        totalCallCount: count\n      }\n    }\n  }\n':
    types.TotalCallCountQueryDocument,
  '\n  query TotalEmailSignaturesQuery($where: DbEmailSignatureBoolExp) {\n    dbEmailSignatureAggregate(where: $where) {\n      aggregate {\n        totalEmailSignatures: count\n      }\n    }\n  }\n':
    types.TotalEmailSignaturesQueryDocument,
  '\n  query TotalLeadCountQuery($where: DbLeadBoolExp, $limit: Int) {\n    dbLeadAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalLeadCount: count\n      }\n    }\n  }\n':
    types.TotalLeadCountQueryDocument,
  '\n  query VerifyNumberQuery($shopId: String!) {\n    dbTwilioConfiguration(where: { shopId: { _eq: $shopId } }) {\n      displayNumber\n      twilioNumber\n    }\n  }\n':
    types.VerifyNumberQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ReferralActivity on DbReferralActivity {\n    id\n    referral {\n      ...ReferralDetailsFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment ReferralActivity on DbReferralActivity {\n    id\n    referral {\n      ...ReferralDetailsFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActivityTypeGrouping on DbActivityTypeGrouping {\n    activityCategory\n    activityType\n    typeLocalizeKey\n  }\n',
): (typeof documents)['\n  fragment ActivityTypeGrouping on DbActivityTypeGrouping {\n    activityCategory\n    activityType\n    typeLocalizeKey\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Address on DbAddress {\n    id\n    city\n    country {\n      description\n      name\n    }\n    countryCode\n    postalCode\n    secondAddressLine\n    streetName\n    streetNumber\n    fullAddress\n    placeId\n  }\n',
): (typeof documents)['\n  fragment Address on DbAddress {\n    id\n    city\n    country {\n      description\n      name\n    }\n    countryCode\n    postalCode\n    secondAddressLine\n    streetName\n    streetNumber\n    fullAddress\n    placeId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AgentInfo on DbAgent {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    status\n    mobilePhoneNumber\n    officePhoneNumber\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1) {\n        gcpFileName\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AgentInfo on DbAgent {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    status\n    mobilePhoneNumber\n    officePhoneNumber\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1) {\n        gcpFileName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Agent on DbAgent {\n    ...AgentInfo\n    createdAt\n    primaryShopId\n    userName\n    salutation\n    betaTester\n    energyAdvisoryEnabled\n    nylasEmailIntegrations {\n      email\n      active\n    }\n  }\n',
): (typeof documents)['\n  fragment Agent on DbAgent {\n    ...AgentInfo\n    createdAt\n    primaryShopId\n    userName\n    salutation\n    betaTester\n    energyAdvisoryEnabled\n    nylasEmailIntegrations {\n      email\n      active\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AgentWithTeamAssignments on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n      }\n      role\n    }\n  }\n',
): (typeof documents)['\n  fragment AgentWithTeamAssignments on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n      }\n      role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AgentWithShopMembership on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n        shop {\n          ...ShopInfo\n        }\n      }\n      role\n    }\n    userShopAssignments {\n      shop {\n        ...ShopInfo\n      }\n      role\n    }\n    userAdminAssignments {\n      role\n    }\n    primaryTeams {\n      shopId\n      teamId\n    }\n  }\n',
): (typeof documents)['\n  fragment AgentWithShopMembership on DbAgent {\n    ...Agent\n    userTeamAssignments {\n      team {\n        ...Team\n        shop {\n          ...ShopInfo\n        }\n      }\n      role\n    }\n    userShopAssignments {\n      shop {\n        ...ShopInfo\n      }\n      role\n    }\n    userAdminAssignments {\n      role\n    }\n    primaryTeams {\n      shopId\n      teamId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AgentProfileImage on DbAgentProfileImage {\n    id\n    title\n    gcpFileName\n  }\n',
): (typeof documents)['\n  fragment AgentProfileImage on DbAgentProfileImage {\n    id\n    title\n    gcpFileName\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AgentProfile on DbAgentProfile {\n    id\n    published\n    cityDistricts {\n      city\n      district\n    }\n    expertises {\n      expertise\n    }\n    spokenLanguages {\n      spokenLanguage\n    }\n    descriptions {\n      jobTitle\n      academicTitle\n      text\n      lang\n      url\n      isAiTranslated\n    }\n    images {\n      ...AgentProfileImage\n    }\n    socialMediaProfiles: agentProfileSocialMediaProfile {\n      facebookUrl\n      instagramUrl\n      twitterUrl\n      xingUrl\n      linkedInUrl\n      tiktokUrl\n    }\n  }\n',
): (typeof documents)['\n  fragment AgentProfile on DbAgentProfile {\n    id\n    published\n    cityDistricts {\n      city\n      district\n    }\n    expertises {\n      expertise\n    }\n    spokenLanguages {\n      spokenLanguage\n    }\n    descriptions {\n      jobTitle\n      academicTitle\n      text\n      lang\n      url\n      isAiTranslated\n    }\n    images {\n      ...AgentProfileImage\n    }\n    socialMediaProfiles: agentProfileSocialMediaProfile {\n      facebookUrl\n      instagramUrl\n      twitterUrl\n      xingUrl\n      linkedInUrl\n      tiktokUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Appointment on DbAppointment {\n    agent {\n      firstName\n      lastName\n    }\n    id\n    type\n    startTime\n    endTime\n    location\n    subject\n    status\n    agentId\n    teamId\n    comment\n    feedback\n    cost\n    propertyIds\n    go3AppointmentKey\n    appointmentContacts {\n      contactId\n    }\n    appointmentLeads {\n      leadId\n    }\n    appointmentAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Appointment on DbAppointment {\n    agent {\n      firstName\n      lastName\n    }\n    id\n    type\n    startTime\n    endTime\n    location\n    subject\n    status\n    agentId\n    teamId\n    comment\n    feedback\n    cost\n    propertyIds\n    go3AppointmentKey\n    appointmentContacts {\n      contactId\n    }\n    appointmentLeads {\n      leadId\n    }\n    appointmentAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AppointmentActivity on DbAppointmentActivity {\n    createdAt\n    appointment {\n      ...Appointment\n    }\n  }\n',
): (typeof documents)['\n  fragment AppointmentActivity on DbAppointmentActivity {\n    createdAt\n    appointment {\n      ...Appointment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AuditActivity on DbAuditActivity {\n    content\n    id\n  }\n',
): (typeof documents)['\n  fragment AuditActivity on DbAuditActivity {\n    content\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BaseActivityInfo on DbBaseActivity {\n    type\n    createdBy\n    createdAt\n    shopId\n  }\n',
): (typeof documents)['\n  fragment BaseActivityInfo on DbBaseActivity {\n    type\n    createdBy\n    createdAt\n    shopId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActionActivity on DbBaseActivity {\n    id\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n    category\n    taskActivity {\n      task {\n        taskType {\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ActionActivity on DbBaseActivity {\n    id\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n    category\n    taskActivity {\n      task {\n        taskType {\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActivityContactFragment on DbContact {\n    id\n    firstName\n    lastName\n    salutation\n    title\n    birthdate\n    contactStatus\n    blocked\n    preferredTimes {\n      preferredContactTime\n    }\n    personalNote\n    emailAddresses {\n      address\n      preferred\n    }\n    preferredLanguage\n    phoneNumbers {\n      number\n      preferred\n      type\n    }\n  }\n',
): (typeof documents)['\n  fragment ActivityContactFragment on DbContact {\n    id\n    firstName\n    lastName\n    salutation\n    title\n    birthdate\n    contactStatus\n    blocked\n    preferredTimes {\n      preferredContactTime\n    }\n    personalNote\n    emailAddresses {\n      address\n      preferred\n    }\n    preferredLanguage\n    phoneNumbers {\n      number\n      preferred\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActivityLeadFragment on DbLead {\n    contactId\n    id\n    intentType\n    status\n    expiredAt\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        countryCode\n        streetName\n        streetNumber\n        secondAddressLine\n      }\n      livingArea\n      plotArea\n    }\n    go3PropertyId\n    peLinkedPropertyId\n    buyerToRentSpecification {\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n    }\n  }\n',
): (typeof documents)['\n  fragment ActivityLeadFragment on DbLead {\n    contactId\n    id\n    intentType\n    status\n    expiredAt\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        countryCode\n        streetName\n        streetNumber\n        secondAddressLine\n      }\n      livingArea\n      plotArea\n    }\n    go3PropertyId\n    peLinkedPropertyId\n    buyerToRentSpecification {\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CreatedAndReferralActivityLeadFragment on DbLead {\n    referrals {\n      receivingShop {\n        id\n      }\n    }\n    personalNote\n\n    timeFrame\n    customerMessage\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    timeFrame\n    intentType\n  }\n',
): (typeof documents)['\n  fragment CreatedAndReferralActivityLeadFragment on DbLead {\n    referrals {\n      receivingShop {\n        id\n      }\n    }\n    personalNote\n\n    timeFrame\n    customerMessage\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    timeFrame\n    intentType\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BaseActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    type\n    createdAt\n    createdBy\n    creator {\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    leadId\n    shopId\n    lead {\n      ...ActivityLeadFragment\n      ...CreatedAndReferralActivityLeadFragment\n    }\n    contact {\n      ...ActivityContactFragment\n    }\n\n    callActivity {\n      ...CallActivity\n    }\n\n    auditActivity {\n      ...AuditActivity\n    }\n\n    consentActivity {\n      ...ConsentActivity\n    }\n\n    emailActivity {\n      ...EmailActivity\n    }\n\n    exposeSharedActivity {\n      ...ExposeSharedActivity\n    }\n\n    taskActivity {\n      ...TaskActivity\n    }\n\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n    referralActivity {\n      ...ReferralActivity\n    }\n    documentActivity {\n      ...DocumentActivity\n    }\n    contactChangeLogs {\n      ...ContactChangeActivity\n    }\n    base_activity_legacy_go3_change_logs {\n      ...LegacyGo3ChangeActivity\n    }\n  }\n',
): (typeof documents)['\n  fragment BaseActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    type\n    createdAt\n    createdBy\n    creator {\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    leadId\n    shopId\n    lead {\n      ...ActivityLeadFragment\n      ...CreatedAndReferralActivityLeadFragment\n    }\n    contact {\n      ...ActivityContactFragment\n    }\n\n    callActivity {\n      ...CallActivity\n    }\n\n    auditActivity {\n      ...AuditActivity\n    }\n\n    consentActivity {\n      ...ConsentActivity\n    }\n\n    emailActivity {\n      ...EmailActivity\n    }\n\n    exposeSharedActivity {\n      ...ExposeSharedActivity\n    }\n\n    taskActivity {\n      ...TaskActivity\n    }\n\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n    referralActivity {\n      ...ReferralActivity\n    }\n    documentActivity {\n      ...DocumentActivity\n    }\n    contactChangeLogs {\n      ...ContactChangeActivity\n    }\n    base_activity_legacy_go3_change_logs {\n      ...LegacyGo3ChangeActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DashboardActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    leadId\n    contactId\n    contact {\n      firstName\n      lastName\n    }\n    taskActivity {\n      ...TaskActivity\n    }\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n  }\n',
): (typeof documents)['\n  fragment DashboardActivity on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    leadId\n    contactId\n    contact {\n      firstName\n      lastName\n    }\n    taskActivity {\n      ...TaskActivity\n    }\n    appointmentActivity {\n      ...AppointmentActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ExpandedActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    shopId\n    contact {\n      id\n      firstName\n      lastName\n    }\n    leadId\n\n    callActivity {\n      id\n      call {\n        direction\n        agent {\n          firstName\n          lastName\n        }\n        status\n        duration\n        createdAt\n        note\n        outcome\n      }\n    }\n\n    emailActivity {\n      id\n      email {\n        direction\n        subject\n        propertyIds\n        createdAt\n        from\n        to\n        cc\n        body\n      }\n    }\n\n    taskActivity {\n      id\n      task {\n        id\n        agentId\n        title\n        type\n        propertyIds\n        dueDatetime\n        status\n        updatedAt\n        comment\n      }\n    }\n    appointmentActivity {\n      id\n      appointment {\n        id\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n        status\n        updatedAt\n        comment\n      }\n    }\n    documentActivity {\n      id\n      documentReference {\n        id\n        fileName\n        contentType\n        createdAt\n        sizeKb\n      }\n    }\n    exposeSharedActivity {\n      id\n      propertyIds\n      status\n      title\n      createdAt\n      comment\n    }\n  }\n',
): (typeof documents)['\n  fragment ExpandedActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n      firstName\n      lastName\n    }\n    contactId\n    shopId\n    contact {\n      id\n      firstName\n      lastName\n    }\n    leadId\n\n    callActivity {\n      id\n      call {\n        direction\n        agent {\n          firstName\n          lastName\n        }\n        status\n        duration\n        createdAt\n        note\n        outcome\n      }\n    }\n\n    emailActivity {\n      id\n      email {\n        direction\n        subject\n        propertyIds\n        createdAt\n        from\n        to\n        cc\n        body\n      }\n    }\n\n    taskActivity {\n      id\n      task {\n        id\n        agentId\n        title\n        type\n        propertyIds\n        dueDatetime\n        status\n        updatedAt\n        comment\n      }\n    }\n    appointmentActivity {\n      id\n      appointment {\n        id\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n        status\n        updatedAt\n        comment\n      }\n    }\n    documentActivity {\n      id\n      documentReference {\n        id\n        fileName\n        contentType\n        createdAt\n        sizeKb\n      }\n    }\n    exposeSharedActivity {\n      id\n      propertyIds\n      status\n      title\n      createdAt\n      comment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n    }\n    contactId\n    shopId\n\n    leadId\n\n    callActivity {\n      call {\n        createdAt\n        direction\n        status\n        duration\n      }\n    }\n\n    emailActivity {\n      email {\n        subject\n        propertyIds\n        createdAt\n        direction\n      }\n    }\n\n    taskActivity {\n      task {\n        agentId\n        title\n        type\n        dueDatetime\n        propertyIds\n      }\n    }\n    appointmentActivity {\n      appointment {\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n      }\n    }\n    documentActivity {\n      documentReference {\n        fileName\n        contentType\n        createdAt\n      }\n    }\n    exposeSharedActivity {\n      propertyIds\n      title\n      createdAt\n    }\n  }\n',
): (typeof documents)['\n  fragment ActivityListItem on DbBaseActivity {\n    ...BaseActivityInfo\n    id\n    category\n    creator {\n      id\n      userName\n    }\n    contactId\n    shopId\n\n    leadId\n\n    callActivity {\n      call {\n        createdAt\n        direction\n        status\n        duration\n      }\n    }\n\n    emailActivity {\n      email {\n        subject\n        propertyIds\n        createdAt\n        direction\n      }\n    }\n\n    taskActivity {\n      task {\n        agentId\n        title\n        type\n        dueDatetime\n        propertyIds\n      }\n    }\n    appointmentActivity {\n      appointment {\n        agentId\n        subject\n        type\n        propertyIds\n        startTime\n        endTime\n      }\n    }\n    documentActivity {\n      documentReference {\n        fileName\n        contentType\n        createdAt\n      }\n    }\n    exposeSharedActivity {\n      propertyIds\n      title\n      createdAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BatchJobExecution on DbBatchJobExecution {\n    startTime\n    status\n    jobExecutionId\n    jobInstanceId\n    endTime\n    exitCode\n    exitMessage\n    batchJobExecutionParams(where: { parameterName: { _in: ["shop_id, migration_id"] } }) {\n      parameterName\n      parameterValue\n      parameterType\n    }\n    batchStepExecutions(where: { stepName: { _nlike: "%PARTITION%" } }) {\n      exitCode\n      exitMessage\n      endTime\n      readCount\n      status\n      startTime\n      stepName\n      writeCount\n      stepExecutionId\n    }\n  }\n',
): (typeof documents)['\n  fragment BatchJobExecution on DbBatchJobExecution {\n    startTime\n    status\n    jobExecutionId\n    jobInstanceId\n    endTime\n    exitCode\n    exitMessage\n    batchJobExecutionParams(where: { parameterName: { _in: ["shop_id, migration_id"] } }) {\n      parameterName\n      parameterValue\n      parameterType\n    }\n    batchStepExecutions(where: { stepName: { _nlike: "%PARTITION%" } }) {\n      exitCode\n      exitMessage\n      endTime\n      readCount\n      status\n      startTime\n      stepName\n      writeCount\n      stepExecutionId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BuyerToRentSpecification on DbBuyerToRentSpecification {\n    area\n    bedroomsMax\n    bedroomsMin\n    city\n    country\n    currency\n    district\n    id\n    legacyAreaNames\n    legacyDistrictNames\n    livingAreaMax\n    livingAreaMin\n    monthlyNetRentMax\n    monthlyNetRentMin\n    monthlyTotalRentMax\n    monthlyTotalRentMin\n    plotSurfaceMax\n    plotSurfaceMin\n    priceMax\n    priceMin\n    roomsMax\n    roomsMin\n    subTypes\n    variations\n    totalSurfaceMax\n    totalSurfaceMin\n    type\n    placeIds\n    searchDemandId\n    buyerToRentSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n    customSearchDemandPolygons {\n      id\n      name\n      polygon\n    }\n  }\n',
): (typeof documents)['\n  fragment BuyerToRentSpecification on DbBuyerToRentSpecification {\n    area\n    bedroomsMax\n    bedroomsMin\n    city\n    country\n    currency\n    district\n    id\n    legacyAreaNames\n    legacyDistrictNames\n    livingAreaMax\n    livingAreaMin\n    monthlyNetRentMax\n    monthlyNetRentMin\n    monthlyTotalRentMax\n    monthlyTotalRentMin\n    plotSurfaceMax\n    plotSurfaceMin\n    priceMax\n    priceMin\n    roomsMax\n    roomsMin\n    subTypes\n    variations\n    totalSurfaceMax\n    totalSurfaceMin\n    type\n    placeIds\n    searchDemandId\n    buyerToRentSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n    customSearchDemandPolygons {\n      id\n      name\n      polygon\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CallInfo on DbCall {\n    id\n    callSid\n    createdAt\n    duration\n    outcome\n    note\n    status\n    shopId\n    callDirection {\n      name\n    }\n    agent {\n      ...Agent\n    }\n    recipient\n    caller\n  }\n',
): (typeof documents)['\n  fragment CallInfo on DbCall {\n    id\n    callSid\n    createdAt\n    duration\n    outcome\n    note\n    status\n    shopId\n    callDirection {\n      name\n    }\n    agent {\n      ...Agent\n    }\n    recipient\n    caller\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Call on DbCall {\n    call_activities {\n      baseActivity {\n        ...BaseActivityInfo\n        id\n        category\n        type\n        createdAt\n        createdBy\n        contactId\n        leadId\n        shopId\n        lead {\n          ...ActivityLeadFragment\n          ...CreatedAndReferralActivityLeadFragment\n        }\n        contact {\n          ...ActivityContactFragment\n        }\n      }\n    }\n    ...CallInfo\n  }\n',
): (typeof documents)['\n  fragment Call on DbCall {\n    call_activities {\n      baseActivity {\n        ...BaseActivityInfo\n        id\n        category\n        type\n        createdAt\n        createdBy\n        contactId\n        leadId\n        shopId\n        lead {\n          ...ActivityLeadFragment\n          ...CreatedAndReferralActivityLeadFragment\n        }\n        contact {\n          ...ActivityContactFragment\n        }\n      }\n    }\n    ...CallInfo\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CallActivity on DbCallActivity {\n    call {\n      ...CallInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment CallActivity on DbCallActivity {\n    call {\n      ...CallInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Consent on DbConsent {\n    id\n    licenseId\n    status\n    consentType\n    consentedOn\n    updatedAt\n    revokedAt\n    expiredAt\n    consentSource\n    contactForm\n    createdBy\n    creator {\n      firstName\n      lastName\n    }\n    document {\n      ...Document\n    }\n  }\n',
): (typeof documents)['\n  fragment Consent on DbConsent {\n    id\n    licenseId\n    status\n    consentType\n    consentedOn\n    updatedAt\n    revokedAt\n    expiredAt\n    consentSource\n    contactForm\n    createdBy\n    creator {\n      firstName\n      lastName\n    }\n    document {\n      ...Document\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ConsentActivity on DbConsentActivity {\n    id\n    consentId\n    consent {\n      createdAt\n      status\n      licenseId\n      expiredAt\n      customerAccountId\n      customerAccount {\n        emailAddress\n      }\n      contactForm\n      ipAddress\n      consentType\n      consentText\n      url\n    }\n    content\n    id\n  }\n',
): (typeof documents)['\n  fragment ConsentActivity on DbConsentActivity {\n    id\n    consentId\n    consent {\n      createdAt\n      status\n      licenseId\n      expiredAt\n      customerAccountId\n      customerAccount {\n        emailAddress\n      }\n      contactForm\n      ipAddress\n      consentType\n      consentText\n      url\n    }\n    content\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactInfo on DbContact {\n    id\n    go3Utag\n    firstName\n    lastName\n    preferredLanguage\n    blocked\n    phoneNumbers {\n      number\n      preferred\n    }\n    emailAddresses {\n      address\n      preferred\n    }\n  }\n',
): (typeof documents)['\n  fragment ContactInfo on DbContact {\n    id\n    go3Utag\n    firstName\n    lastName\n    preferredLanguage\n    blocked\n    phoneNumbers {\n      number\n      preferred\n    }\n    emailAddresses {\n      address\n      preferred\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Contact on DbContact {\n    ...ContactInfo\n    addresses {\n      ...ContactAddress\n    }\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n    legalEntity {\n      department\n      id\n      name\n    }\n    fullName\n    salutation\n    formattedSalutation\n    title\n    contactStatus\n    birthdate\n    placeOfBirth\n    nationality\n    idNumber\n    kycSearchId\n    personalNote\n    partnerFirstName\n    partnerLastName\n    deleted\n    createdAt\n    type\n    autoCc\n    customerAccountId\n    blocked\n    customerAccount {\n      emailAddress\n      consents {\n        ...Consent\n      }\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      preferred\n      type\n    }\n    preferredLanguage\n    preferredTimes {\n      preferredContactTime\n    }\n    contactRelationships {\n      relatedContactId\n      autoCc\n      type\n      relatedContact: related_contact {\n        ...ContactInfo\n      }\n    }\n\n    contactGdpr {\n      agreedToGdpr\n    }\n    contactRenterInformations {\n      contactId\n      householdType\n      professionType\n      petOwner\n      monthlyIncomeMin\n      monthlyIncomeMax\n      currency\n    }\n    tags {\n      tag {\n        id\n        name\n      }\n    }\n    preferredPhone: phoneNumbers(where: { preferred: { _eq: true } }) {\n      number\n      internationalNumber\n    }\n    preferredEmail: emailAddresses(where: { preferred: { _eq: true } }) {\n      address\n    }\n    leadhubGo3ContactLinks {\n      firstAgent {\n        ...AgentInfo\n      }\n      secondAgent {\n        ...AgentInfo\n      }\n      thirdAgent {\n        ...AgentInfo\n      }\n      firstIntentCategory\n      secondIntentCategory\n      thirdIntentCategory\n    }\n    emailLinks {\n      threadId\n    }\n    shopId\n    shop {\n      shopName\n    }\n    creator {\n      firstName\n      lastName\n    }\n    customerAccountId\n    blocked\n  }\n',
): (typeof documents)['\n  fragment Contact on DbContact {\n    ...ContactInfo\n    addresses {\n      ...ContactAddress\n    }\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n    legalEntity {\n      department\n      id\n      name\n    }\n    fullName\n    salutation\n    formattedSalutation\n    title\n    contactStatus\n    birthdate\n    placeOfBirth\n    nationality\n    idNumber\n    kycSearchId\n    personalNote\n    partnerFirstName\n    partnerLastName\n    deleted\n    createdAt\n    type\n    autoCc\n    customerAccountId\n    blocked\n    customerAccount {\n      emailAddress\n      consents {\n        ...Consent\n      }\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      preferred\n      type\n    }\n    preferredLanguage\n    preferredTimes {\n      preferredContactTime\n    }\n    contactRelationships {\n      relatedContactId\n      autoCc\n      type\n      relatedContact: related_contact {\n        ...ContactInfo\n      }\n    }\n\n    contactGdpr {\n      agreedToGdpr\n    }\n    contactRenterInformations {\n      contactId\n      householdType\n      professionType\n      petOwner\n      monthlyIncomeMin\n      monthlyIncomeMax\n      currency\n    }\n    tags {\n      tag {\n        id\n        name\n      }\n    }\n    preferredPhone: phoneNumbers(where: { preferred: { _eq: true } }) {\n      number\n      internationalNumber\n    }\n    preferredEmail: emailAddresses(where: { preferred: { _eq: true } }) {\n      address\n    }\n    leadhubGo3ContactLinks {\n      firstAgent {\n        ...AgentInfo\n      }\n      secondAgent {\n        ...AgentInfo\n      }\n      thirdAgent {\n        ...AgentInfo\n      }\n      firstIntentCategory\n      secondIntentCategory\n      thirdIntentCategory\n    }\n    emailLinks {\n      threadId\n    }\n    shopId\n    shop {\n      shopName\n    }\n    creator {\n      firstName\n      lastName\n    }\n    customerAccountId\n    blocked\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactAndLeads on DbContact {\n    ...Contact\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ContactAndLeads on DbContact {\n    ...Contact\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactAddress on DbContactAddress {\n    address {\n      ...Address\n    }\n    preferred\n    type\n  }\n',
): (typeof documents)['\n  fragment ContactAddress on DbContactAddress {\n    address {\n      ...Address\n    }\n    preferred\n    type\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactChangeActivity on DbContactChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n',
): (typeof documents)['\n  fragment ContactChangeActivity on DbContactChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactConnectedPropertiesListing on PropertyEngineListing {\n    address {\n      streetName\n      streetNumber\n    }\n    assignedAgentId\n    assignedTeamId\n    id\n    lastActivation\n    listingOwnerContactId\n    status\n    type\n    utag\n  }\n',
): (typeof documents)['\n  fragment ContactConnectedPropertiesListing on PropertyEngineListing {\n    address {\n      streetName\n      streetNumber\n    }\n    assignedAgentId\n    assignedTeamId\n    id\n    lastActivation\n    listingOwnerContactId\n    status\n    type\n    utag\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactEmailAddress on DbContactEmailAddress {\n    address\n    preferred\n    type\n  }\n',
): (typeof documents)['\n  fragment ContactEmailAddress on DbContactEmailAddress {\n    address\n    preferred\n    type\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactLastActivity on DbContact {\n    lastActivity: baseActivities(limit: 1, orderBy: { orderDate: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment ContactLastActivity on DbContact {\n    lastActivity: baseActivities(limit: 1, orderBy: { orderDate: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactListEntry on DbContact {\n    addresses {\n      ...ContactAddress\n    }\n\n    contactGdpr {\n      agreedToGdpr\n      blockedDueToGdpr\n    }\n\n    createdAt\n\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n\n    firstName\n    id\n    lastName\n    type\n    blocked\n\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n    mostRecentActivityCreatedAt\n\n    tags {\n      tagId\n    }\n\n    leads {\n      ...LeadForContactList\n    }\n    shopId\n    phoneNumbers {\n      number\n      preferred\n      internationalNumber\n    }\n  }\n',
): (typeof documents)['\n  fragment ContactListEntry on DbContact {\n    addresses {\n      ...ContactAddress\n    }\n\n    contactGdpr {\n      agreedToGdpr\n      blockedDueToGdpr\n    }\n\n    createdAt\n\n    emailAddresses {\n      address\n      preferred\n      type\n    }\n\n    firstName\n    id\n    lastName\n    type\n    blocked\n\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n    mostRecentActivityCreatedAt\n\n    tags {\n      tagId\n    }\n\n    leads {\n      ...LeadForContactList\n    }\n    shopId\n    phoneNumbers {\n      number\n      preferred\n      internationalNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ConversionData on DbConversionData {\n    referrer\n    landingPageUrlWithUtm\n    landingPageOwnerShopId\n    campaignOwnerShopId\n  }\n',
): (typeof documents)['\n  fragment ConversionData on DbConversionData {\n    referrer\n    landingPageUrlWithUtm\n    landingPageOwnerShopId\n    campaignOwnerShopId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CustomView on DbCustomListView {\n    id\n    name\n    shownByDefault\n    type\n    viewData\n    viewIndex\n  }\n',
): (typeof documents)['\n  fragment CustomView on DbCustomListView {\n    id\n    name\n    shownByDefault\n    type\n    viewData\n    viewIndex\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DocumentInfo on DbDocumentReference {\n    id\n    displayName\n  }\n',
): (typeof documents)['\n  fragment DocumentInfo on DbDocumentReference {\n    id\n    displayName\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Document on DbDocumentReference {\n    ...DocumentInfo\n    contactId\n    contentType\n    createdAt\n    createdBy\n    description\n    fileName\n    teamId\n    privacyLevel\n    referenceId\n    referenceType\n    sizeKb\n    description\n    createdByAgent {\n      ...Agent\n    }\n    lead {\n      ...LeadInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment Document on DbDocumentReference {\n    ...DocumentInfo\n    contactId\n    contentType\n    createdAt\n    createdBy\n    description\n    fileName\n    teamId\n    privacyLevel\n    referenceId\n    referenceType\n    sizeKb\n    description\n    createdByAgent {\n      ...Agent\n    }\n    lead {\n      ...LeadInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DocumentActivity on DbDocumentActivity {\n    id\n    documentReference {\n      ...Document\n    }\n  }\n',
): (typeof documents)['\n  fragment DocumentActivity on DbDocumentActivity {\n    id\n    documentReference {\n      ...Document\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DuplicateContact on DbPotentialDuplicateContact {\n    contact {\n      ...DuplicateContactDetails\n    }\n    createdAt\n    groupId\n    matchedEmail\n    matchedPhone\n  }\n',
): (typeof documents)['\n  fragment DuplicateContact on DbPotentialDuplicateContact {\n    contact {\n      ...DuplicateContactDetails\n    }\n    createdAt\n    groupId\n    matchedEmail\n    matchedPhone\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DuplicateContactDetails on DbContact {\n    contactStatus\n    createdAt\n    fullName\n    id\n    blocked\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n    }\n    emailAddresses {\n      address\n      normalizedAddress\n      preferred\n      type\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      normalizedLocalNumber\n      preferred\n      type\n    }\n    ...ContactLastActivity\n    lastInteractionActivity {\n      ...LastInteraction\n    }\n  }\n',
): (typeof documents)['\n  fragment DuplicateContactDetails on DbContact {\n    contactStatus\n    createdAt\n    fullName\n    id\n    blocked\n    leads {\n      ...LeadInfo\n      ...LeadLastActivity\n    }\n    emailAddresses {\n      address\n      normalizedAddress\n      preferred\n      type\n    }\n    phoneNumbers {\n      number\n      internationalNumber\n      normalizedLocalNumber\n      preferred\n      type\n    }\n    ...ContactLastActivity\n    lastInteractionActivity {\n      ...LastInteraction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BulkEmailFragment on DbBulkEmail {\n    id\n    from\n    to\n    cc\n    bcc\n    subject\n    body\n    status\n    createdAt\n    attachments {\n      document {\n        id\n        contentType\n        name\n      }\n    }\n    documentReferences {\n      id\n      fileName\n      sizeKb\n    }\n    status\n    trackings {\n      id\n      count\n      type\n      updatedAt\n      linkUrl\n      recipient\n      type\n    }\n    emailActivities {\n      baseActivity {\n        contactId\n      }\n    }\n    template {\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment BulkEmailFragment on DbBulkEmail {\n    id\n    from\n    to\n    cc\n    bcc\n    subject\n    body\n    status\n    createdAt\n    attachments {\n      document {\n        id\n        contentType\n        name\n      }\n    }\n    documentReferences {\n      id\n      fileName\n      sizeKb\n    }\n    status\n    trackings {\n      id\n      count\n      type\n      updatedAt\n      linkUrl\n      recipient\n      type\n    }\n    emailActivities {\n      baseActivity {\n        contactId\n      }\n    }\n    template {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmailActivity on DbEmailActivity {\n    email {\n      id\n      status\n      from\n      to\n      cc\n      bcc\n      subject\n      createdAt\n      direction\n      body\n      propertyIds\n      trackings {\n        id\n        count\n        type\n        recipient\n        updatedAt\n        linkUrl\n      }\n      attachments {\n        document {\n          id\n          contentType\n          name\n        }\n      }\n    }\n    id\n  }\n',
): (typeof documents)['\n  fragment EmailActivity on DbEmailActivity {\n    email {\n      id\n      status\n      from\n      to\n      cc\n      bcc\n      subject\n      createdAt\n      direction\n      body\n      propertyIds\n      trackings {\n        id\n        count\n        type\n        recipient\n        updatedAt\n        linkUrl\n      }\n      attachments {\n        document {\n          id\n          contentType\n          name\n        }\n      }\n    }\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NylasEmailIntegration on DbNylasEmailIntegration {\n    id\n    email\n    agentId\n    shopId\n    createdAt\n    updatedAt\n    scope\n    active\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment NylasEmailIntegration on DbNylasEmailIntegration {\n    id\n    email\n    agentId\n    shopId\n    createdAt\n    updatedAt\n    scope\n    active\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmailPlaceholder on DbEmailPlaceholder {\n    name\n    mapping\n    scope\n    type\n  }\n',
): (typeof documents)['\n  fragment EmailPlaceholder on DbEmailPlaceholder {\n    name\n    mapping\n    scope\n    type\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmailSignature on DbEmailSignature {\n    id\n    scope\n    name\n    signature\n    agentId\n    shopId\n    teamId\n    createdAt\n    updatedAt\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment EmailSignature on DbEmailSignature {\n    id\n    scope\n    name\n    signature\n    agentId\n    shopId\n    teamId\n    createdAt\n    updatedAt\n    isDefault: agentDefaultsAggregate(where: { agentId: { _eq: $agent_id }, shopId: { _eq: $shop_id } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmailTemplate on DbEmailTemplate {\n    id\n    scope\n    name\n    type\n    agentId\n    contents {\n      language\n      subject\n      body\n    }\n    shopId\n    teamId\n    createdAt\n    updatedAt\n  }\n',
): (typeof documents)['\n  fragment EmailTemplate on DbEmailTemplate {\n    id\n    scope\n    name\n    type\n    agentId\n    contents {\n      language\n      subject\n      body\n    }\n    shopId\n    teamId\n    createdAt\n    updatedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmailTracking on DbEmailTracking {\n    id\n    emailId\n    type\n    linkUrl\n    count\n    email {\n      messageId\n    }\n    updatedAt\n  }\n',
): (typeof documents)['\n  fragment EmailTracking on DbEmailTracking {\n    id\n    emailId\n    type\n    linkUrl\n    count\n    email {\n      messageId\n    }\n    updatedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ExposeSharedActivity on DbExposeeSharedActivity {\n    id\n    propertyIds\n    status\n    title\n    updatedAt\n    createdAt\n    comment\n  }\n',
): (typeof documents)['\n  fragment ExposeSharedActivity on DbExposeeSharedActivity {\n    id\n    propertyIds\n    status\n    title\n    updatedAt\n    createdAt\n    comment\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment KeycloakUserData on KeycloakUser {\n    firstName\n    lastName\n    email\n    ldapId\n  }\n',
): (typeof documents)['\n  fragment KeycloakUserData on KeycloakUser {\n    firstName\n    lastName\n    email\n    ldapId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LastInteraction on DbBaseActivity {\n    ...BaseActivityInfo\n    orderDate\n    category\n    taskActivity {\n      task {\n        type\n      }\n    }\n    appointmentActivity {\n      appointment {\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment LastInteraction on DbBaseActivity {\n    ...BaseActivityInfo\n    orderDate\n    category\n    taskActivity {\n      task {\n        type\n      }\n    }\n    appointmentActivity {\n      appointment {\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadInfo on DbLead {\n    id\n    contactId\n    teamId\n    intentType\n    createdAt\n    expiredAt\n    status\n    timeFrame\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        fullAddress\n      }\n      livingArea\n      priceRangeTo\n      priceRangeFrom\n      priceRangeUnit\n      rentPriceUnit\n      plotArea\n    }\n    buyerToRentSpecification {\n      searchDemandId\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n      priceMax\n      priceMin\n      currency\n      area\n      country\n    }\n    emailLinks {\n      threadId\n    }\n    agentId\n    shopId\n    personalNote\n    go3PropertyId\n    peLinkedPropertyId\n    leadBundlingByLeadWithPropertyId {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment LeadInfo on DbLead {\n    id\n    contactId\n    teamId\n    intentType\n    createdAt\n    expiredAt\n    status\n    timeFrame\n    sellerRentOutSpecification {\n      type\n      address {\n        city\n        postalCode\n        fullAddress\n      }\n      livingArea\n      priceRangeTo\n      priceRangeFrom\n      priceRangeUnit\n      rentPriceUnit\n      plotArea\n    }\n    buyerToRentSpecification {\n      searchDemandId\n      type\n      city\n      livingAreaMax\n      livingAreaMin\n      totalSurfaceMax\n      totalSurfaceMin\n      plotSurfaceMax\n      plotSurfaceMin\n      priceMax\n      priceMin\n      currency\n      area\n      country\n    }\n    emailLinks {\n      threadId\n    }\n    agentId\n    shopId\n    personalNote\n    go3PropertyId\n    peLinkedPropertyId\n    leadBundlingByLeadWithPropertyId {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Lead on DbLead {\n    ...LeadInfo\n    contactId\n    status\n    source\n    priority\n    customerMessage\n    statusReason\n    statusNote\n    utmParams {\n      ...UtmParam\n    }\n    conversionData {\n      ...ConversionData\n    }\n    activities: baseActivities {\n      ...BaseActivityInfo\n    }\n    leadExpiryByLead {\n      ...LeadExpiryByLead\n    }\n    contact {\n      go3ContactKey\n    }\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shopId\n    teamId\n    shop {\n      ...ShopInfo\n    }\n    referrals {\n      ...Referral\n    }\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    leadBundlingByLeadWithPropertyId {\n      leadWithSearchDemand {\n        id\n      }\n    }\n    leadBundlingsByLeadWithSearchDemandId {\n      id\n      leadWithProperty {\n        ...LeadInfo\n      }\n    }\n    ...LeadLastActivity\n  }\n',
): (typeof documents)['\n  fragment Lead on DbLead {\n    ...LeadInfo\n    contactId\n    status\n    source\n    priority\n    customerMessage\n    statusReason\n    statusNote\n    utmParams {\n      ...UtmParam\n    }\n    conversionData {\n      ...ConversionData\n    }\n    activities: baseActivities {\n      ...BaseActivityInfo\n    }\n    leadExpiryByLead {\n      ...LeadExpiryByLead\n    }\n    contact {\n      go3ContactKey\n    }\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shopId\n    teamId\n    shop {\n      ...ShopInfo\n    }\n    referrals {\n      ...Referral\n    }\n    sellerRentOutSpecification {\n      ...SellerRentOutSpecification\n    }\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n    }\n    leadBundlingByLeadWithPropertyId {\n      leadWithSearchDemand {\n        id\n      }\n    }\n    leadBundlingsByLeadWithSearchDemandId {\n      id\n      leadWithProperty {\n        ...LeadInfo\n      }\n    }\n    ...LeadLastActivity\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadListEntry on DbLead {\n    id\n    status\n    source\n    createdAt\n    priority\n    statusReason\n    statusNote\n    contactId\n    buyerToRentSpecification {\n      searchDemandId\n    }\n    contact {\n      id\n      firstName\n      lastName\n      contactStatus\n      blocked\n      phoneNumbers {\n        preferred\n        number\n        internationalNumber\n        type\n      }\n      emailAddresses {\n        address\n        preferred\n      }\n      preferredTimes {\n        preferredContactTime\n      }\n    }\n    ...LeadLastActivity\n    intentType\n    timeFrame\n    go3PropertyId\n    peLinkedPropertyId\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shop {\n      id\n      shopGroup {\n        name\n        shops {\n          id\n          status\n        }\n      }\n    }\n    shopId\n    teamId\n    emailLinks {\n      threadId\n    }\n  }\n',
): (typeof documents)['\n  fragment LeadListEntry on DbLead {\n    id\n    status\n    source\n    createdAt\n    priority\n    statusReason\n    statusNote\n    contactId\n    buyerToRentSpecification {\n      searchDemandId\n    }\n    contact {\n      id\n      firstName\n      lastName\n      contactStatus\n      blocked\n      phoneNumbers {\n        preferred\n        number\n        internationalNumber\n        type\n      }\n      emailAddresses {\n        address\n        preferred\n      }\n      preferredTimes {\n        preferredContactTime\n      }\n    }\n    ...LeadLastActivity\n    intentType\n    timeFrame\n    go3PropertyId\n    peLinkedPropertyId\n    agentId\n    agent {\n      ...AgentInfo\n    }\n    shop {\n      id\n      shopGroup {\n        name\n        shops {\n          id\n          status\n        }\n      }\n    }\n    shopId\n    teamId\n    emailLinks {\n      threadId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadForContactList on DbLead {\n    id\n    intentType\n    agentId\n    agent {\n      firstName\n      lastName\n    }\n  }\n',
): (typeof documents)['\n  fragment LeadForContactList on DbLead {\n    id\n    intentType\n    agentId\n    agent {\n      firstName\n      lastName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadForAddingProperty on DbLead {\n    id\n    intentType\n    contact {\n      go3ContactKey\n    }\n    sellerRentOutSpecification {\n      type\n      subType\n      address {\n        streetName\n        streetNumber\n        city\n        postalCode\n      }\n      livingArea\n      plotArea\n      surfaceTotal\n      numberOfRooms\n      numberOfBathrooms\n    }\n  }\n',
): (typeof documents)['\n  fragment LeadForAddingProperty on DbLead {\n    id\n    intentType\n    contact {\n      go3ContactKey\n    }\n    sellerRentOutSpecification {\n      type\n      subType\n      address {\n        streetName\n        streetNumber\n        city\n        postalCode\n      }\n      livingArea\n      plotArea\n      surfaceTotal\n      numberOfRooms\n      numberOfBathrooms\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadExpiryByLead on DbLeadExpiry {\n    expirationDate\n    id\n    status\n  }\n',
): (typeof documents)['\n  fragment LeadExpiryByLead on DbLeadExpiry {\n    expirationDate\n    id\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ImportLeadRequest on DbImportLeadRequest {\n    id\n    createdAt\n    payloadType\n    requestPayload\n    importLeadStatus\n    errorMessage\n    stackTrace\n    importedLeadId\n  }\n',
): (typeof documents)['\n  fragment ImportLeadRequest on DbImportLeadRequest {\n    id\n    createdAt\n    payloadType\n    requestPayload\n    importLeadStatus\n    errorMessage\n    stackTrace\n    importedLeadId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LeadLastActivity on DbLead {\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment LeadLastActivity on DbLead {\n    lastActivity: baseActivities(limit: 1, orderBy: { createdAt: DESC }) {\n      ...BaseActivityInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LegacyGo3ChangeActivity on DbLegacyGo3ChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n',
): (typeof documents)['\n  fragment LegacyGo3ChangeActivity on DbLegacyGo3ChangeLog {\n    id\n    oldValue\n    newValue\n    fieldName\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LicensePartner on DbLicensePartner {\n    name\n    id\n    isCompanyOwnedOperation\n    status\n    debtorNumber\n    primaryShopId\n    shops {\n      id\n      status\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      licensePartnerProfileImages(limit: 1, where: { type: { _eq: "HERO_TEASER" }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment LicensePartner on DbLicensePartner {\n    name\n    id\n    isCompanyOwnedOperation\n    status\n    debtorNumber\n    primaryShopId\n    shops {\n      id\n      status\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      licensePartnerProfileImages(limit: 1, where: { type: { _eq: "HERO_TEASER" }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Listing on PropertyEngineListing {\n    utag\n    type\n    status\n    cdfs\n    asset {\n      ... on PropertyEngineApartment {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineHouse {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineLand {\n        type\n        subType\n        variation\n        plotSurface\n      }\n    }\n    assignedAgentId\n    assignedTeamId\n\n    currency\n    district\n    area\n    fileAs\n\n    monthlyNetRent\n    monthlyTotalRent\n    monthlyUtilities\n    askingPrice\n    address {\n      city\n      postalCode\n      coverLetterAddress\n      streetName\n      streetNumber\n      countryCode\n      latitude\n      longitude\n    }\n    previewImage {\n      imageUrl\n    }\n\n    marketingDetails {\n      language\n      title\n      premiumExposeEmail {\n        subject\n        description\n        bulletPoints\n        bulletPointsTitle\n      }\n    }\n    internetProfile {\n      priceOnRequest\n      portals {\n        includeInExport\n      }\n    }\n\n    lastActivation\n    status\n    listingOwnerContactId\n  }\n',
): (typeof documents)['\n  fragment Listing on PropertyEngineListing {\n    utag\n    type\n    status\n    cdfs\n    asset {\n      ... on PropertyEngineApartment {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineHouse {\n        type\n        subType\n        variation\n        numberOfRooms\n        numberOfBedrooms\n        numberOfBathrooms\n        totalSurface\n        surfaceLivingArea\n      }\n      ... on PropertyEngineLand {\n        type\n        subType\n        variation\n        plotSurface\n      }\n    }\n    assignedAgentId\n    assignedTeamId\n\n    currency\n    district\n    area\n    fileAs\n\n    monthlyNetRent\n    monthlyTotalRent\n    monthlyUtilities\n    askingPrice\n    address {\n      city\n      postalCode\n      coverLetterAddress\n      streetName\n      streetNumber\n      countryCode\n      latitude\n      longitude\n    }\n    previewImage {\n      imageUrl\n    }\n\n    marketingDetails {\n      language\n      title\n      premiumExposeEmail {\n        subject\n        description\n        bulletPoints\n        bulletPointsTitle\n      }\n    }\n    internetProfile {\n      priceOnRequest\n      portals {\n        includeInExport\n      }\n    }\n\n    lastActivation\n    status\n    listingOwnerContactId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment MatchingStatus on PropertyEngineMatchingStatusDetail {\n    contactId\n    searchDemandId\n    matchingState\n    manuallyProposed\n    feedback\n    createdAt\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n      lead {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment MatchingStatus on PropertyEngineMatchingStatusDetail {\n    contactId\n    searchDemandId\n    matchingState\n    manuallyProposed\n    feedback\n    createdAt\n    buyerToRentSpecification {\n      ...BuyerToRentSpecification\n      lead {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProposedListing on PropertyEngineProposedListing {\n    matchingState\n    manuallyProposed\n    feedback\n    listing {\n      ...Listing\n    }\n    feedback\n    createdAt\n  }\n',
): (typeof documents)['\n  fragment ProposedListing on PropertyEngineProposedListing {\n    matchingState\n    manuallyProposed\n    feedback\n    listing {\n      ...Listing\n    }\n    feedback\n    createdAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Referral on DbReferral {\n    id\n    go3ReferralId\n    referralNotPossibleReason\n    referralAgentNote\n\n    receivingShop {\n      ...ShopInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment Referral on DbReferral {\n    id\n    go3ReferralId\n    referralNotPossibleReason\n    referralAgentNote\n\n    receivingShop {\n      ...ShopInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ReferralDetailsFragment on DbReferral {\n    id\n    createdAt\n    acceptedRefusedDate\n    referralStatus\n    commission\n    note\n    sendingContactId\n    leadId\n    receivingLeadId\n    receivingGo3ContactUtag\n    type\n    leadByReceivingLeadId {\n      contact {\n        id\n        blocked\n        emailAddresses {\n          address\n          preferred\n        }\n        phoneNumbers {\n          number\n          preferred\n        }\n      }\n      agent {\n        ...Agent\n      }\n      team {\n        ...Team\n      }\n    }\n    referralAgentNote\n    referralNotPossibleReason\n    agent {\n      ...Agent\n    }\n    agentByReceivingAgentId {\n      ...Agent\n    }\n    sendingShopId\n    receivingShopId\n    receivingTeamId\n    contactByReceivingLeadIdWrapper {\n      contact {\n        id\n      }\n    }\n    sendingTeamId\n    receivingShop {\n      ...ShopInfo\n    }\n    sendingShop {\n      ...ShopInfo\n    }\n    snapshotId\n    snapshotLead {\n      contactFirstName\n      contactLastName\n      intentType\n    }\n    contact {\n      id\n    }\n    teamByReceivingTeamId {\n      id\n      name\n      shopId\n    }\n    teamBySendingTeamId {\n      id\n      name\n      shopId\n    }\n\n    leadByReceivingLeadId {\n      baseActivities {\n        callActivity {\n          ...CallActivity\n        }\n        emailActivity {\n          ...EmailActivity\n        }\n        appointmentActivity {\n          ...AppointmentActivity\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ReferralDetailsFragment on DbReferral {\n    id\n    createdAt\n    acceptedRefusedDate\n    referralStatus\n    commission\n    note\n    sendingContactId\n    leadId\n    receivingLeadId\n    receivingGo3ContactUtag\n    type\n    leadByReceivingLeadId {\n      contact {\n        id\n        blocked\n        emailAddresses {\n          address\n          preferred\n        }\n        phoneNumbers {\n          number\n          preferred\n        }\n      }\n      agent {\n        ...Agent\n      }\n      team {\n        ...Team\n      }\n    }\n    referralAgentNote\n    referralNotPossibleReason\n    agent {\n      ...Agent\n    }\n    agentByReceivingAgentId {\n      ...Agent\n    }\n    sendingShopId\n    receivingShopId\n    receivingTeamId\n    contactByReceivingLeadIdWrapper {\n      contact {\n        id\n      }\n    }\n    sendingTeamId\n    receivingShop {\n      ...ShopInfo\n    }\n    sendingShop {\n      ...ShopInfo\n    }\n    snapshotId\n    snapshotLead {\n      contactFirstName\n      contactLastName\n      intentType\n    }\n    contact {\n      id\n    }\n    teamByReceivingTeamId {\n      id\n      name\n      shopId\n    }\n    teamBySendingTeamId {\n      id\n      name\n      shopId\n    }\n\n    leadByReceivingLeadId {\n      baseActivities {\n        callActivity {\n          ...CallActivity\n        }\n        emailActivity {\n          ...EmailActivity\n        }\n        appointmentActivity {\n          ...AppointmentActivity\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SellerRentOutSpecification on DbSellerRentOutSpecification {\n    constructionYear\n    condition\n    priceRangeTo\n    priceRangeFrom\n    rentRangeFrom\n    rentRangeTo\n    priceRangeUnit\n    rentPriceUnit\n    occupationStatus\n    livingArea\n    numberOfBathrooms\n    numberOfBedrooms\n    floor\n    numberOfMasterBathrooms\n    numberOfRooms\n    totalFloors\n    surfaceTotal\n    plotArea\n    surfaceUsable\n    balcony\n    garden\n    parking\n    swimmingPool\n    elevator\n    garage\n    guestToilet\n    type\n    subType\n    variation\n    id\n    owner {\n      id\n      firstName\n      lastName\n      fullName\n    }\n    ownershipStatus\n    ownerDescription\n    address {\n      ...Address\n    }\n    sellerRentOutSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SellerRentOutSpecification on DbSellerRentOutSpecification {\n    constructionYear\n    condition\n    priceRangeTo\n    priceRangeFrom\n    rentRangeFrom\n    rentRangeTo\n    priceRangeUnit\n    rentPriceUnit\n    occupationStatus\n    livingArea\n    numberOfBathrooms\n    numberOfBedrooms\n    floor\n    numberOfMasterBathrooms\n    numberOfRooms\n    totalFloors\n    surfaceTotal\n    plotArea\n    surfaceUsable\n    balcony\n    garden\n    parking\n    swimmingPool\n    elevator\n    garage\n    guestToilet\n    type\n    subType\n    variation\n    id\n    owner {\n      id\n      firstName\n      lastName\n      fullName\n    }\n    ownershipStatus\n    ownerDescription\n    address {\n      ...Address\n    }\n    sellerRentOutSpecificationCdfs {\n      cdf {\n        id\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopInfo on DbShop {\n    id\n    shopName\n    city\n    countryCode\n    postalCode\n    streetName\n    phone\n    email\n    status\n    streetNumber\n    onboardingLevel\n    groupId\n    shopGroup {\n      shops {\n        id\n      }\n    }\n    latitude\n    longitude\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      id\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1, where: { type: { _eq: SHOP }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ShopInfo on DbShop {\n    id\n    shopName\n    city\n    countryCode\n    postalCode\n    streetName\n    phone\n    email\n    status\n    streetNumber\n    onboardingLevel\n    groupId\n    shopGroup {\n      shops {\n        id\n      }\n    }\n    latitude\n    longitude\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      id\n    }\n    profiles(limit: 1, where: { published: { _isNull: true } }) {\n      images(limit: 1, where: { type: { _eq: SHOP }, index: { _eq: 0 } }) {\n        gcpFileName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Shop on DbShop {\n    ...ShopInfo\n    currency\n    website\n    energyAdvisoryEnabled\n    twilioConfigurations {\n      id\n      twilioNumber\n    }\n    shopGroup {\n      name\n      shops {\n        id\n        status\n      }\n    }\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      shops {\n        id\n      }\n      id\n    }\n    businessUnit\n    shopStatus {\n      description\n    }\n    teams {\n      ...Team\n    }\n  }\n',
): (typeof documents)['\n  fragment Shop on DbShop {\n    ...ShopInfo\n    currency\n    website\n    energyAdvisoryEnabled\n    twilioConfigurations {\n      id\n      twilioNumber\n    }\n    shopGroup {\n      name\n      shops {\n        id\n        status\n      }\n    }\n    licensePartner {\n      name\n      isCompanyOwnedOperation\n      shops {\n        id\n      }\n      id\n    }\n    businessUnit\n    shopStatus {\n      description\n    }\n    teams {\n      ...Team\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopWithMembers on DbShop {\n    ...Shop\n    teams {\n      ...TeamWithMembers\n    }\n    userShopAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n',
): (typeof documents)['\n  fragment ShopWithMembers on DbShop {\n    ...Shop\n    teams {\n      ...TeamWithMembers\n    }\n    userShopAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopNameByID on DbShop {\n    countryCode\n    shopName\n    id\n  }\n',
): (typeof documents)['\n  fragment ShopNameByID on DbShop {\n    countryCode\n    shopName\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopGroup on DbShopGroup {\n    id\n    name\n    shops {\n      ...ShopInfo\n    }\n    description\n  }\n',
): (typeof documents)['\n  fragment ShopGroup on DbShopGroup {\n    id\n    name\n    shops {\n      ...ShopInfo\n    }\n    description\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopProfile on DbShopProfile {\n    spokenLanguages {\n      spokenLanguage\n    }\n    customerMails {\n      footer\n      lang\n    }\n  }\n',
): (typeof documents)['\n  fragment ShopProfile on DbShopProfile {\n    spokenLanguages {\n      spokenLanguage\n    }\n    customerMails {\n      footer\n      lang\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ShopSettings on DbShopSetting {\n    applyAutomatedContactSettings\n    ccUsersInAssignmentEmails\n    consentManagementEnabled\n    defaultCity\n    defaultCountryCode\n    defaultPreferredLanguage\n    shopId\n    sendQualificationLink\n    leadAutoAssignmentEnabled\n    leadExpiryEnabled\n    leadExpiryDaysBuyer\n    leadExpiryDaysSeller\n    leadExpiryDaysToRent\n    leadExpiryDaysRentOut\n    leadExtendingInteractionsEnabled\n    lastInteractionRecalculatedAt\n    activeToRecalculateLastInteraction\n  }\n',
): (typeof documents)['\n  fragment ShopSettings on DbShopSetting {\n    applyAutomatedContactSettings\n    ccUsersInAssignmentEmails\n    consentManagementEnabled\n    defaultCity\n    defaultCountryCode\n    defaultPreferredLanguage\n    shopId\n    sendQualificationLink\n    leadAutoAssignmentEnabled\n    leadExpiryEnabled\n    leadExpiryDaysBuyer\n    leadExpiryDaysSeller\n    leadExpiryDaysToRent\n    leadExpiryDaysRentOut\n    leadExtendingInteractionsEnabled\n    lastInteractionRecalculatedAt\n    activeToRecalculateLastInteraction\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SnapshotLeadFragment on DbSnapshotLead {\n    id\n    contactFirstName\n    contactLastName\n    createdAt\n    intentType\n    priority\n    personalNote\n    propertyId\n    preferredEmailAddress {\n      address\n    }\n    preferredPhoneNumber {\n      number\n    }\n    snapshotBuyerToRentSpecification {\n      id\n      area\n      livingAreaMax\n      livingAreaMin\n      monthlyNetRentMax\n      monthlyNetRentMin\n      monthlyTotalRentMax\n      monthlyTotalRentMin\n      plotSurfaceMax\n      plotSurfaceMin\n      currency\n      priceMax\n      priceMin\n      roomsMin\n      roomsMax\n      type\n      subTypes\n      variations\n      placeIds\n      snapshotCustomSearchDemandPolygons {\n        id\n        name\n        polygon\n      }\n    }\n    snapshotSellerRentOutSpecification {\n      id\n      assetSubType {\n        name\n      }\n      assetType {\n        name\n      }\n      condition\n      floor\n      totalFloors\n      constructionYear\n      plotArea\n      livingArea\n      numberOfRooms\n      numberOfBathrooms\n      variation\n      priceRangeFrom\n      priceRangeTo\n      priceRangeUnit\n      rentPriceUnit\n      rentRangeFrom\n      rentRangeTo\n      city\n      country {\n        name\n      }\n      postalCode\n      countryCode\n      streetName\n      streetNumber\n      balcony\n      garden\n      parking\n      swimmingPool\n      elevator\n      garage\n      guestToilet\n    }\n  }\n',
): (typeof documents)['\n  fragment SnapshotLeadFragment on DbSnapshotLead {\n    id\n    contactFirstName\n    contactLastName\n    createdAt\n    intentType\n    priority\n    personalNote\n    propertyId\n    preferredEmailAddress {\n      address\n    }\n    preferredPhoneNumber {\n      number\n    }\n    snapshotBuyerToRentSpecification {\n      id\n      area\n      livingAreaMax\n      livingAreaMin\n      monthlyNetRentMax\n      monthlyNetRentMin\n      monthlyTotalRentMax\n      monthlyTotalRentMin\n      plotSurfaceMax\n      plotSurfaceMin\n      currency\n      priceMax\n      priceMin\n      roomsMin\n      roomsMax\n      type\n      subTypes\n      variations\n      placeIds\n      snapshotCustomSearchDemandPolygons {\n        id\n        name\n        polygon\n      }\n    }\n    snapshotSellerRentOutSpecification {\n      id\n      assetSubType {\n        name\n      }\n      assetType {\n        name\n      }\n      condition\n      floor\n      totalFloors\n      constructionYear\n      plotArea\n      livingArea\n      numberOfRooms\n      numberOfBathrooms\n      variation\n      priceRangeFrom\n      priceRangeTo\n      priceRangeUnit\n      rentPriceUnit\n      rentRangeFrom\n      rentRangeTo\n      city\n      country {\n        name\n      }\n      postalCode\n      countryCode\n      streetName\n      streetNumber\n      balcony\n      garden\n      parking\n      swimmingPool\n      elevator\n      garage\n      guestToilet\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Task on DbTask {\n    id\n    type\n    dueDatetime\n    title\n    status\n    agentId\n    teamId\n    comment\n    propertyIds\n    priority\n    go3TaskKey\n    taskContacts {\n      contactId\n    }\n    taskLeads {\n      leadId\n    }\n    taskAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Task on DbTask {\n    id\n    type\n    dueDatetime\n    title\n    status\n    agentId\n    teamId\n    comment\n    propertyIds\n    priority\n    go3TaskKey\n    taskContacts {\n      contactId\n    }\n    taskLeads {\n      leadId\n    }\n    taskAgents {\n      agent {\n        ...AgentInfo\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TaskActivity on DbTaskActivity {\n    task {\n      ...Task\n    }\n  }\n',
): (typeof documents)['\n  fragment TaskActivity on DbTaskActivity {\n    task {\n      ...Task\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TeamInfo on DbTeam {\n    id\n    name\n    shopId\n    email\n  }\n',
): (typeof documents)['\n  fragment TeamInfo on DbTeam {\n    id\n    name\n    shopId\n    email\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Team on DbTeam {\n    ...TeamInfo\n    normalizedName\n    description\n    status\n    teamStatus {\n      description\n    }\n  }\n',
): (typeof documents)['\n  fragment Team on DbTeam {\n    ...TeamInfo\n    normalizedName\n    description\n    status\n    teamStatus {\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TeamWithMembers on DbTeam {\n    ...Team\n    userTeamAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n',
): (typeof documents)['\n  fragment TeamWithMembers on DbTeam {\n    ...Team\n    userTeamAssignments {\n      agent {\n        ...Agent\n      }\n      role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TeamWithMembersAndShop on DbTeam {\n    ...TeamWithMembers\n    shop {\n      ...ShopInfo\n    }\n  }\n',
): (typeof documents)['\n  fragment TeamWithMembersAndShop on DbTeam {\n    ...TeamWithMembers\n    shop {\n      ...ShopInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UtmParam on DbUtmParam {\n    autaclid\n    msclkid\n    pmclid\n    campaign\n    content\n    gclid\n    medium\n    source\n    url\n    gbraid\n    wbraid\n  }\n',
): (typeof documents)['\n  fragment UtmParam on DbUtmParam {\n    autaclid\n    msclkid\n    pmclid\n    campaign\n    content\n    gclid\n    medium\n    source\n    url\n    gbraid\n    wbraid\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLastInteractionActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...LastInteraction\n    }\n  }\n',
): (typeof documents)['\n  query getLastInteractionActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...LastInteraction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...ActionActivity\n    }\n  }\n',
): (typeof documents)['\n  query getActivities($limit: Int, $orderBy: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, orderBy: $orderBy, where: $where) {\n      ...ActionActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getActivitiesList(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ActivityListItem\n    }\n  }\n',
): (typeof documents)['\n  query getActivitiesList(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ActivityListItem\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getExpandedActivity(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ExpandedActivityListItem\n    }\n  }\n',
): (typeof documents)['\n  query getExpandedActivity(\n    $limit: Int\n    $offset: Int\n    $orderBy: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...ExpandedActivityListItem\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query totalActivitiesCountQuery($where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivityAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalActivityCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query totalActivitiesCountQuery($where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivityAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalActivityCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query activityTypeGrouping {\n    dbActivityTypeGrouping {\n      ...ActivityTypeGrouping\n    }\n  }\n',
): (typeof documents)['\n  query activityTypeGrouping {\n    dbActivityTypeGrouping {\n      ...ActivityTypeGrouping\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentQuery(\n    $where: DbAgentBoolExp\n    $orderBy: [DbAgentOrderBy!]\n    $limit: Int\n    $offset: Int\n    $distinctOn: [DbAgentSelectColumn!]\n  ) {\n    dbAgent(where: $where, orderBy: $orderBy, distinctOn: $distinctOn, limit: $limit, offset: $offset) {\n      ...Agent\n    }\n  }\n',
): (typeof documents)['\n  query AgentQuery(\n    $where: DbAgentBoolExp\n    $orderBy: [DbAgentOrderBy!]\n    $limit: Int\n    $offset: Int\n    $distinctOn: [DbAgentSelectColumn!]\n  ) {\n    dbAgent(where: $where, orderBy: $orderBy, distinctOn: $distinctOn, limit: $limit, offset: $offset) {\n      ...Agent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentWithTeamAssignments($where: DbAgentBoolExp!) {\n    dbAgent(where: $where) {\n      ...AgentWithTeamAssignments\n    }\n  }\n',
): (typeof documents)['\n  query AgentWithTeamAssignments($where: DbAgentBoolExp!) {\n    dbAgent(where: $where) {\n      ...AgentWithTeamAssignments\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadsResponsibleAgents($contactId: String) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      agentsAssignedToLeadsOfContact {\n        intentType\n        agent {\n          ...AgentInfo\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query LeadsResponsibleAgents($contactId: String) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      agentsAssignedToLeadsOfContact {\n        intentType\n        agent {\n          ...AgentInfo\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentsWithAllMemberships($where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...AgentWithShopMembership\n    }\n    dbAgentAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AgentsWithAllMemberships($where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...AgentWithShopMembership\n    }\n    dbAgentAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BulkQuery($order_by: [DbBulkEmailOrderBy!], $where: DbBulkEmailBoolExp, $limit: Int, $offset: Int) {\n    dbBulkEmail(orderBy: $order_by, where: $where, limit: $limit, offset: $offset) {\n      ...BulkEmailFragment\n    }\n  }\n',
): (typeof documents)['\n  query BulkQuery($order_by: [DbBulkEmailOrderBy!], $where: DbBulkEmailBoolExp, $limit: Int, $offset: Int) {\n    dbBulkEmail(orderBy: $order_by, where: $where, limit: $limit, offset: $offset) {\n      ...BulkEmailFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalBulkEmailCountQuery($where: DbBulkEmailBoolExp) {\n    dbBulkEmailAggregate(where: $where) {\n      aggregate {\n        totalBulkEmails: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalBulkEmailCountQuery($where: DbBulkEmailBoolExp) {\n    dbBulkEmailAggregate(where: $where) {\n      aggregate {\n        totalBulkEmails: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query callListQuery($limit: Int, $offset: Int, $order_by: [DbCallOrderBy!], $where: DbCallBoolExp) {\n    dbCall(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Call\n    }\n  }\n',
): (typeof documents)['\n  query callListQuery($limit: Int, $offset: Int, $order_by: [DbCallOrderBy!], $where: DbCallBoolExp) {\n    dbCall(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Call\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CallQueue($shops: [String!]) {\n    dbCallAggregate(where: { status: { _eq: QUEUED }, shopId: { _in: $shops } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CallQueue($shops: [String!]) {\n    dbCallAggregate(where: { status: { _eq: QUEUED }, shopId: { _in: $shops } }) {\n      aggregate {\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Comment($contactId: String!) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      personalNote\n    }\n  }\n',
): (typeof documents)['\n  query Comment($contactId: String!) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      personalNote\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ConsentById($where: DbConsentBoolExp) {\n    dbConsent(where: $where) {\n      ...Consent\n    }\n  }\n',
): (typeof documents)['\n  query ConsentById($where: DbConsentBoolExp) {\n    dbConsent(where: $where) {\n      ...Consent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactAndLeads($id: String, $shopIds: [String!]) {\n    dbContact(where: { id: { _eq: $id }, shopId: { _in: $shopIds } }) {\n      ...ContactAndLeads\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n      ...ContactLastActivity\n    }\n  }\n',
): (typeof documents)['\n  query ContactAndLeads($id: String, $shopIds: [String!]) {\n    dbContact(where: { id: { _eq: $id }, shopId: { _in: $shopIds } }) {\n      ...ContactAndLeads\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n      ...ContactLastActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Contact($id: String) {\n    dbContact(where: { id: { _eq: $id } }) {\n      ...Contact\n    }\n  }\n',
): (typeof documents)['\n  query Contact($id: String) {\n    dbContact(where: { id: { _eq: $id } }) {\n      ...Contact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactsAndLeads($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactAndLeads\n    }\n  }\n',
): (typeof documents)['\n  query ContactsAndLeads($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactAndLeads\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Contacts($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Contact\n    }\n  }\n',
): (typeof documents)['\n  query Contacts($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Contact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactIds($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  query ContactIds($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactsList($order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(orderBy: $order_by, where: $where) {\n      ...ContactListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ContactsList($order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(orderBy: $order_by, where: $where) {\n      ...ContactListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactsListCount($where: DbContactBoolExp, $limit: Int) {\n    dbContactAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ContactsListCount($where: DbContactBoolExp, $limit: Int) {\n    dbContactAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query publicContactQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbContactDetailsOrderBy!]\n    $where: DbContactDetailsBoolExp\n  ) {\n    dbContactDetails(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      firstName\n      lastName\n      id\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n',
): (typeof documents)['\n  query publicContactQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbContactDetailsOrderBy!]\n    $where: DbContactDetailsBoolExp\n  ) {\n    dbContactDetails(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      firstName\n      lastName\n      id\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query contactInfosQuery($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactInfo\n    }\n  }\n',
): (typeof documents)['\n  query contactInfosQuery($limit: Int, $offset: Int, $order_by: [DbContactOrderBy!], $where: DbContactBoolExp) {\n    dbContact(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ContactInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query contactInfoWithRelationshipsQuery($contactId: String, $where: DbContactBoolExp) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      contactRelationships(where: { related_contact: $where }) {\n        type\n        relatedContact: related_contact {\n          ...ContactInfo\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query contactInfoWithRelationshipsQuery($contactId: String, $where: DbContactBoolExp) {\n    dbContact(where: { id: { _eq: $contactId } }) {\n      contactRelationships(where: { related_contact: $where }) {\n        type\n        relatedContact: related_contact {\n          ...ContactInfo\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactTags($contactId: String!) {\n    dbContactTag(where: { contactId: { _eq: $contactId } }) {\n      tag {\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ContactTags($contactId: String!) {\n    dbContactTag(where: { contactId: { _eq: $contactId } }) {\n      tag {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactAllTags($limit: Int, $offset: Int, $order_by: [DbContactTagOrderBy!], $where: DbContactTagBoolExp) {\n    dbContactTag(limit: $limit, offset: $offset, orderBy: $order_by, where: $where, distinctOn: tagId) {\n      tag {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ContactAllTags($limit: Int, $offset: Int, $order_by: [DbContactTagOrderBy!], $where: DbContactTagBoolExp) {\n    dbContactTag(limit: $limit, offset: $offset, orderBy: $order_by, where: $where, distinctOn: tagId) {\n      tag {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ContactsConnectedProperties($ownerContactId: String!, $shopId: PropertyEngineUUID!) {\n    propertyEngine {\n      searchListings(shopId: $shopId, where: { ownerContactId: $ownerContactId }) {\n        listings {\n          ...ContactConnectedPropertiesListing\n        }\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ContactsConnectedProperties($ownerContactId: String!, $shopId: PropertyEngineUUID!) {\n    propertyEngine {\n      searchListings(shopId: $shopId, where: { ownerContactId: $ownerContactId }) {\n        listings {\n          ...ContactConnectedPropertiesListing\n        }\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CustomDemandFields($where: DbCdfBoolExp) {\n    dbCdf(where: $where, orderBy: { createdAt: DESC }) {\n      id\n      label\n      shopId\n    }\n  }\n',
): (typeof documents)['\n  query CustomDemandFields($where: DbCdfBoolExp) {\n    dbCdf(where: $where, orderBy: { createdAt: DESC }) {\n      id\n      label\n      shopId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CustomViewsList($activeShopId: String, $type: EnumsCustomViewTypeEnum) {\n    dbCustomListView(orderBy: { viewIndex: ASC }, where: { type: { _eq: $type }, shopId: { _eq: $activeShopId } }) {\n      ...CustomView\n    }\n  }\n',
): (typeof documents)['\n  query CustomViewsList($activeShopId: String, $type: EnumsCustomViewTypeEnum) {\n    dbCustomListView(orderBy: { viewIndex: ASC }, where: { type: { _eq: $type }, shopId: { _eq: $activeShopId } }) {\n      ...CustomView\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentDashboardActivity($order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivity(where: $where, orderBy: $order_by, limit: $limit) {\n      ...DashboardActivity\n    }\n  }\n',
): (typeof documents)['\n  query AgentDashboardActivity($order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp, $limit: Int) {\n    dbBaseActivity(where: $where, orderBy: $order_by, limit: $limit) {\n      ...DashboardActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Documents(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Document\n    }\n  }\n',
): (typeof documents)['\n  query Documents(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Document\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DocumentsCount($where: DbDocumentReferenceBoolExp) {\n    dbDocumentReferenceAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DocumentsCount($where: DbDocumentReferenceBoolExp) {\n    dbDocumentReferenceAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DocumentsInfoQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...DocumentInfo\n    }\n  }\n',
): (typeof documents)['\n  query DocumentsInfoQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbDocumentReferenceOrderBy!]\n    $where: DbDocumentReferenceBoolExp\n  ) {\n    dbDocumentReference(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...DocumentInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DocumentContactIdsQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      emailActivity {\n        emailId\n      }\n      printedDocumentActivity {\n        printedDocumentId\n      }\n      appointmentActivity {\n        appointmentId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DocumentContactIdsQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbBaseActivityOrderBy!]\n    $where: DbBaseActivityBoolExp\n  ) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      emailActivity {\n        emailId\n      }\n      printedDocumentActivity {\n        printedDocumentId\n      }\n      appointmentActivity {\n        appointmentId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DuplicateContactGroupIds(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      groupId\n    }\n  }\n',
): (typeof documents)['\n  query DuplicateContactGroupIds(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      groupId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DuplicateContact(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      ...DuplicateContact\n      contact {\n        lastInteractionActivity {\n          ...LastInteraction\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DuplicateContact(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbPotentialDuplicateContactOrderBy!]\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContact(\n      limit: $limit\n      offset: $offset\n      orderBy: $order_by\n      where: $where\n      distinctOn: $distinctOn\n    ) {\n      ...DuplicateContact\n      contact {\n        lastInteractionActivity {\n          ...LastInteraction\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DuplicateContactCount(\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContactAggregate(where: $where, distinctOn: $distinctOn) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DuplicateContactCount(\n    $where: DbPotentialDuplicateContactBoolExp\n    $distinctOn: [DbPotentialDuplicateContactSelectColumn!]\n  ) {\n    dbPotentialDuplicateContactAggregate(where: $where, distinctOn: $distinctOn) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailLinksQuery($where: DbEmailLinkBoolExp, $contactRelationshipWhere: DbContactRelationshipBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n      contactId\n      leadId\n      contact {\n        ...ContactAndLeads\n      }\n    }\n  }\n',
): (typeof documents)['\n  query EmailLinksQuery($where: DbEmailLinkBoolExp, $contactRelationshipWhere: DbContactRelationshipBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n      contactId\n      leadId\n      contact {\n        ...ContactAndLeads\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailLinkThreadIdQuery($where: DbEmailLinkBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n    }\n  }\n',
): (typeof documents)['\n  query EmailLinkThreadIdQuery($where: DbEmailLinkBoolExp) {\n    dbEmailLink(where: $where) {\n      threadId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailPlaceholderQuery($where: DbEmailPlaceholderBoolExp) {\n    dbEmailPlaceholder(orderBy: { name: ASC }, where: $where) {\n      ...EmailPlaceholder\n    }\n  }\n',
): (typeof documents)['\n  query EmailPlaceholderQuery($where: DbEmailPlaceholderBoolExp) {\n    dbEmailPlaceholder(orderBy: { name: ASC }, where: $where) {\n      ...EmailPlaceholder\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailSignaturesQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbEmailSignatureOrderBy!]\n    $where: DbEmailSignatureBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbEmailSignature(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...EmailSignature\n    }\n  }\n',
): (typeof documents)['\n  query EmailSignaturesQuery(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbEmailSignatureOrderBy!]\n    $where: DbEmailSignatureBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbEmailSignature(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...EmailSignature\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailTemplatesQuery($order_by: [DbEmailTemplateOrderBy!], $where: DbEmailTemplateBoolExp) {\n    dbEmailTemplate(orderBy: $order_by, where: $where) {\n      ...EmailTemplate\n    }\n  }\n',
): (typeof documents)['\n  query EmailTemplatesQuery($order_by: [DbEmailTemplateOrderBy!], $where: DbEmailTemplateBoolExp) {\n    dbEmailTemplate(orderBy: $order_by, where: $where) {\n      ...EmailTemplate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailTrackingQuery($where: DbEmailTrackingBoolExp) {\n    dbEmailTracking(where: $where) {\n      ...EmailTracking\n    }\n  }\n',
): (typeof documents)['\n  query EmailTrackingQuery($where: DbEmailTrackingBoolExp) {\n    dbEmailTracking(where: $where) {\n      ...EmailTracking\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetEnableFeatures {\n    dbEnabledFeatureFlags {\n      enabledFeature\n    }\n  }\n',
): (typeof documents)['\n  query GetEnableFeatures {\n    dbEnabledFeatureFlags {\n      enabledFeature\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FailedLeadImports($limit: Int, $offset: Int) {\n    dbImportLeadRequest(\n      limit: $limit\n      offset: $offset\n      where: { importLeadStatus: { _eq: FAILED } }\n      orderBy: { createdAt: DESC }\n    ) {\n      ...ImportLeadRequest\n    }\n  }\n',
): (typeof documents)['\n  query FailedLeadImports($limit: Int, $offset: Int) {\n    dbImportLeadRequest(\n      limit: $limit\n      offset: $offset\n      where: { importLeadStatus: { _eq: FAILED } }\n      orderBy: { createdAt: DESC }\n    ) {\n      ...ImportLeadRequest\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FailedLeadImportsTotalCount {\n    dbImportLeadRequestAggregate(where: { importLeadStatus: { _eq: FAILED } }) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FailedLeadImportsTotalCount {\n    dbImportLeadRequestAggregate(where: { importLeadStatus: { _eq: FAILED } }) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindProposedListingsQuery(\n    $contactId: String!\n    $matchingState: PropertyEngineMatchingState\n    $searchDemandIds: [PropertyEngineUUID!]\n    $addressQuery: String\n    $listingType: PropertyEngineListingType\n    $matchingStatusOrder: PropertyEngineMatchingStatusOrder\n    $page: Int = 0\n    $perPage: Int = 100\n  ) {\n    propertyEngine {\n      findProposedListings(\n        contactId: $contactId\n        matchingState: $matchingState\n        searchDemandIds: $searchDemandIds\n        addressQuery: $addressQuery\n        listingType: $listingType\n        matchingStatusOrder: $matchingStatusOrder\n        page: $page\n        perPage: $perPage\n      ) {\n        total\n        proposedListings {\n          ...ProposedListing\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindProposedListingsQuery(\n    $contactId: String!\n    $matchingState: PropertyEngineMatchingState\n    $searchDemandIds: [PropertyEngineUUID!]\n    $addressQuery: String\n    $listingType: PropertyEngineListingType\n    $matchingStatusOrder: PropertyEngineMatchingStatusOrder\n    $page: Int = 0\n    $perPage: Int = 100\n  ) {\n    propertyEngine {\n      findProposedListings(\n        contactId: $contactId\n        matchingState: $matchingState\n        searchDemandIds: $searchDemandIds\n        addressQuery: $addressQuery\n        listingType: $listingType\n        matchingStatusOrder: $matchingStatusOrder\n        page: $page\n        perPage: $perPage\n      ) {\n        total\n        proposedListings {\n          ...ProposedListing\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindProposedListingsCountQuery($contactId: String!) {\n    propertyEngine {\n      findProposedListings(contactId: $contactId) {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindProposedListingsCountQuery($contactId: String!) {\n    propertyEngine {\n      findProposedListings(contactId: $contactId) {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FullLeadsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Lead\n    }\n  }\n',
): (typeof documents)['\n  query FullLeadsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Lead\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllEnums {\n    enumsBaseActivityCategory {\n      name\n    }\n    enumsActivityType {\n      name\n    }\n    enumsAddressType {\n      name\n    }\n    enumsContactTime {\n      name\n    }\n    enumsCountry {\n      name\n    }\n    enumsLanguage {\n      name\n    }\n    enumsLeadSource {\n      name\n    }\n    enumsLeadStatus {\n      name\n    }\n    enumsPhoneNumberType {\n      name\n    }\n    enumsEmailAddressType {\n      name\n    }\n    enumsSalutation {\n      name\n    }\n    enumsGender {\n      name\n    }\n    enumsAssetType {\n      name\n    }\n    enumsAssetSubType {\n      name\n    }\n    enumsPriority {\n      name\n    }\n    enumsIntentType {\n      name\n    }\n    enumsAssetCondition {\n      name\n    }\n    enumsReferralNotPossibleReason {\n      name\n    }\n    enumsZeroCommissionReason {\n      name\n    }\n    enumsEmailSignatureScope {\n      name\n    }\n    enumsEmailTemplateScope {\n      name\n    }\n    enumsUserRole {\n      name\n    }\n    enumsTimeFrame {\n      name\n    }\n    enumsLeadStatusReason {\n      name\n    }\n    enumsShopExpertise {\n      name\n    }\n    enumsIntentCategory {\n      name\n    }\n    enumsContactRelationType {\n      name\n    }\n    enumsCallStatus {\n      name\n    }\n    enumsCallOutcome {\n      name\n    }\n    enumsTaskAppointmentStatus {\n      name\n    }\n    enumsTaskType {\n      name\n    }\n    enumsAppointmentType {\n      name\n    }\n    enumsSharedType {\n      name\n    }\n    enumsPropertyOwnershipStatus {\n      name\n    }\n    enumsPropertyOccupationStatus {\n      name\n    }\n    enumsEntityType {\n      name\n    }\n    enumsPrivacyLevel {\n      name\n    }\n    enumsMandatoryShopProfileField {\n      name\n    }\n    enumsConsentSource {\n      name\n    }\n    enumsHouseholdType {\n      name\n    }\n    enumsProfessionType {\n      name\n    }\n    enumsSpokenLanguage {\n      name\n    }\n  }\n',
): (typeof documents)['\n  query GetAllEnums {\n    enumsBaseActivityCategory {\n      name\n    }\n    enumsActivityType {\n      name\n    }\n    enumsAddressType {\n      name\n    }\n    enumsContactTime {\n      name\n    }\n    enumsCountry {\n      name\n    }\n    enumsLanguage {\n      name\n    }\n    enumsLeadSource {\n      name\n    }\n    enumsLeadStatus {\n      name\n    }\n    enumsPhoneNumberType {\n      name\n    }\n    enumsEmailAddressType {\n      name\n    }\n    enumsSalutation {\n      name\n    }\n    enumsGender {\n      name\n    }\n    enumsAssetType {\n      name\n    }\n    enumsAssetSubType {\n      name\n    }\n    enumsPriority {\n      name\n    }\n    enumsIntentType {\n      name\n    }\n    enumsAssetCondition {\n      name\n    }\n    enumsReferralNotPossibleReason {\n      name\n    }\n    enumsZeroCommissionReason {\n      name\n    }\n    enumsEmailSignatureScope {\n      name\n    }\n    enumsEmailTemplateScope {\n      name\n    }\n    enumsUserRole {\n      name\n    }\n    enumsTimeFrame {\n      name\n    }\n    enumsLeadStatusReason {\n      name\n    }\n    enumsShopExpertise {\n      name\n    }\n    enumsIntentCategory {\n      name\n    }\n    enumsContactRelationType {\n      name\n    }\n    enumsCallStatus {\n      name\n    }\n    enumsCallOutcome {\n      name\n    }\n    enumsTaskAppointmentStatus {\n      name\n    }\n    enumsTaskType {\n      name\n    }\n    enumsAppointmentType {\n      name\n    }\n    enumsSharedType {\n      name\n    }\n    enumsPropertyOwnershipStatus {\n      name\n    }\n    enumsPropertyOccupationStatus {\n      name\n    }\n    enumsEntityType {\n      name\n    }\n    enumsPrivacyLevel {\n      name\n    }\n    enumsMandatoryShopProfileField {\n      name\n    }\n    enumsConsentSource {\n      name\n    }\n    enumsHouseholdType {\n      name\n    }\n    enumsProfessionType {\n      name\n    }\n    enumsSpokenLanguage {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GoogleIntegrations(\n    $order_by: [DbNylasEmailIntegrationOrderBy!]\n    $where: DbNylasEmailIntegrationBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbNylasEmailIntegration(orderBy: $order_by, where: $where) {\n      ...NylasEmailIntegration\n    }\n  }\n',
): (typeof documents)['\n  query GoogleIntegrations(\n    $order_by: [DbNylasEmailIntegrationOrderBy!]\n    $where: DbNylasEmailIntegrationBoolExp\n    $agent_id: String\n    $shop_id: String\n  ) {\n    dbNylasEmailIntegration(orderBy: $order_by, where: $where) {\n      ...NylasEmailIntegration\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...Lead\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n',
): (typeof documents)['\n  query LeadById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...Lead\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Go3PropertyIdByLeadId($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      go3PropertyId\n    }\n  }\n',
): (typeof documents)['\n  query Go3PropertyIdByLeadId($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      go3PropertyId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query leadAndContactById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n',
): (typeof documents)['\n  query leadAndContactById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n      contact {\n        ...ContactInfo\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadBaseInfoById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n    }\n  }\n',
): (typeof documents)['\n  query LeadBaseInfoById($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadInfoByIdForAddingProperty($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadForAddingProperty\n    }\n  }\n',
): (typeof documents)['\n  query LeadInfoByIdForAddingProperty($id: String) {\n    dbLead(where: { id: { _eq: $id } }) {\n      ...LeadForAddingProperty\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadsQuery($limit: Int, $offset: Int, $where: DbLeadBoolExp, $lastInteractionWhere: DbBaseActivityBoolExp) {\n    dbLead(limit: $limit, offset: $offset, where: $where) {\n      ...LeadInfo\n    }\n  }\n',
): (typeof documents)['\n  query LeadsQuery($limit: Int, $offset: Int, $where: DbLeadBoolExp, $lastInteractionWhere: DbBaseActivityBoolExp) {\n    dbLead(limit: $limit, offset: $offset, where: $where) {\n      ...LeadInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadsOfContactQuery($limit: Int, $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, where: $where) {\n      ...Lead\n    }\n  }\n',
): (typeof documents)['\n  query LeadsOfContactQuery($limit: Int, $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, where: $where) {\n      ...Lead\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n',
): (typeof documents)['\n  query LeadBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadListIdsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  query LeadListIdsQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadListQuery($order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(orderBy: $order_by, where: $where) {\n      ...LeadListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n',
): (typeof documents)['\n  query LeadListQuery($order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(orderBy: $order_by, where: $where) {\n      ...LeadListEntry\n      lastInteractionActivity {\n        ...LastInteraction\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadInfoListQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LeadInfo\n    }\n  }\n',
): (typeof documents)['\n  query LeadInfoListQuery($limit: Int, $offset: Int, $order_by: [DbLeadOrderBy!], $where: DbLeadBoolExp) {\n    dbLead(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LeadInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query leadSourceOptions($shopIds: [String!]) {\n    dbLead(limit: 250, distinctOn: source, where: { shopId: { _in: $shopIds } }) {\n      source\n    }\n  }\n',
): (typeof documents)['\n  query leadSourceOptions($shopIds: [String!]) {\n    dbLead(limit: 250, distinctOn: source, where: { shopId: { _in: $shopIds } }) {\n      source\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query leadStatistics($where: DbLeadStatisticsBoolExp) {\n    dbLeadStatistics(where: $where) {\n      count\n      originalLeadSource\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query leadStatistics($where: DbLeadStatisticsBoolExp) {\n    dbLeadStatistics(where: $where) {\n      count\n      originalLeadSource\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query licensePartners(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbLicensePartnerOrderBy!]\n    $where: DbLicensePartnerBoolExp\n  ) {\n    dbLicensePartner(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LicensePartner\n    }\n  }\n',
): (typeof documents)['\n  query licensePartners(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbLicensePartnerOrderBy!]\n    $where: DbLicensePartnerBoolExp\n  ) {\n    dbLicensePartner(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...LicensePartner\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPropertyDetailQuery($utag: PropertyEngineListingUtag!) {\n    propertyEngine {\n      getListingByUtag(utag: $utag) {\n        ...Listing\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPropertyDetailQuery($utag: PropertyEngineListingUtag!) {\n    propertyEngine {\n      getListingByUtag(utag: $utag) {\n        ...Listing\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ListingsQuery(\n    $page: Int\n    $perPage: Int\n    $where: PropertyEngineSearchListingsFilterInput!\n    $shopId: PropertyEngineUUID!\n  ) {\n    propertyEngine {\n      searchListings(page: $page, perPage: $perPage, where: $where, shopId: $shopId) {\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ListingsQuery(\n    $page: Int\n    $perPage: Int\n    $where: PropertyEngineSearchListingsFilterInput!\n    $shopId: PropertyEngineUUID!\n  ) {\n    propertyEngine {\n      searchListings(page: $page, perPage: $perPage, where: $where, shopId: $shopId) {\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MatchingCountQuery($searchDemandId: PropertyEngineUUID!) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(id: $searchDemandId) {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MatchingCountQuery($searchDemandId: PropertyEngineUUID!) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(id: $searchDemandId) {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MatchingsQuery(\n    $searchDemandId: PropertyEngineUUID!\n    $page: Int\n    $perPage: Int\n    $order: PropertyEngineListingOrder\n    $addressQuery: String\n    $listingStatus: [PropertyEngineListingStatus!]\n  ) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(\n        id: $searchDemandId\n        page: $page\n        perPage: $perPage\n        order: $order\n        addressQuery: $addressQuery\n        listingStatus: $listingStatus\n      ) {\n        total\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MatchingsQuery(\n    $searchDemandId: PropertyEngineUUID!\n    $page: Int\n    $perPage: Int\n    $order: PropertyEngineListingOrder\n    $addressQuery: String\n    $listingStatus: [PropertyEngineListingStatus!]\n  ) {\n    propertyEngine {\n      findMatchingListingsForSearchDemand(\n        id: $searchDemandId\n        page: $page\n        perPage: $perPage\n        order: $order\n        addressQuery: $addressQuery\n        listingStatus: $listingStatus\n      ) {\n        total\n        listings {\n          ...Listing\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpsertMatchingStatus($input: [PropertyEngineMatchingStatusInput!]!) {\n    propertyEngine {\n      upsertMatchingStatus(input: $input) {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpsertMatchingStatus($input: [PropertyEngineMatchingStatusInput!]!) {\n    propertyEngine {\n      upsertMatchingStatus(input: $input) {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query findMatchingStatus($listingUtag: PropertyEngineListingUtag!, $contactId: String!) {\n    propertyEngine {\n      findMatchingStatus(listingUtag: $listingUtag, contactId: $contactId) {\n        ...MatchingStatus\n      }\n    }\n  }\n',
): (typeof documents)['\n  query findMatchingStatus($listingUtag: PropertyEngineListingUtag!, $contactId: String!) {\n    propertyEngine {\n      findMatchingStatus(listingUtag: $listingUtag, contactId: $contactId) {\n        ...MatchingStatus\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query batchJobExecution($limit: Int, $offset: Int) {\n    dbBatchJobExecution(limit: $limit, offset: $offset, orderBy: { jobExecutionId: DESC }) {\n      ...BatchJobExecution\n    }\n  }\n',
): (typeof documents)['\n  query batchJobExecution($limit: Int, $offset: Int) {\n    dbBatchJobExecution(limit: $limit, offset: $offset, orderBy: { jobExecutionId: DESC }) {\n      ...BatchJobExecution\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NewsQuery($limit: Int, $offset: Int, $order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      createdAt\n      type\n      auditActivity {\n        content\n      }\n      contact {\n        firstName\n        lastName\n        id\n      }\n      lead {\n        ...LeadInfo\n        status\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NewsQuery($limit: Int, $offset: Int, $order_by: [DbBaseActivityOrderBy!], $where: DbBaseActivityBoolExp) {\n    dbBaseActivity(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      createdAt\n      type\n      auditActivity {\n        content\n      }\n      contact {\n        firstName\n        lastName\n        id\n      }\n      lead {\n        ...LeadInfo\n        status\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CountryMandatoryLanguage(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbCountryMandatoryLanguageOrderBy!]\n    $where: DbCountryMandatoryLanguageBoolExp\n  ) {\n    dbCountryMandatoryLanguage(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      countryCode\n      lang\n    }\n  }\n',
): (typeof documents)['\n  query CountryMandatoryLanguage(\n    $limit: Int\n    $offset: Int\n    $order_by: [DbCountryMandatoryLanguageOrderBy!]\n    $where: DbCountryMandatoryLanguageBoolExp\n  ) {\n    dbCountryMandatoryLanguage(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      id\n      countryCode\n      lang\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MyQuery(\n    $dimensions: [PropertyEngineDashboardFeedListingsDimension!]!\n    $shopId: PropertyEngineUUID!\n    $dateFrom: PropertyEngineDateTime!\n    $perPage: Int\n  ) {\n    propertyEngine {\n      dashboardFeedListings(dimensions: $dimensions, shopId: $shopId, dateTimeFrom: $dateFrom, perPage: $perPage) {\n        total\n        results {\n          listing {\n            id\n            type\n            status\n            utag\n            fileAs\n            lastActivation\n            lastAssignedAgentChange\n            lastPriceReduction\n            previewImage {\n              imageUrl\n            }\n            pictures {\n              imageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MyQuery(\n    $dimensions: [PropertyEngineDashboardFeedListingsDimension!]!\n    $shopId: PropertyEngineUUID!\n    $dateFrom: PropertyEngineDateTime!\n    $perPage: Int\n  ) {\n    propertyEngine {\n      dashboardFeedListings(dimensions: $dimensions, shopId: $shopId, dateTimeFrom: $dateFrom, perPage: $perPage) {\n        total\n        results {\n          listing {\n            id\n            type\n            status\n            utag\n            fileAs\n            lastActivation\n            lastAssignedAgentChange\n            lastPriceReduction\n            previewImage {\n              imageUrl\n            }\n            pictures {\n              imageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralQuery($limit: Int, $offset: Int, $order_by: [DbReferralOrderBy!], $where: DbReferralBoolExp) {\n    dbReferral(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ReferralDetailsFragment\n    }\n  }\n',
): (typeof documents)['\n  query ReferralQuery($limit: Int, $offset: Int, $order_by: [DbReferralOrderBy!], $where: DbReferralBoolExp) {\n    dbReferral(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ReferralDetailsFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalReferralCount($where: DbReferralBoolExp) {\n    dbReferralAggregate(where: $where) {\n      aggregate {\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalReferralCount($where: DbReferralBoolExp) {\n    dbReferralAggregate(where: $where) {\n      aggregate {\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralSendingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingShopId) {\n      sendingShop {\n        id\n        shopName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralSendingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingShopId) {\n      sendingShop {\n        id\n        shopName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralSendingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: agentId) {\n      agent {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralSendingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: agentId) {\n      agent {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralReceivingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingShopId) {\n      receivingShop {\n        id\n        shopName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralReceivingShopsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingShopId) {\n      receivingShop {\n        id\n        shopName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralReceivingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingTeamId) {\n      teamByReceivingTeamId {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralReceivingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingTeamId) {\n      teamByReceivingTeamId {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralSendingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingTeamId) {\n      teamBySendingTeamId {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralSendingTeamsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: sendingTeamId) {\n      teamBySendingTeamId {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralReceivingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingAgentId) {\n      agentByReceivingAgentId {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralReceivingAgentsQuery($where: DbReferralBoolExp) {\n    dbReferral(where: $where, distinctOn: receivingAgentId) {\n      agentByReceivingAgentId {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReferralWithSnapshotLeadQuery($referralId: String) {\n    dbReferral(where: { id: { _eq: $referralId } }) {\n      ...ReferralDetailsFragment\n      snapshotLead {\n        ...SnapshotLeadFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReferralWithSnapshotLeadQuery($referralId: String) {\n    dbReferral(where: { id: { _eq: $referralId } }) {\n      ...ReferralDetailsFragment\n      snapshotLead {\n        ...SnapshotLeadFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  # Returns the list of all shops that the leads are assigned to that are assigned to $shopId\n  query ReferredShops($shopId: String!) {\n    dbReferral(where: { sendingShopId: { _eq: $shopId } }, distinctOn: receivingShopId) {\n      receivingShop {\n        ...ShopInfo\n      }\n    }\n  }\n',
): (typeof documents)['\n  # Returns the list of all shops that the leads are assigned to that are assigned to $shopId\n  query ReferredShops($shopId: String!) {\n    dbReferral(where: { sendingShopId: { _eq: $shopId } }, distinctOn: receivingShopId) {\n      receivingShop {\n        ...ShopInfo\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Lead($where: DbLeadBoolExp) {\n    dbLead(where: $where) {\n      ...Lead\n    }\n  }\n',
): (typeof documents)['\n  query Lead($where: DbLeadBoolExp) {\n    dbLead(where: $where) {\n      ...Lead\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchKeycloakUsers($search: String!, $limit: Int!) {\n    keycloakUser(search: $search, limit: $limit) {\n      ...KeycloakUserData\n    }\n  }\n',
): (typeof documents)['\n  query SearchKeycloakUsers($search: String!, $limit: Int!) {\n    keycloakUser(search: $search, limit: $limit) {\n      ...KeycloakUserData\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query shopBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n',
): (typeof documents)['\n  query shopBaseActivity($where: DbBaseActivityBoolExp, $orderBy: [DbBaseActivityOrderBy!], $limit: Int, $offset: Int) {\n    dbBaseActivity(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {\n      ...BaseActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopGroups($limit: Int, $offset: Int, $order_by: [DbShopGroupOrderBy!], $where: DbShopGroupBoolExp) {\n    dbShopGroup(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopGroup\n    }\n  }\n',
): (typeof documents)['\n  query ShopGroups($limit: Int, $offset: Int, $order_by: [DbShopGroupOrderBy!], $where: DbShopGroupBoolExp) {\n    dbShopGroup(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopGroup\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalShopGroupCount {\n    dbShopGroupAggregate {\n      aggregate {\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalShopGroupCount {\n    dbShopGroupAggregate {\n      aggregate {\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentsInShop($shopId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shopId: { _eq: $shopId } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n      primaryTeams(where: { shopId: { _eq: $shopId } }) {\n        shopId\n        teamId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AgentsInShop($shopId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shopId: { _eq: $shopId } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n      primaryTeams(where: { shopId: { _eq: $shopId } }) {\n        shopId\n        teamId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalAgentsInShopCount($shopId: String, $where: DbAgentBoolExp!) {\n    dbAgentAggregate(\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalAgentsInShopCount($shopId: String, $where: DbAgentBoolExp!) {\n    dbAgentAggregate(\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shopId: { _eq: $shopId } } }\n              { userTeamAssignments: { team: { shopId: { _eq: $shopId } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AgentsInLipa($lipaId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shop: { licensePartnerId: { _eq: $lipaId } } } }\n              { userTeamAssignments: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shop: { licensePartnerId: { _eq: $lipaId } } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AgentsInLipa($lipaId: String, $where: DbAgentBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbAgentOrderBy!]) {\n    dbAgent(\n      limit: $limit\n      offset: $offset\n      orderBy: $orderBy\n      where: {\n        _and: [\n          $where\n          {\n            _or: [\n              { userShopAssignments: { shop: { licensePartnerId: { _eq: $lipaId } } } }\n              { userTeamAssignments: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } } }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...Agent\n      userTeamAssignments(where: { team: { shop: { licensePartnerId: { _eq: $lipaId } } } }) {\n        team {\n          ...Team\n          shop {\n            ...ShopInfo\n          }\n        }\n        role\n      }\n      userShopAssignments(where: { shop: { licensePartnerId: { _eq: $lipaId } } }) {\n        shop {\n          ...ShopInfo\n        }\n        role\n      }\n      userAdminAssignments {\n        role\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopProfile($shopId: String, $where: DbShopProfileBoolExp!) {\n    dbShopProfile(where: { _and: [$where, { shopId: { _eq: $shopId }, published: { _isNull: true } }] }) {\n      ...ShopProfile\n    }\n  }\n',
): (typeof documents)['\n  query ShopProfile($shopId: String, $where: DbShopProfileBoolExp!) {\n    dbShopProfile(where: { _and: [$where, { shopId: { _eq: $shopId }, published: { _isNull: true } }] }) {\n      ...ShopProfile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      ...ShopSettings\n    }\n  }\n',
): (typeof documents)['\n  query ShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      ...ShopSettings\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutoExposeShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        qualiformMandatoryFields {\n          fieldName\n        }\n        qualiformLeadIntents {\n          intentType\n        }\n        qualiformLeadSources {\n          leadSource\n        }\n        qualiformShopSettings {\n          secondaryLanguageEnglishEnabled\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AutoExposeShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        qualiformMandatoryFields {\n          fieldName\n        }\n        qualiformLeadIntents {\n          intentType\n        }\n        qualiformLeadSources {\n          leadSource\n        }\n        qualiformShopSettings {\n          secondaryLanguageEnglishEnabled\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutoLeadAssignmentShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        leadAutoAssignmentIntents {\n          intentType\n        }\n        leadAutoAssignmentRules {\n          leadAssignmentRule\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AutoLeadAssignmentShopSettings($where: DbShopSettingBoolExp) {\n    dbShopSetting(where: $where) {\n      shop {\n        leadAutoAssignmentIntents {\n          intentType\n        }\n        leadAutoAssignmentRules {\n          leadAssignmentRule\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ConsentManagementShopSettings($shopId: String!) {\n    dbShopManagedConsent(where: { shopId: { _eq: $shopId } }) {\n      consentType\n    }\n  }\n',
): (typeof documents)['\n  query ConsentManagementShopSettings($shopId: String!) {\n    dbShopManagedConsent(where: { shopId: { _eq: $shopId } }) {\n      consentType\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LastInteractionSettings($shopId: String!) {\n    dbShopSetting(where: { shopId: { _eq: $shopId } }) {\n      leadExtendingInteractionsEnabled\n    }\n  }\n',
): (typeof documents)['\n  query LastInteractionSettings($shopId: String!) {\n    dbShopSetting(where: { shopId: { _eq: $shopId } }) {\n      leadExtendingInteractionsEnabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopLastInteractionActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n',
): (typeof documents)['\n  query ShopLastInteractionActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopTags($shopIds: [String!]!) {\n    dbTag(where: { shopId: { _in: $shopIds }, deleted: { _eq: false } }) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query ShopTags($shopIds: [String!]!) {\n    dbTag(where: { shopId: { _in: $shopIds }, deleted: { _eq: false } }) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Shops($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Shop\n    }\n  }\n',
): (typeof documents)['\n  query Shops($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...Shop\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopInfo($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopInfo\n    }\n  }\n',
): (typeof documents)['\n  query ShopInfo($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopsWithMembers($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopWithMembers\n    }\n  }\n',
): (typeof documents)['\n  query ShopsWithMembers($limit: Int, $offset: Int, $order_by: [DbShopOrderBy!], $where: DbShopBoolExp) {\n    dbShop(limit: $limit, offset: $offset, orderBy: $order_by, where: $where) {\n      ...ShopWithMembers\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalShopCount($where: DbShopBoolExp) {\n    dbShopAggregate(where: $where) {\n      aggregate {\n        totalShopCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalShopCount($where: DbShopBoolExp) {\n    dbShopAggregate(where: $where) {\n      aggregate {\n        totalShopCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query shopNameByIDs($shopIds: [String!]!) {\n    dbShop(where: { id: { _in: $shopIds } }) {\n      ...ShopNameByID\n    }\n  }\n',
): (typeof documents)['\n  query shopNameByIDs($shopIds: [String!]!) {\n    dbShop(where: { id: { _in: $shopIds } }) {\n      ...ShopNameByID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LeadExtendingActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n',
): (typeof documents)['\n  query LeadExtendingActivities($shopId: String!) {\n    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {\n      activityType\n    }\n    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {\n      taskType\n    }\n    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {\n      appointmentType\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query systemUserIdsQuery($systemUsers: [String!]) {\n    dbAgent(where: { userName: { _in: $systemUsers } }) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  query systemUserIdsQuery($systemUsers: [String!]) {\n    dbAgent(where: { userName: { _in: $systemUsers } }) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Tags($where: DbTagBoolExp) {\n    dbTag(where: $where) {\n      id\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  query Tags($where: DbTagBoolExp) {\n    dbTag(where: $where) {\n      id\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query teamsWithMembers($where: DbTeamBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...TeamWithMembersAndShop\n    }\n    dbTeamAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query teamsWithMembers($where: DbTeamBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {\n      ...TeamWithMembersAndShop\n    }\n    dbTeamAggregate(where: $where) {\n      aggregate {\n        totalCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query teamInfo($where: DbTeamBoolExp!, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(where: $where, orderBy: $orderBy) {\n      ...TeamInfo\n    }\n  }\n',
): (typeof documents)['\n  query teamInfo($where: DbTeamBoolExp!, $orderBy: [DbTeamOrderBy!]) {\n    dbTeam(where: $where, orderBy: $orderBy) {\n      ...TeamInfo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TeamsAssignedToAgentInShop($where: DbAgentBoolExp!, $shopId: String!) {\n    dbAgent(where: $where) {\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          name\n          id\n        }\n      }\n    }\n    dbTeam(where: { shopId: { _eq: $shopId } }) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query TeamsAssignedToAgentInShop($where: DbAgentBoolExp!, $shopId: String!) {\n    dbAgent(where: $where) {\n      userTeamAssignments(where: { team: { shopId: { _eq: $shopId } } }) {\n        team {\n          name\n          id\n        }\n      }\n    }\n    dbTeam(where: { shopId: { _eq: $shopId } }) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalCallCountQuery($where: DbCallBoolExp) {\n    dbCallAggregate(where: $where) {\n      aggregate {\n        totalCallCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalCallCountQuery($where: DbCallBoolExp) {\n    dbCallAggregate(where: $where) {\n      aggregate {\n        totalCallCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalEmailSignaturesQuery($where: DbEmailSignatureBoolExp) {\n    dbEmailSignatureAggregate(where: $where) {\n      aggregate {\n        totalEmailSignatures: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalEmailSignaturesQuery($where: DbEmailSignatureBoolExp) {\n    dbEmailSignatureAggregate(where: $where) {\n      aggregate {\n        totalEmailSignatures: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TotalLeadCountQuery($where: DbLeadBoolExp, $limit: Int) {\n    dbLeadAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalLeadCount: count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TotalLeadCountQuery($where: DbLeadBoolExp, $limit: Int) {\n    dbLeadAggregate(where: $where, limit: $limit) {\n      aggregate {\n        totalLeadCount: count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VerifyNumberQuery($shopId: String!) {\n    dbTwilioConfiguration(where: { shopId: { _eq: $shopId } }) {\n      displayNumber\n      twilioNumber\n    }\n  }\n',
): (typeof documents)['\n  query VerifyNumberQuery($shopId: String!) {\n    dbTwilioConfiguration(where: { shopId: { _eq: $shopId } }) {\n      displayNumber\n      twilioNumber\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never;
