import { SendBulkEmailCcRecipient, SendBulkEmailData, SendBulkEmailRecipient } from '@ev/eva-container-api';
import { useMutation } from '@tanstack/react-query';
import { Agent, LanguageEnum } from 'api/graphql/generated/graphql';
import { ResolveBulkEmailTemplateResponse } from 'api/rest/models/EmailDataTypes';
import {
  getContactId,
  mapLeadInfosToLeadAndContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import cloneDeep from 'lodash.clonedeep';
import {
  loadAgents,
  loadContactsAndLeads,
  loadLeads,
  loadListingsFromPe,
  loadResolvedBulkTemplates,
  loadResolvedSignature,
  loadTemplate,
} from 'util/defaultValues/defaultValueLoaders';
import {
  emailBodyWithSignature,
  getAutoCcRecipients,
  getTemplateContent,
  highlightUnresolvedPlaceholders,
} from 'util/email';
import { isSet } from 'util/filters';
import { ComposeBulkEmailFormData, PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function isContact(recipient: SendBulkEmailRecipient): recipient is { contactId: string; email?: string } {
  return 'contactId' in recipient && !!recipient.contactId;
}

export function isLead(recipient: SendBulkEmailRecipient): recipient is { leadId: string } {
  return 'leadId' in recipient && !!recipient.leadId;
}

export function isAgent(recipient: SendBulkEmailCcRecipient): recipient is { agentId: string } {
  return 'agentId' in recipient && !!recipient.agentId;
}

function mapToCcRecipientData(recipients: SendBulkEmailCcRecipient[] | undefined, agents: Agent[] | undefined) {
  return (
    recipients
      ?.map((recipient) => {
        if (isAgent(recipient)) {
          const agent = agents?.find((a) => a.id === recipient.agentId);
          if (agent) {
            return { type: 'AGENT' as const, ...agent };
          }
        } else {
          return { type: 'EMAIL' as const, email: recipient.email };
        }
      })
      .filter(isSet) || []
  );
}

async function createComposeBulkEmailDefaultValues({
  from,
  data,
  activeShopId,
  preferredShopLanguage,
}: {
  from: string;
  data: SendBulkEmailData;
  activeShopId: string;
  preferredShopLanguage?: LanguageEnum;
}): Promise<ComposeBulkEmailFormData> {
  const contactIds = data.to.filter(isContact).map((recipient) => recipient.contactId);
  const leadIds = data.to.filter(isLead).map((recipient) => recipient.leadId);
  const agentIds = [...(data.cc?.filter(isAgent) || []), ...(data.bcc?.filter(isAgent) || [])].map(
    (recipient) => recipient.agentId,
  );

  const leads = (await loadLeads(leadIds)) || [];
  const [contacts, template, agents, properties] = await Promise.all([
    loadContactsAndLeads([...contactIds, ...leads.map((l) => l.contactId)]),
    loadTemplate(activeShopId, data.template),
    loadAgents(agentIds),
    loadListingsFromPe(data.propertyUtags),
  ]);

  const contactsFlagged = contacts?.filter((contact) => contact.contactStatus === 'FLAGGED' || contact.blocked);
  const contactsNotFlagged =
    contacts?.filter((contact) => contact.contactStatus !== 'FLAGGED' && !contact.blocked) ?? [];
  const contactsWithEmailAndNotFlagged = contactsNotFlagged.filter((contact) => contact?.preferredEmail[0]?.address);
  const contactsWithoutEmail = contactsNotFlagged.filter((contact) => !contact?.preferredEmail[0]?.address);

  const recipients = mapLeadInfosToLeadAndContactOptions(leads || [], contactsWithEmailAndNotFlagged || []);

  return {
    from,
    to: recipients,
    cc: mapToCcRecipientData(data.cc, agents),
    bcc: mapToCcRecipientData(data.bcc, agents),
    isExposeSending: !!data.template,
    template: template!,
    subjectOverride: getTemplateContent(template, preferredShopLanguage)?.subject?.trim() || '',
    properties,
    createFollowUpTask: false,
    hidePrice: false,
    signatureId: undefined,
    attachments: [],
    toContactsWithoutEmail: contactsWithoutEmail ?? [],
    toContactsFlagged: contactsFlagged ?? [],
  };
}

export const useCreateComposeBulkEmailDefaultValues = () => {
  const { mutateAsync, ...rest } = useMutation(createComposeBulkEmailDefaultValues);
  return { createComposeBulkEmailDefaultValues: mutateAsync, ...rest };
};

async function createPreviewBulkEmailDefaultValues({
  composeData,
  activeShopId,
  preferredShopLanguage,
}: {
  composeData: ComposeBulkEmailFormData;
  activeShopId: string;
  preferredShopLanguage?: LanguageEnum;
}): Promise<PreviewBulkEmailFormData> {
  const [resolvedTemplates, signature] = await Promise.all([
    loadResolvedBulkTemplates({
      shopId: activeShopId,
      emailTemplateId: composeData.template.id,
      propertyIds: composeData.properties.map((p) => p.utag),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recipients: composeData.to.map((r) => ({
        contactId: getContactId(r),
        leadId: r.type === 'LEAD' ? r.id : undefined,
      })),
    }),
    loadResolvedSignature(activeShopId, composeData.signatureId),
  ]);

  const templatesByContact = Object.fromEntries<ResolveBulkEmailTemplateResponse>(
    resolvedTemplates.map((template) => [template.recipient.contactId, template]),
  );

  const shopLanguageTemplateContent = getTemplateContent(composeData.template, preferredShopLanguage);
  const hasSubjectOverride = composeData.subjectOverride !== shopLanguageTemplateContent?.subject;

  const recipients = composeData.to.map((recipient) => {
    const contactId = getContactId(recipient);
    const template = templatesByContact[contactId]!;
    const highlightedBody = highlightUnresolvedPlaceholders(template.body, template.unresolvedPlaceholders);
    const autoCcRecipients = getAutoCcRecipients([recipient], [recipient]);

    return {
      recipient: cloneDeep(recipient),
      properties: cloneDeep(composeData.properties),
      alreadyProposedProperties: cloneDeep(template.alreadyProposedProperties || []),
      cc: cloneDeep([...composeData.cc, ...autoCcRecipients]),
      bcc: cloneDeep(composeData.bcc),
      body: emailBodyWithSignature(highlightedBody, signature),
      subject: (hasSubjectOverride ? composeData.subjectOverride : template.subject) || '',
      showSubjectOverrideWarning: hasSubjectOverride && template.language !== shopLanguageTemplateContent?.language,
      language: template.language,
    };
  });

  return {
    isPriceReduction: composeData.template.type === 'PRICE_REDUCTION',
    isExposeSending: composeData.isExposeSending,
    recipients: recipients,
    attachments: composeData.attachments,
  };
}

export const useCreatePreviewBulkEmailDefaultValues = () => {
  const { mutateAsync, ...rest } = useMutation(createPreviewBulkEmailDefaultValues);
  return { createPreviewBulkEmailDefaultValues: mutateAsync, ...rest };
};
