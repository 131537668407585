import { AppModule, DEFAULT_LANG, useLocale } from '@ev/eva-container-api';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { startOfDay, subYears } from 'date-fns';
import { env } from 'env';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo, useRef } from 'react';
import { InitialValuesForAddProperty } from 'util/mappers/propertyMapper';
import { SearchQuery, buildSearchParams } from 'util/navigation/searchParams';
import { usePermissions } from 'util/usePermissions';

const GOME_LANG_MAP: Record<string, string> = {
  en: 'en-GB',
  de: 'de-DE',
  'de-AT': 'de-AT',
  'de-CH': 'de-CH',
  el: 'el-GR',
  es: 'es-eS',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-PT',
};

export function getGOmeLocale(locale: string) {
  if (locale) return locale !== DEFAULT_LANG ? GOME_LANG_MAP[locale] : undefined;
}

export function getPathname(url: string) {
  return new URL(url, window.location.origin).pathname;
}

export function useDestination() {
  const { activeShop } = useActiveShop();
  const locale = useLocale();
  const lang = locale !== DEFAULT_LANG ? locale : undefined;
  const currentAgentId = useCurrentAgentId();
  const gomeIntegrationEnabled = useIsFeatureEnabled('GOME_MODULE_FEDERATION');
  const { showAgentUI } = usePermissions();
  const shouldRenderAgentUI = showAgentUI(activeShop);

  const dateNow = useRef(new Date());

  return useMemo(() => {
    const path = (pathname: string, options?: { shopId?: string; query?: SearchQuery }) => {
      const nextShopId = options?.shopId || activeShop?.id;

      let url = lang ? `/${lang}/${nextShopId}${pathname}` : `/${nextShopId}${pathname}`;
      if (options?.query) {
        url += `?${buildSearchParams(options.query).toString()}`;
      }
      return url;
    };

    const gomeMePath = (pathname: string, query?: Record<string, string>) => {
      if (gomeIntegrationEnabled) {
        return path(pathname, { query });
      }

      const queryString = `?${new URLSearchParams({ shopId: activeShop?.id, ...query }).toString()}`;
      return lang
        ? `${env.VITE_GO_AGENT_BASE_URL}/${GOME_LANG_MAP[lang]}${pathname}${queryString}`
        : `${env.VITE_GO_AGENT_BASE_URL}${pathname}${queryString}`;
    };

    const toSalesQueryParams = (options: {
      create: true;
      initialValues?: Maybe<InitialValuesForAddProperty>;
      redirectUrl?: Maybe<string>;
    }) => {
      const queryParams: Record<string, string> = {};

      if (options?.create) {
        queryParams.create = 'property';
      }
      if (options?.initialValues) {
        const jsonString = JSON.stringify(options.initialValues);
        const encodedValues = encodeURIComponent(jsonString);
        queryParams.initialValues = encodedValues;
      }
      if (options?.redirectUrl) {
        const encodedValues = encodeURIComponent(options.redirectUrl);
        queryParams.redirectUrl = encodedValues;
      }
      return queryParams;
    };

    const defaultAgentFilter = shouldRenderAgentUI ? { agent: [currentAgentId] } : {};

    return {
      toHomePage: (options?: { shopId?: string }) => path('', options),
      to404: () => (lang ? `/${lang}/404` : `/404`),
      toDashboard: () => path('/dashboard'),
      toStatistics: () => path('/statistics'),
      toLeadList: (options?: { shopId?: string }) => path('/leads', options),
      toActivities: () =>
        path('/activities', {
          query: { createdAtFrom: subYears(startOfDay(dateNow.current), 1).toISOString() },
        }),
      toContactList: (options?: { shopId?: string }) => path('/contacts', { ...options, query: defaultAgentFilter }),
      toContactDetails: ({ shopId, contactId }: { contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}`, { shopId }),
      toContactActivities: ({ shopId, contactId }: { contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/activities`, { shopId }),
      toMobileContactDetails: ({ shopId, contactId }: { contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/contactdetails`, { shopId }),
      toContactDocuments: ({ shopId, contactId }: { contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/documents`, { shopId }),
      toProposedListings: ({ shopId, contactId }: { contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/proposedlistings`, { shopId }),
      toLeadDetails: ({ shopId, contactId, leadId }: { leadId: string; contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/lead/${leadId}`, { shopId }),
      toLeadMatchings: ({ shopId, contactId, leadId }: { leadId: string; contactId: string; shopId?: string }) =>
        path(`/contact/${contactId}/lead/${leadId}/matching`, { shopId }),
      toCommunications: () => path('/mails'),
      toGoogleIntegrations: () => path('/mails/setting/integrations'),
      toEmailSignatures: () => path('/mails/setting/signatures'),
      toEmailTemplates: () => path('/mails/setting/templates'),
      toEmailInbox: (options?: { shopId?: string }) => path('/mails/inbox', options),
      toEmailSent: () => path('/mails/sent'),
      toEmailSentBulk: () => path('/mails/sentbulk'),
      toEmailThread: ({ threadIndex }: { threadIndex: number }) => path(`/mails/${threadIndex}`),
      toCalls: () => path('/calls'),
      toCallDetails: ({ callId }: { callId?: string }) => path(`/calls/${callId}`),
      toReferrals: () => path('/referrals'),
      toNetwork: () => path('/network'),
      toAdmin: () => path('/admin'),
      toMyShopAndTeam: (options?: { shopId?: string }) => path('/user/shop', options),
      toShopSettings: (options?: { shopId?: string }) => path('/user/shop/settings', options),
      toShopDetails: (options?: { shopId?: string }) => path('/user/shop/details', options),
      toPublicNetwork: () => (lang ? `/${lang}/network` : `/network`),
      toShopExport: () => path(`/user/shop/export`),

      // Profile Engine
      toAgentProfile: (options?: { agentId: string }) => path(`/profile/agent/${options?.agentId || currentAgentId}`),
      toShopProfile: (options?: { shopId?: string }) => path('/profile/shop', options),
      toLiPaProfile: () => path(`/profile/lipa/`),

      // GOme
      toSales: (options?: {
        create: true;
        initialValues?: Maybe<InitialValuesForAddProperty>;
        redirectUrl?: Maybe<string>;
      }) => {
        return gomeMePath('/sales', options ? toSalesQueryParams(options) : {});
      },

      toLettings: () => gomeMePath('/lettings'),
      toSalesProperty: (utag: string) => gomeMePath(`/sales/${utag}`),
      toLettingsProperty: (utag: string) => gomeMePath(`/lettings/${utag}`),
      toDevelopments: (options?: { create: true }) => {
        return gomeMePath('/developments', options?.create ? { create: 'development' } : undefined);
      },

      // KYC
      toKyc: () => path('/kyc'),
      toKycPersonCheck: ({ searchId }: { searchId: string }) => path(`/kyc/person/${searchId}`),

      // App Modules
      toAppModule: (appModule: AppModule) => {
        const subPath = appModule.route.path.replace(/^\//, '');
        switch (appModule.scope) {
          case 'SHOP_SETTINGS':
            return path('/user/shop/' + subPath);
        }
      },
    };
  }, [shouldRenderAgentUI, currentAgentId, activeShop?.id, lang, gomeIntegrationEnabled]);
}
