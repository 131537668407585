import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PropsWithChildren, Suspense, useState } from 'react';
import { theme } from 'theme';

import 'react-quill/dist/quill.snow.css';

import { MainNavigationSidePanel } from './MainNavigationSidePanel';

import { useDeactivateLayout } from '@ev/eva-container-api';
import { ErrorBoundary } from 'components/error/ErrorBoundary';
import { DESKTOP_TOP_BAR_HEIGHT, MOBILE_TOP_BAR_HEIGHT } from 'components/state/UIStateProvider';
import { MobileMainNavigation } from 'eva-frame/EvaLayout/MainNavigationDrawer';
import { MobileProfileNavigation } from 'eva-frame/EvaLayout/ProfileMenuButton/MobileProfileNavigation';
import { useDatadog } from 'eva-frame/EvaLayout/useDatadog';
import {
  NavItem,
  useMainNavigationItems,
  usePublicMainNavigationItems,
} from 'eva-frame/EvaLayout/useMainNavigationItems';
import { PublicTopBar, TopBar } from './TopBar';

const Grid = styled('div', {
  shouldForwardProp: (prop: string) => !['deactivated'].includes(prop),
})<{ deactivated: boolean }>(({ deactivated }) =>
  deactivated
    ? {}
    : {
        display: 'grid',
        [theme.breakpoints.down('desktop')]: {
          gridTemplateColumns: '[left content] minmax(0,1fr) [right]',
          gridTemplateRows: `[top] ${MOBILE_TOP_BAR_HEIGHT}px [content] 1fr [bottom]`,
        },
        [theme.breakpoints.up('desktop')]: {
          gridTemplateColumns: '[left] max-content [content] minmax(0,1fr) [right]',
          gridTemplateRows: `[top] ${DESKTOP_TOP_BAR_HEIGHT}px [content] 1fr [bottom]`,
        },
        minHeight: '100vh',
        maxWidth: '100vw',
      },
);

export const Main = styled('main')({
  gridColumnEnd: 'right',
  gridColumnStart: 'content',
  gridRowEnd: 'bottom',
  gridRowStart: 'content',
  position: 'relative',
  padding: '0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
});

function EvaLayoutInner({
  children,
  isPublic,
  mainNavigationItems,
}: PropsWithChildren<{ isPublic: boolean; mainNavigationItems: readonly NavItem[] }>) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { isLayoutDeactivated } = useDeactivateLayout();
  const [isMobileMainNavigationOpen, setIsMobileMainNavigationOpen] = useState(false);
  const [isProfileNavigationOpen, setIsProfileNavigationOpen] = useState(false);

  useDatadog();
  const showMobileProfileNavigation = !isDesktop && isProfileNavigationOpen && !isLayoutDeactivated;
  const showMobileMainNavigation = !isDesktop && isMobileMainNavigationOpen && !isLayoutDeactivated;
  const showSomeMobileNavigation = showMobileProfileNavigation || showMobileMainNavigation;

  return (
    <Grid deactivated={isLayoutDeactivated}>
      {isDesktop && !isLayoutDeactivated && <MainNavigationSidePanel items={mainNavigationItems} />}
      {!isLayoutDeactivated &&
        (isPublic ? (
          <PublicTopBar
            isMobileNavigationOpen={isMobileMainNavigationOpen}
            setIsMobileMainNavigationOpen={setIsMobileMainNavigationOpen}
          />
        ) : (
          <TopBar
            isMobileMainNavigationOpen={isMobileMainNavigationOpen}
            setIsMobileMainNavigationOpen={setIsMobileMainNavigationOpen}
            isProfileNavigationOpen={isProfileNavigationOpen}
            setIsProfileNavigationOpen={setIsProfileNavigationOpen}
          />
        ))}
      <Main sx={{ display: showSomeMobileNavigation ? 'none' : undefined }}>
        <ErrorBoundary>
          <Suspense fallback={<LinearProgress />}>{children}</Suspense>
        </ErrorBoundary>
      </Main>
      {showMobileMainNavigation && (
        <MobileMainNavigation items={mainNavigationItems} onClose={() => setIsMobileMainNavigationOpen(false)} />
      )}
      {showMobileProfileNavigation && <MobileProfileNavigation onClose={() => setIsProfileNavigationOpen(false)} />}
    </Grid>
  );
}

export function EvaLayout({ children }: PropsWithChildren) {
  const { items } = useMainNavigationItems();

  return (
    <EvaLayoutInner isPublic={false} mainNavigationItems={items}>
      {children}
    </EvaLayoutInner>
  );
}

export function EvaPublicLayout({ children }: PropsWithChildren) {
  const { items } = usePublicMainNavigationItems();

  return (
    <EvaLayoutInner isPublic mainNavigationItems={items}>
      {children}
    </EvaLayoutInner>
  );
}
