import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSearchShopByLocation } from 'api/rest/hooks/useSearchShopByLocation';
import { AddressSearch } from 'components/general/AddressSearch/AddressSearch';
import { InfoTooltip } from 'components/general/InfoTooltip/InfoTooltip';
import { LinearProgressPaperTop } from 'components/general/LinearProgressPaperTop/LinearProgressPaperTop';
import { LeafletMap } from 'components/map/LeafletMap';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { withEnabledPage } from 'util/navigation/withEnabledPage';
import { Marker, useGetAddressMarker, useGetShopMarkers } from 'util/places/mapMarkers';
import { PlacesAddressSearchResult } from 'util/places/types';
import { useDebounce } from 'util/useDebounce';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { useTrackPageView } from 'util/useSnowplowTracking';

type BusinessUnitType = 'RESIDENTIAL' | 'COMMERCIAL';

const NON_BOUNDARY_TYPES = [
  'street_address',
  'street_number',
  'premise',
  'subpremise',
  'plus_code',
  'point_of_interest',
] as const;

function mapToCoordinates(bffCoordinates: { lat: number; lng: number }) {
  return {
    longitude: bffCoordinates.lng,
    latitude: bffCoordinates.lat,
  };
}

function mapToSearchRequest(address: PlacesAddressSearchResult | null, businessUnitType: BusinessUnitType) {
  const { location, boundingBox, polygon: multiPolygon, types } = address || {};
  const includeBoundaries =
    types?.length && NON_BOUNDARY_TYPES.every((nonBoundaryType) => !types.includes(nonBoundaryType));

  const request = {
    businessUnitType,
    location: location ? mapToCoordinates(location) : undefined,
    boundingBox:
      includeBoundaries && boundingBox
        ? {
            northEast: mapToCoordinates(boundingBox.northEast),
            southWest: mapToCoordinates(boundingBox.southWest),
          }
        : undefined,
    polygons: includeBoundaries
      ? multiPolygon?.coordinates?.flatMap((coordinates) =>
          coordinates.map((polygon) =>
            polygon.map((position) => ({
              longitude: position[0]!,
              latitude: position[1]!,
            })),
          ),
        )
      : undefined,
  };

  return !request.location && !request.boundingBox && !request.polygons ? null : request;
}

/**
 *
 * Attention this component is accessible without a LeadHub account!
 */
export const NetworkPage = ({ isPublic = false }: { isPublic?: boolean }) => {
  const { openErrorSnackBar } = useErrorSnackBar();
  const debounce = useDebounce();
  const getAddressMarker = useGetAddressMarker();
  const getShopMarkers = useGetShopMarkers();
  const { t } = useTranslation(['lead', 'errors']);
  const [businessUnitType, setBusinessUnitType] = useState<BusinessUnitType>('RESIDENTIAL');
  const [markers, setMarkers] = useState<Marker[]>();
  const [showNoShopsError, setShowNoShopsError] = useState(false);
  const [address, setAddress] = useState<PlacesAddressSearchResult | null>(null);
  const { searchShopByLocation, isLoading } = useSearchShopByLocation();

  useTrackPageView('network');

  useEffect(() => {
    debounce(() => {
      const request = mapToSearchRequest(address, businessUnitType);
      if (!address || !request) {
        setMarkers(undefined);
        return;
      }

      searchShopByLocation(request, {
        onSuccess: async (result) => {
          const [addressMarker, shopMarkers] = await Promise.all([
            getAddressMarker({ address, shops: result }),
            getShopMarkers(result),
          ]);
          setShowNoShopsError(shopMarkers.length === 0 && !addressMarker[0]?.plannedShop);

          if (addressMarker.length > 0) {
            addressMarker[0]!.infoShownInitially = !!addressMarker[0]!.plannedShop;
          }
          setMarkers([...addressMarker, ...shopMarkers]);
        },
        onError: (error) => openErrorSnackBar(t('lead:networkPage.searchShopError'), error),
      });
    });
  }, [
    address,
    businessUnitType,
    debounce,
    searchShopByLocation,
    openErrorSnackBar,
    t,
    getAddressMarker,
    getShopMarkers,
    isPublic,
  ]);

  const addressHasNoStreetNumber = !!address && !address?.streetNumber;

  const containerStyle = { height: '60vh' };
  return (
    <>
      <Helmet>
        <title>{t('lead:networkPage.title')}</title>
        <meta property="og:title" content="Contacts" key="title" />
      </Helmet>
      <Box sx={{ margin: 3, marginBottom: 0 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h1">{t('lead:networkPage.title')}</Typography>
          <InfoTooltip text={t('lead:networkPage.marketingText')} />
        </Stack>
        <Tabs
          sx={{ marginTop: 4 }}
          value={businessUnitType}
          onChange={(_: unknown, value: BusinessUnitType) => {
            setBusinessUnitType(value);
          }}
        >
          <Tab value="RESIDENTIAL" sx={{ textTransform: 'none' }} label={t('lead:networkPage.residential')} />
          <Tab value="COMMERCIAL" sx={{ textTransform: 'none' }} label={t('lead:networkPage.commercial')} />
        </Tabs>
      </Box>
      <Divider />
      <Paper variant="outlined" elevation={0} sx={{ padding: 2, margin: { tablet: 3 }, position: 'relative' }}>
        {isLoading && <LinearProgressPaperTop />}

        <Stack direction="row" gap={1} alignItems="center" mb={3}>
          <AddressSearch
            sx={{ flex: 1 }}
            showWarningOnBlur={false}
            label={t('lead:networkPage.searchLabel')}
            onChange={setAddress}
          />
          {addressHasNoStreetNumber && (
            <InfoTooltip sx={{ color: theme.palette.error.main }} text={t('lead:networkPage.noStreetNumber')} />
          )}
        </Stack>

        <LeafletMap containerStyle={containerStyle} markers={markers}>
          {showNoShopsError && <NoShopsFound onClick={() => setShowNoShopsError(false)} />}
        </LeafletMap>
      </Paper>
    </>
  );
};

function NoShopsFound({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation(['lead']);
  return (
    <Stack
      onClick={onClick}
      sx={{
        position: 'absolute',
        inset: 0,
        backgroundColor: theme.palette.transparent.black16,
        zIndex: theme.zIndex.modal,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Paper elevation={3} sx={{ paddingX: 3, paddingY: 2 }}>
        <Typography variant="body3" color={theme.palette.shade.grey2}>
          {t('lead:networkPage.noShopsFound')}
        </Typography>
      </Paper>
    </Stack>
  );
}

export const Component = withEnabledPage('Network', NetworkPage);
