import { Box, Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAgentsInShopGroup } from 'api/graphql/hooks/useAgents';
import { useContactRelationships, useContactsAndLeads } from 'api/graphql/hooks/useContact';
import {
  ContactItem,
  LeadItem,
  LeadOptionChip,
} from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  getContactId,
  mapContactToLeadAndContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { AgentTooltipChip } from 'components/general/Chips/TooltipChip/AgentTooltipChip';
import { ContactTooltipChip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { contactRelationshipTypeTranslation } from 'const/enumTranslations';
import React, { ReactNode, useRef } from 'react';
import { theme } from 'theme';
import { getPreferredEmail } from 'util/contactUtils';
import { getEmail } from 'util/email';
import { agentSearchFilter, contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { Recipient } from 'util/schemas/sendEmailSchema';
import { useSearchText } from 'util/useDebounce';

export function getId(recipient?: Recipient) {
  if (!recipient) {
    return;
  }
  return recipient.type === 'EMAIL' ? recipient.email : recipient.id;
}

export function getLeadId(recipient?: Recipient) {
  if (!recipient) {
    return;
  }
  return recipient.type === 'LEAD' ? recipient.id : undefined;
}

export function getContactOfLead(recipient?: Recipient) {
  if (!recipient) {
    return;
  }
  return recipient.type === 'LEAD' ? recipient.contact : undefined;
}

export function RecipientPicker({
  value,
  onChange,
  label,
  disabled,
  errorMessage,
  restrictToAgents,
  children,
}: {
  value: Recipient[];
  onChange: (value: Recipient[]) => void;
  label: React.ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  restrictToAgents?: boolean;
  children?: ReactNode;
}) {
  const { t } = useTranslation(['communication', 'enums']);
  const { shopIdsInActiveGroup } = useActiveShop();

  const { searchText, setSearchText } = useSearchText();

  const { agentsInShopGroup = [] } = useAgentsInShopGroup({
    shopIds: shopIdsInActiveGroup,
    where: agentSearchFilter(searchText),
  });

  const selectedContactOrLead = value.find((recipient) => recipient.type === 'CONTACT' || recipient.type === 'LEAD');
  const contactId = selectedContactOrLead
    ? getContactId(selectedContactOrLead as LeadAndContactSearchOption)
    : undefined;

  const { contactRelationships = [] } = useContactRelationships(
    {
      contactId,
      where: {
        contactStatus: { _neq: 'FLAGGED' },
        blocked: { _neq: true },
        deleted: { _neq: true },
        ...contactSearchNameFilter(searchText),
      },
    },
    { enabled: !!contactId && !restrictToAgents },
  );

  const enableContacts = !!searchText && !selectedContactOrLead && !restrictToAgents;
  const { contacts = [] } = useContactsAndLeads(
    {
      where: {
        shopId: { _in: shopIdsInActiveGroup },
        contactStatus: { _neq: 'FLAGGED' },
        blocked: { _neq: true },
        deleted: { _neq: true },
        ...contactSearchNameFilter(searchText),
      },
      limit: 20,
      offset: 0,
    },
    { enabled: enableContacts, keepPreviousData: true },
  );

  // Even if the query is disable , we receive some times the result of the cache.
  const resultContacts = enableContacts ? contacts : [];

  const isOpen = !!searchText.length;

  const recipients: Recipient[] = [
    ...contactRelationships.map((contactRelationship) => ({
      type: 'RELATED_CONTACT' as const,
      relationship: contactRelationship.type,
      disabled: !contactRelationship.relatedContact.emailAddresses[0]?.address,
      ...contactRelationship.relatedContact,
    })),
    ...mapContactToLeadAndContactOptions(resultContacts).map((option) => ({
      ...option,
      disabled: option.type === 'CONTACT' && option.email.length === 0,
    })),
    ...agentsInShopGroup.map((agent) => ({
      type: 'AGENT' as const,
      ...agent,
    })),
  ];

  const lastRenderedOptionType = useRef<Recipient['type']>();

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Autocomplete
        open={isOpen}
        disabled={disabled}
        freeSolo={!restrictToAgents}
        value={value}
        disableClearable
        multiple
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          switch (option.type) {
            case 'AGENT':
            case 'CONTACT':
            case 'RELATED_CONTACT':
              return [option.firstName, option.lastName, getEmail(option)].filter(Boolean).join();
            case 'EMAIL':
              return option.email;
            case 'LEAD':
              return [option.contact.firstName, option.contact.lastName, option.contact.email].filter(Boolean).join();
          }
        }}
        onChange={(_, items) => {
          onChange(items.map((item) => (typeof item === 'string' ? { type: 'EMAIL', email: item } : item)));
        }}
        isOptionEqualToValue={(option: string | Recipient, value: Recipient) =>
          typeof option === 'string' ? option === getEmail(value) : getId(option) === getId(value)
        }
        filterOptions={(options) => options} // Needed to disable the internal filtering
        onInputChange={(_, newInputValue) => setSearchText(newInputValue)}
        options={recipients}
        getOptionDisabled={(option: Recipient) => !getEmail(option)}
        renderOption={(props, option, state) => {
          if (state.index === 0) {
            lastRenderedOptionType.current = undefined;
          }
          const typeMatch = option.type === 'LEAD' ? 'CONTACT' : option.type;
          const isFirstOfType = lastRenderedOptionType.current !== typeMatch;
          lastRenderedOptionType.current = typeMatch;
          // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
          switch (option.type) {
            case 'AGENT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.agent')}</TypeLabel>}
                  <PersonMenuItem person={option} {...props} />
                </React.Fragment>
              );
            case 'CONTACT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.contact')}</TypeLabel>}
                  <ContactItem {...props} option={option} />
                </React.Fragment>
              );
            case 'LEAD':
              return (
                <Box {...props} key={option.id} component="li">
                  <LeadItem sx={{ marginLeft: 4 }} lead={option} />
                </Box>
              );
            case 'RELATED_CONTACT':
              return (
                <React.Fragment key={option.id}>
                  {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.relationship')}</TypeLabel>}
                  <ContactItem {...props} option={{ ...option, email: getPreferredEmail(option) || '' }}>
                    <Typography variant="body4" sx={{ color: theme.palette.text.secondary }}>
                      {t(contactRelationshipTypeTranslation[option.relationship])}
                    </Typography>
                  </ContactItem>
                </React.Fragment>
              );
          }
        }}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            switch (option.type) {
              case 'AGENT':
                return (
                  <AgentTooltipChip
                    key={index}
                    handleDelete={() => getTagProps({ index }).onDelete(index)}
                    agentId={option.id}
                  />
                );
              case 'RELATED_CONTACT':
                return (
                  <ContactTooltipChip
                    key={index}
                    handleDelete={() => getTagProps({ index }).onDelete(index)}
                    contactId={option.id}
                    asLink={false}
                  />
                );
              case 'CONTACT':
                return (
                  <ContactTooltipChip
                    key={index}
                    asLink={false}
                    handleDelete={() => getTagProps({ index }).onDelete(index)}
                    contactId={option.id}
                    currentEmail={option.email}
                  />
                );
              case 'LEAD':
                return <LeadOptionChip key={option.id} lead={option} index={index} getTagProps={getTagProps} />;
              case 'EMAIL':
                return <EVChip label={option.email} {...getTagProps({ index })} key={index} selected={false} />;
            }
          })
        }
        renderInput={(params) => (
          <TextField
            required
            {...params}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage}
            fullWidth
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        sx={{
          width: '100%',
          '.MuiInputBase-root': {
            paddingRight: children ? '80px !important' : 'auto',
          },
        }}
      />
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          right: 0,
          paddingRight: '8px',
          top: '50%',
          transform: 'translateY(-50%)', // Centers it vertically
          display: children ? 'flex' : 'none',
        }}
      >
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
}

export function TypeLabel({ children }: React.PropsWithChildren) {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: theme.palette.text.secondary, paddingX: 2, paddingY: 1 }}
      fontWeight="bold"
      component="p"
    >
      {children}
    </Typography>
  );
}
