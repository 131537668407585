import { z } from 'zod';

export const serverSchema = {
  CI: z.coerce.boolean().optional().default(false),
  E2E_TEST_URL: z.string().min(1).optional(),
  HASURA_SECRET: z.string().min(1).optional(),
  HASURA_URL: z.string().min(1).optional(),
  LEADAPI_URL: z.string().min(1).optional(),
  LEAD_HUB_E2E_ADMIN_EMAIL: z.string().min(1).optional(),
  LEAD_HUB_E2E_ADMIN_PASSWORD: z.string().min(1).optional(),
  LEAD_HUB_E2E_AGENT_EMAIL: z.string().min(1).optional(),
  LEAD_HUB_E2E_AGENT_PASSWORD: z.string().min(1).optional(),
  LEAD_HUB_E2E_MANAGER_EMAIL: z.string().min(1).optional(),
  LEAD_HUB_E2E_MANAGER_PASSWORD: z.string().min(1).optional(),
  LOCALISE_PROJECT_ID: z.string().min(1).optional(),
  LOCALISE_TOKEN: z.string().min(1).optional(),
  NODE_ENV: z.enum(['development', 'test', 'production']).default('production'),
  SENTRY_AUTH_TOKEN: z.string().min(1).optional(),
  SENTRY_DSN: z.string().min(1).optional(),
  SONARQUBE_NOTIFICATION_PASSWORD: z.string().min(1).optional(),
  UPLOAD_SOURCE_MAPS: z.coerce.boolean().optional().default(false),
} as const;

export const clientSchema = {
  VITE_BACKEND_API_URL: z.string().min(1).optional(),
  VITE_DATADOG_CLIENT_SECRET: z.string().optional(),
  VITE_DISABLED_FEATURES: z.string().optional(),
  VITE_FEATURE_BRANCH: z.string().min(1).optional(),
  VITE_FRESH_SERVICE_ID: z.string().min(1).optional(),
  VITE_FRESH_SERVICE_URL: z.string().min(1).optional(),
  VITE_GCP_AGENT_PROFILE_IMAGE_URL: z.string().min(1),
  VITE_GCP_LIPA_PROFILE_IMAGE_URL: z.string().min(1),
  VITE_GCP_SHOP_PROFILE_IMAGE_URL: z.string().min(1),
  VITE_GO_AGENT_BASE_URL: z.string().min(1),
  VITE_GOOGLE_MAPS_API_KEY: z.string().min(1),
  VITE_HASURA_URL: z.string().min(1),
  VITE_KEYCLOAK_CLIENT_ID: z.string().min(1),
  VITE_KEYCLOAK_REALM: z.string().min(1),
  VITE_KEYCLOAK_URL: z.string().min(1),
  VITE_KYC_URL: z.string().min(1),
  VITE_LEAD_HUB_E2E_ADMIN_USERNAME: z.string().min(1).optional(),
  VITE_LEAD_HUB_E2E_AGENT_USERNAME: z.string().min(1).optional(),
  VITE_LEAD_HUB_E2E_MANAGER_USERNAME: z.string().min(1).optional(),
  VITE_LEADHUB_ENV: z
    .enum(['local', 'local-dev', 'feature', 'dev', 'stage', 'prod', 'migration-prod'])
    .default('local-dev'),
  VITE_NYLAS_CALLBACK_URL: z.string().min(1).optional(),
  VITE_NYLAS_V3_CLIENT_ID: z.string().min(1),
  VITE_PREVIEW_AGENT_PROFILE_URL: z.string().min(1),
  VITE_PREVIEW_LIPA_PROFILE_URL: z.string().min(1),
  VITE_PREVIEW_SHOP_PROFILE_URL: z.string().min(1),
  VITE_PRODUCT_FRUITS_WORKSPACE_ID: z.string().min(1),
  VITE_PROFILE_ENGINE_URL: z.string().min(1),
  VITE_PUBLISHED_PROFILE_BASE_URL: z.string().min(1),
  VITE_REFERRAL_TERMS_AND_CONDITIONS_URL: z.string().min(1),
  VITE_SEARCH_BFF_API_URL: z.string().min(1),
  VITE_SEARCH_BFF_APPLICATION_KEY: z.string().min(1),
  VITE_SECONDARY_HASURA_URL: z.string().min(1).optional(),
  VITE_SENTRY_DSN: z.string().min(1).optional(),
  VITE_SENTRY_ENVIRONMENT: z.enum(['local', 'dev', 'feature', 'prod', 'stage', 'migration-prod']).optional(),
  VITE_VERSION: z.string().min(1).optional(),
} as const;
