import { Box, Popover, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AgentOption } from 'components/forms/AgentsPicker/AgentsPicker';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import LinkIcon from 'components/icons/link_external.svg?react';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { theme } from 'theme';
import { useDestination } from 'util/navigation/useDestination';

export const AgentPopover = ({
  anchorEl,
  selectedOption,
  handleClose,
}: {
  anchorEl: Maybe<HTMLDivElement | HTMLButtonElement>;
  selectedOption?: AgentOption;
  handleClose: () => void;
}) => {
  const open = Boolean(anchorEl);
  const { toAgentProfile } = useDestination();
  const id = open ? 'simple-popover' : undefined;
  const profileEnabled = useIsFeatureEnabled('PROFILE_INTEGRATION');

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
        <EVAvatar person={{ firstName: selectedOption?.firstName, lastName: selectedOption?.lastName }} size="s" />
        <Stack sx={{ ml: 1 }}>
          <Typography variant="body3" component="div">
            {selectedOption?.firstName} {selectedOption?.lastName}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
            {selectedOption?.email}
          </Typography>
        </Stack>
        <Box sx={{ ml: 3 }}>
          {selectedOption?.id && profileEnabled && (
            <IconButton target="_blank" href={toAgentProfile({ agentId: selectedOption.id })}>
              <LinkIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
