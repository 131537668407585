import { useJwtToken, useLocale } from '@ev/eva-container-api';
import {
  UserActionEnum,
  getPageContext,
  getUserContext,
  trackPageView,
  trackUserInteractionEvent,
} from '@ev/snowplow-library';
import { useCallback, useEffect, useMemo } from 'react';

export const useTrackPageView = (template: string, page?: string) => {
  const { jwtToken } = useJwtToken();

  const email = jwtToken?.email;
  const ldapId = jwtToken?.sub;
  const locale = useLocale();

  const contexts = useMemo(
    () => [
      getUserContext({ email, ldapId }),
      getPageContext({ isHQPage: true, page_language: locale, type: page, template_type: template }),
    ],
    [page, email, ldapId, template, locale],
  );

  useEffect(() => {
    trackPageView({ context: contexts });
  }, [contexts]);

  const sendSnowplowClickEvent = useCallback(
    (type: string) => {
      trackUserInteractionEvent({
        action: UserActionEnum.click,
        elementId: type,
        contexts,
      });
    },
    [contexts],
  );

  return { sendSnowplowClickEvent };
};
