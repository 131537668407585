import { CloseActivityStatus } from '@ev/eva-container-api';
import { useDrawerParams } from 'util/drawerUrlParams/useDrawerParams';
import { z } from 'zod';

const taskUrlParamsSchema = z.object({
  contactIds: z.string().array().optional(),
  type: z
    .enum([
      'MISCELLANEOUS',
      'TODO',
      'FOLLOW_UP',
      'OFFER',
      'NEWSLETTER',
      'ACQUISITION',
      'OWNERSHIP_ANNIVERSARY',
      'ACTIVE_BUYER_CONTACT',
      'ACTIVE_SELLER_CONTACT',
      'PLACE_ADVERTISEMENT',
      'PRICE_REDUCTION',
      'ACTIVITY_REPORT',
      'INCOMING_BUYER_SELLER_CONTACT',
      'EXPOSE_MANUAL_SHARE',
      'MAKE_AN_APPOINTMENT',
      'INCOMING_CALL',
      'OUTGOING_CALL',
      'NOTICE',
      'MARKET_REPORT',
    ])
    .optional(),
  propertyUtags: z.string().array().optional(),
  editId: z.string().optional(),
  status: z.enum(['COMPLETED', 'CANCELLED'] as const satisfies readonly CloseActivityStatus[]).optional(),
  redirectUrl: z.string().optional(),
});

export type TaskUrlParams = z.infer<typeof taskUrlParamsSchema>;

export function useTaskDrawerUrlParams({ onTaskParams }: { onTaskParams: (params: TaskUrlParams) => void }) {
  useDrawerParams({
    paramName: 'taskDrawer',
    schema: taskUrlParamsSchema,
    onParams: onTaskParams,
  });
}
