import { OutlinedInputProps } from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import React, { HTMLAttributes, ReactNode, SyntheticEvent } from 'react';
import { theme } from 'theme';
import sentry from 'util/sentry';

type AsyncAutocompleteProps<Option extends object> = {
  selectedOption: Option | undefined;
  options: Array<Option>;
  getSearchTextForOption: (option: Option) => string;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState,
  ) => ReactNode;
  searchString: string;
  loading: boolean;
  label: ReactNode;
  noOptionsPlaceholder: string;
  showPlaceholderForEmptyList?: boolean;
  onSelectedOptionChanged: (option: Option | undefined) => void;
  onSearchStringChanged: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  errorMessage?: string;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  sx?: SxProps;
  filterSelectedOptions?: boolean;
  disableClearable?: boolean;
  textFieldRef?: React.MutableRefObject<unknown> | React.RefCallback<unknown> | null;
  popupIcon?: ReactNode;
  textFieldProps?: Partial<OutlinedInputProps> & { 'data-loggingid'?: string };
  preventMenuFlip?: boolean;
  preserveDefaultValue?: boolean;
  readOnly?: boolean;
  /** Required to show chips and hide input text */
  hideInputValue?: boolean;
};

export function AsyncAutocomplete<Option extends object>({
  sx,
  label,
  filterSelectedOptions = true,
  disableClearable = true,
  noOptionsPlaceholder,
  showPlaceholderForEmptyList,
  onSelectedOptionChanged,
  options,
  onSearchStringChanged,
  searchString,
  renderOption,
  getSearchTextForOption,
  selectedOption,
  onBlur: propsOnBlur,
  onFocus,
  errorMessage,
  hasError,
  disabled,
  required,
  loading,
  textFieldRef,
  popupIcon,
  preventMenuFlip,
  preserveDefaultValue,
  textFieldProps = {},
  readOnly,
  hideInputValue,
}: AsyncAutocompleteProps<Option>) {
  function isPlaceholder(option: Option | { placeholder: boolean }) {
    return 'placeholder' in option;
  }

  function isDisabled(option: Option | { disabled: boolean }) {
    return 'disabled' in option && option.disabled;
  }

  const onOptionSelected = (
    event: SyntheticEvent,
    option: Option | string | (Option | string)[] | null | { placeholder: boolean },
  ) => {
    if (typeof option !== 'string' && !Array.isArray(option) && option && !('placeholder' in option)) {
      onSelectedOptionChanged(option);
      onSearchStringChanged(getSearchTextForOption(option));
    }
  };
  const onInputChange = (event: SyntheticEvent | null, value: string, reason: string) => {
    if (value || reason === 'input' || reason === 'clear') {
      onSearchStringChanged(value);
      if (!preserveDefaultValue || reason == 'clear') {
        onSelectedOptionChanged(undefined);
      }
    } else if (event?.type === 'keydown' && (event as React.KeyboardEvent)?.code === 'Enter' && options.length) {
      onSearchStringChanged(getSearchTextForOption(options[0]!));
      onSelectedOptionChanged(options[0]);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'ArrowRight' && options.length) {
      if (options[0] === undefined) {
        sentry.logError(new Error('options[0] is undefined inside AsyncAutocomplete()'));
        return;
      }
      onSearchStringChanged(getSearchTextForOption(options[0]));
      onSelectedOptionChanged(options[0]);
    }
  };

  const onBlur = () => {
    if (!selectedOption && options.length === 1) {
      onSelectedOptionChanged(options[0]);
    }
    propsOnBlur?.();
  };

  //showPlaceHolderForEmptyList will show the noOptionsPlaceHolder for no options and no search string
  const showPlaceHolder = !loading && (searchString || showPlaceholderForEmptyList) && !options.length;
  const displayedOptions = showPlaceHolder ? [{ placeholder: true }] : options;

  const slotProps = preventMenuFlip
    ? {
        popper: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }
    : {};

  return (
    <Autocomplete
      sx={sx}
      getOptionLabel={(option) =>
        option && typeof option !== 'string' && !('placeholder' in option) ? getSearchTextForOption(option) : ''
      }
      filterOptions={(x) => x}
      slotProps={slotProps}
      options={displayedOptions as Array<Option>}
      disabled={disabled}
      autoComplete
      autoHighlight
      includeInputInList
      filterSelectedOptions={filterSelectedOptions}
      disableClearable={disableClearable}
      freeSolo
      inputValue={searchString}
      value={selectedOption ?? ''}
      getOptionDisabled={(option) => {
        return isPlaceholder(option) || isDisabled(option);
      }}
      onChange={onOptionSelected}
      onBlur={onBlur}
      onFocus={onFocus}
      forcePopupIcon={true}
      noOptionsText={
        <Typography variant="body4" sx={{ color: theme.palette.grey[400] }} whiteSpace="initial">
          {noOptionsPlaceholder}
        </Typography>
      }
      onKeyDown={onKeyDown}
      onInputChange={onInputChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              value: selectedOption && hideInputValue ? '' : params.inputProps.value,
              readOnly: readOnly && selectedOption,
            }}
            InputProps={{
              ...params.InputProps,
              ref: params.InputProps.ref,
              inputRef: textFieldRef,
              ...textFieldProps,
            }}
            error={hasError}
            helperText={errorMessage}
            label={label}
            required={required}
          />
        );
      }}
      renderOption={(props, option, state) => {
        if ('placeholder' in option) {
          return (
            <MenuItem {...props}>
              <Typography variant="body4" whiteSpace="initial">
                {noOptionsPlaceholder}
              </Typography>
            </MenuItem>
          );
        }

        return renderOption(props, option, state);
      }}
      popupIcon={popupIcon}
    />
  );
}
