import { setupKeycloak } from '@ev/eva-container-api';
import { initSnowplowTracker } from '@ev/snowplow-library';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import { KeycloakProvider } from 'components/state/KeycloakProvider';
import { NotificationProvider } from 'components/state/NotificationProvider';
import { UIStateProvider } from 'components/state/UIStateProvider';
import { env } from 'env';
import { FeatureFlagProvider } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { LokalizationProvider } from 'eva-frame/EvaProviders/LokalizationProvider';
import { RootErrorBoundary } from 'eva-frame/EvaProviders/RootErrorBoundary';
import { EvaRouter } from 'eva-frame/EvaRouter';
import { HelmetProvider } from 'react-helmet-async';
import { theme } from 'theme';
import { LeafletLoader } from 'util/leaflet-loader';

if (env.VITE_LEADHUB_ENV !== 'local' && env.VITE_LEADHUB_ENV !== 'local-dev') {
  initSnowplowTracker({
    applicationId: 'leadhub',
    hasUserAcceptedAgreement: true,
    isProd: env.VITE_LEADHUB_ENV === 'prod',
    initialTrackPageViewEvent: false,
  });
}

const keycloak = setupKeycloak({
  url: env.VITE_KEYCLOAK_URL,
  realm: env.VITE_KEYCLOAK_REALM,
  clientId: env.VITE_KEYCLOAK_CLIENT_ID,
});

export function EvaFrame() {
  return (
    <LokalizationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootErrorBoundary>
          <KeycloakProvider keycloak={keycloak}>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <FeatureFlagProvider>
                  <HelmetProvider>
                    <UIStateProvider>
                      <LeafletLoader>
                        <EvaRouter />
                      </LeafletLoader>
                    </UIStateProvider>
                  </HelmetProvider>
                </FeatureFlagProvider>
              </QueryClientProvider>
            </NotificationProvider>
          </KeycloakProvider>
        </RootErrorBoundary>
      </ThemeProvider>
    </LokalizationProvider>
  );
}
