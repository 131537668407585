import { AppModule, useJwtToken } from '@ev/eva-container-api';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { useEffect, useMemo, useState } from 'react';
import { isSet } from 'util/filters';

let appModules: AppModule[];
export async function loadAppModules({
  integrateKyc,
  integrateGome,
  integrateProfile,
}: {
  integrateKyc: boolean;
  integrateGome: boolean;
  integrateProfile: boolean;
}): Promise<AppModule[]> {
  if (!appModules) {
    const appModuleImports = [
      ...(integrateKyc ? [{ name: 'kyc', load: () => import('kyc/appModules') }] : []),
      ...(integrateGome ? [{ name: 'gome', load: () => import('gome/appModules') }] : []),
      ...(integrateProfile ? [{ name: 'profile', load: () => import('profile/appModules') }] : []),
    ] as const;

    const appModuleFactories = await Promise.all(
      appModuleImports.map((appModuleImport) =>
        appModuleImport.load().catch((e) => {
          // eslint-disable-next-line no-console
          console.info(`No app module exported for ${appModuleImport.name}`, e);
          return null;
        }),
      ),
    );

    appModules = (
      await Promise.all(appModuleFactories.filter(isSet).map(({ default: moduleFactory }) => moduleFactory()))
    ).flat();
  }
  return appModules;
}

export function useAppModules(scope: AppModule['scope']) {
  const integrateProfile = useIsFeatureEnabled('PROFILE_INTEGRATION');
  const integrateGome = useIsFeatureEnabled('GOME_MODULE_FEDERATION');
  const integrateKyc = useIsFeatureEnabled('KYC_INTEGRATION');
  const enableAppModules = useIsFeatureEnabled('APP_MODULES');

  const [appModules, setAppModules] = useState<AppModule[]>([]);

  const { jwtToken } = useJwtToken();
  const { shopIdsInActiveGroup, activeShop } = useActiveShop();

  useEffect(() => {
    if (enableAppModules) {
      loadAppModules({ integrateKyc, integrateGome, integrateProfile }).then(setAppModules);
    }
  }, [enableAppModules, integrateGome, integrateKyc, integrateProfile]);

  return useMemo(
    () =>
      appModules
        .filter((module) => module.scope === scope && !module.hidden?.(jwtToken, activeShop.id, shopIdsInActiveGroup))
        .sort((a, b) => a.order - b.order),
    [appModules, scope, jwtToken, shopIdsInActiveGroup, activeShop.id],
  );
}
